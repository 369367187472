@mixin fb {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin cont($max_width: 1200px) {
  width: 100%;
  max-width: $max_width;
  margin: auto;
  padding: 0 15px;
}

@mixin br($c: red) {
  border: 1px solid $c;
}
@mixin br0() {
  border: 1px solid rgba(255, 0, 0, 0.32);
}

@mixin abs($position: absolute) {
  position: $position;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1;
  background: rgba(73, 86, 103, 0.67);
}

@mixin w($w: auto, $h: auto) {
  min-width: $w + px;
  max-width: $w + px;
  width: 100%;
  height: $h + px;
  min-height: $h + px;
}
@mixin w2($w: auto, $h: auto) {
  min-width: $w + em;
  max-width: $w + em;
  width: 100%;
  height: $h + em;
  min-height: $h + em;
}

@mixin letter(){
  span {
    position: relative;
    opacity: 0;
    transition:.6s opacity, .2s top;
  }
}

@mixin letter_active(){
  span {
    opacity: 1;
    letter-spacing: 0;
    &:nth-child(even) {
      top: 0;
      transform:rotate(0deg);
    }
    &:nth-child(odd) {
      top: 0;
    }
  }
}

@mixin letter_anim(){
  // span {
  //   &:nth-child(even) {
  //     animation:ghost 2s linear;
  //     animation-delay: 1.5s;
  //   }
  //   &:nth-child(odd) {
  //     animation:ghost 2.5s linear ;
  //     animation-delay: 3s;
  //   }
  // }
}

@mixin circle(){
  .circle {
    &:before {
      border-top-color: #65188e;
      border-right-color: #65188e;
      border-bottom-color: #65188e;
      transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
    }
    &:after {
      border-top: 1px solid #47aaf3;
      border-left-width: 1px;
      border-right-width: 1px;
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
      transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
      transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
      transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
    }
  }
}

@mixin block_title($size: 6em){
  z-index: 3;
  color: #fff;
  font-size: $size;
  line-height: 1.45;
  position: relative;
  font-family: $hregular;
}

@mixin title_light($size: 1.1, $position: left){
  font-size: $size + em;
  letter-spacing: 2.8px;
  font-family: $pregular;
  text-transform: uppercase;
  display: block;
  margin-bottom: 1em;
  text-align: $position;
  color: $w;
  text-shadow: 0 3px 6px #ee1e8f, 0 11px 10px #ee1e8f;
}

@mixin inner_lines($colol: $w){
  content: '';
  @include abs;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  border:1px solid $colol;
}

$w:#fff;
$blue:#4bb4ff;
$red: #f2326d;
$border:rgba(255,255,255,.2);



$pbold:'Prompt Bold';
$psemibold:'Prompt SemiBold';
$pregular:'Prompt Regular';
$pmedium:'Prompt Medium';
$hlight:'Heebo Light';
$hregular:'Heebo Regular';
$hbold:"Heebo-Bold";
$hmedium:"Heebo-Medium";


