
#root {
	
	.wrap_user_emails {
		display: block;
		position: relative;
		width: 100%;
		max-width: 92em;
		height: 100vh;
		font-size: 10px;
		padding: 9em 4em 5em;
		z-index: 2;
		margin: auto;
		@media(max-width: 768px){
			font-size: 9px;
			padding: 9em 2em 5em;
		}
		@media(max-width: 550px){
			font-size: 8px;
		}

		&+footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			.wrap_socials  {
				display: none;
			}
			.copyright {
				display: none;
				&.center {
					display: block;
					p{
						border:0;
						padding-left: 0;
						margin-left: 0;
					}
				}
				.privacyPolicy {
					display: none;
				}
			}
		}
		.head_page {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			color: $w;
		}
		.total {
			font-size: 1.1em;
			font-family: $pmedium;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
		.block_title {
			font-size: 6em;
			color: $w;
			font-family: $hregular;
			line-height: 1;
			position: relative;
			z-index: 3;
			
			&_subtitle {
				font-family: $hlight;
				font-size: 1.3em;
				color: $w;
			}
			@media(max-width: 768px){
				max-width: 100%;
				font-size: 5em;
			}
			@media(max-width: 500px){
				font-size: 4.55em;
				line-height: 1.55;
			}
		}
		.user_list {
			width: 100%;
			display: block;
			margin: 2em auto 0;
			background: rgba(23, 43, 86, 0.61);

			.line {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1.1em 2em;
				border-bottom: 1px solid $border;
				position: relative;
				@media(max-width: 550px){
					padding: 1.1em 0 1.1em 2em;
				}

				
				&.field_copied {
					
					.copy_status {
						opacity: 0;
						transition:1s opacity;
						transition-delay: 1.5s;
						transition-property: opacity;
					}
					.copy_status {
						&:before {
							left: 0; 
							right: auto;
							width: 1em;
							transition:.4s; 
							transition-delay:.2s;
							transition-property: width; 
						}
						&:after {
							bottom: 0;
							top: auto;
							height: 100%;
							transition:.4s; 
							transition-delay:.4s;
							transition-property: height;
						}
					}
				}

				&:hover, &.active {
					background:$border;
				}
				&.caption {
					background:$border;
					span {
						font-size: 1.1em;
						font-family: $pmedium;
						text-transform: uppercase;
						letter-spacing: 1px;

					}
					.email {
						span {
							font-size: 1.1em;
						}
					}
				}
				&:not(.caption) {
					.email {
						span {
							font-size: 1.8em;
							@media(max-width: 500px){
								font-size: 1.4em;
							}
						}
					}
					div {
						&:not(.options) {
							cursor: pointer;
							span {
								cursor: pointer;
							}
						}
						&:not(.email) {
							span {
								font-family: $hlight;
							}
						}
					}
				}
				&:last-child {
					border-bottom: 0;
				}
				span {
					color: $w;
					font-size: 1.2em;
				}
				svg {
					width: 1.5em;
					min-width: 1.5em;
					height: 1.5em;
					fill:$w;
					cursor: pointer;
					&:hover {
						fill:#4bb4ff;
					}
				}
				div {
					&:not(.line_date), &:not(.copy_status) {
						padding: 0 1em;
						@media(max-width: 550px){
							padding-left: 0;
						} 
					}
				}  
				.line_date {
					display: flex;
					align-items: center;
					width: 44%;
					@media(max-width: 550px){
						display:block;
						width: 8em;
						min-width: 8em;
						
						span {
							white-space: nowrap;
						}
					}
				}
				.date {
					width: 50%;
					padding-left: 0;
				}
				.time {
					width: 50%;
				}
				.email {
					width: 46%;
					@media(max-width: 550px){
						width: 15em;
					}
					span {
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 100%;
						overflow: hidden;
						display: block;
						
					}
				}
				.options {
					width: 10%;
					padding-right: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					@media(max-width: 550px){
						width: 7em;
						min-width: 7em;
					}
					.remove {
						margin: 0 1em;
					}
				}
				.copy_status {
					position: relative;
					top: 0;
					bottom: 0; 
					margin: auto;
					transition:0s opacity;
					opacity: 1;
					@include fb;
					width: 1em;
					height: 2em;
					min-width: 1em;
					margin: 0 0 .8em .5em;
					box-sizing: content-box;
					transform: rotate(45deg);

					&:before { 
						content: '';
						width: 0;
						height: 2px;
						background:#4bb4ff;
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						right: auto;
						transition:0s;
						
					}
					&:after {
						content: '';
						width: 2px;
						height: 0;
						background:#4bb4ff;
						display: block;
						position: absolute;
						right: 0;
						bottom: 0;
						top: auto;
						transition:0s;
					}
				}
				
			}
		}
	} 
}