section {
	&.main_content {
		.help_center {
			@media(max-width: 550px){
				font-size: 8px ;
			}
		}
	}
}
.block_window {
	.questions_text {
		display: block;
		text-align: center;
		color: $w;
		padding-top: 3em;
		.title {
			font-family: $hregular;
			font-size: 3em;
			line-height: 1;
			margin-bottom: 1em;
		}
	} 

	&.help_center {
		padding: 8em 0;

		
		.sphere {
			position: relative;
			width: 13.5em;
			min-width: 13.5em;
			height: 13.5em;
			border-radius: 50%;
			z-index: 0;
			transition: .7s;
			@include fb;
			margin:0 3em 2em 0;
			transform:scale(1);
			opacity: 1;

			&.red {
				&:after {
					background:radial-gradient(ellipse at center,rgba(162, 34, 125, 0) 0,rgba(162, 34, 125, 0) 27%,rgba(162, 34, 125, 0.37) 48%,rgba(162,34,125,.64) 60%,#a2227d 100%);

				}
			}
			&.blue {
				&:after {
					background:radial-gradient(ellipse at center,rgba(51,117,177,0) 0,rgba(51,117,177,0) 27%,rgba(51,117,177,.37) 48%,rgba(51,117,177,.64) 70%,#a2227d 100%);
					
				}
			}
			&.green {
				&:after {
					background:radial-gradient(ellipse at center,rgba(32,124,117,0) 0,rgba(32,124,117,0) 27%,rgba(32,124,117,.37) 48%,rgba(32,124,117,.64) 70%,#a2227d 100%);
					
				}
			}
			&:before {
				content: "";
				width: 53%;
				height: 53%;
				border: 1px solid rgba(255,255,255,.2);
				border-radius: 50%;
				display: block;
				@include abs;
				animation:sphere_waves 4s  linear infinite;
			}
			.waves {
				width: 100%;
				height: 100%;
				border-radius:50%;
				@include abs;
				&:before {
					content: "";
					width: 53%;
					height: 53%;
					border: 1px solid rgba(255,255,255,.2);
					border-radius: 50%;
					display: none;
					@include abs;
					
				}
			}
			&:after {
				content: "";
				width: 70%;
				height: 70%;
				border-radius:50%;
				background: #a2227d;
				transition:.4s;
				@include abs;
			}

		}
		svg {
			&.core {
				position: absolute;
				z-index: 1;
				@include abs;
				height: 4.5em;
				transition:.3s;
				top:0;
				opacity: 1;
			}
			&.sphere_general {	
				& > .cls-1 {
					fill: #357ab8;
				}

				& > .cls-1, & > .cls-2 {
					fill-rule: evenodd;
				}

				& > .cls-2 {
					fill: #4bb4ff;
				}
			}
			&.sphere_mobile {
				& > .cls-1 {
					fill: #ee1e8f;
					stroke: #9c1f51;
					stroke-width: 3px;
				}

				& > .cls-2 {
					fill: #f44da8;
					fill-rule: evenodd;
				}

				& > .cls-3 {
					fill: #9c1f51;
					stroke: #9f2467;
					stroke-width: 1px;
				}
			}
			&.sphere_tablet{
				height: 3.5em;
				& > .cls-1 {
					fill: #28c08f;
					stroke: #1d7d5e;
					stroke-width: 3px;
				}

				& > .cls-1, & > .cls-2 {
					fill-rule: evenodd;
				}

				& > .cls-2 {
					fill: #3fd6a5;
				}
			}
		}

		

		.group_category_questions{
			display: flex;
			align-items: stretch;
			margin: 4em auto 3em;
			background-color: rgb(23, 43, 86);
			box-shadow: 0 15px 27px 0 rgba(10, 21, 50, 0.77);
			flex-wrap: wrap;
			@media (max-width: 768px) {
				display: block;
			}
			&.active {
				.sphere {
					transform: scale(1);
					opacity: 1;
					transition-delay: .3s;
					transition-property:transform, opacity; 
				}
			}
			.sphere {
				transform: scale(.6);
				opacity: 0;
				transition: .4s transform, .4s opacity;
			}
			.col {
				flex: 1;
				position: relative;
				min-height: 20em;
				padding: 2em;
				color:$w;
				cursor: pointer;
				display: block;
				&.active {
					// .sphere {
					// 	transform:scale(1);
					// 	opacity: 1;
					// 	.waves {
					// 		svg {
					// 			top: 0;
					// 			opacity: 1;
					// 			transition-delay: .4s;
					// 		}
					// 	}
					// }
				}
				&:hover {
					.sphere {
						&:after {
							width: 75%;
							height: 75%;
						}
						.waves {
							&:before {
								display: block;
								animation:sphere_waves 5s  linear infinite;
							}
						}
					}
					.title {
						svg {
							margin-left: 1.3em;
						}
					}
				}
				&:nth-child(1) {
					
					&:after {
						height:.1em;
						content: '';
						width: 0;
						position: absolute;
						right: 0;
						left: 0;
						bottom: 0;
						margin: auto;
						background:$border;
						// transition:.4s;
						display: none;
					}
					@media (min-width: 769px) and (max-width: 992px) {
						width: 100%;
						flex-basis: 100%;
						display: flex;
						align-items: center;
						&:after {
							display: block;
							width: 100%;
							// transition-delay:.5s;
						}
					}
				}

				&:nth-child(2) {
					
					
					&.active {
						&:after, &:before {
							@media (min-width:768px) {
								height:100%;
								// transition-delay: 1.1s;
							}
							@media (max-width:768px) {
								width: 100%;								
								// transition-delay: 1.1s;
							}
						}
					}
					&:before {
						content: '';
						width: .15em;
						height: 100%;
						position: absolute;
						left: 0;
						bottom: 0;
						background:$border;
						// transition:.4s;
						@media (max-width:768px) {
							width: 100%;
							height: .15em;
						}
						@media (min-width: 768px) and (max-width: 992px) {
							display: none;
						}
					}
					&:after {
						height:100%;
						content: '';
						width: .15em;
						position: absolute;
						right: 0;
						top: 0;
						background:$border;
						// transition:.4s;
						@media (max-width:768px) {
							width: 100%;
							height: .15em;
						}
					}
				}
				.title {
					font-family: $hregular;
					font-size: 2em; 
					display: flex;
					align-items: center;
					line-height: 1.2;

					svg {
						width: 1.5em;
						margin-left: .8em;
						fill:$w;
						transition:.4s;
					}
				}
				.subtitle {
					font-family: $hlight;
					color: #848db1;
					font-size: 1.3em;
					line-height: 1;
					@media (max-width: 992px) {
						display: block;
					}
				}
			}
		}

		.group_questions {
			display: block;
			background-color: rgb(23, 43, 86);
			width: 100%;
			padding:3em 3em 4em;
			color:$w; 
			box-shadow: 0 0 16px -5px rgba(10, 21, 50, 0.52);
			margin-bottom: 4em;
			.title {
				&_line {
					font-family: $hregular;
					font-size: 3em;
					margin-bottom: .5em;
				}
			}
			&_line {
				border-bottom: .1em solid $border;
				padding: 1.2em 0;
				position: relative;
				&.active {
					&:before, &:after {
						width: 100%;
					}
					.group_questions {
						&_indicator {
							transform:rotate(-45deg);
							&:before, &:after {
								transition:0s;
							}
						}
						&_body {
							padding: 2.5em 0 2em;
							display: block;
							max-height: 90em;
							opacity: 1;
							p {
								top: 0;
							}
						}
					}
				}
				&:before {
					width: 0;
					content: '';
					height: .15em;
					position: absolute;
					bottom: -1px;
					left: 0;
					background: $w;
					transition:.7s;
				}
				&:after {
					right: 0;
					left: auto;
				}
			}
			&_col {
				margin-bottom: 5em;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&_head {
				display: flex;
				align-items: center;
				width: 100%;
				cursor: pointer;
			}
			&_indicator {
				width: 2em;
				min-width: 2em;
				height: 2em;
				fill:$w;
				position: relative;
				transition:.5s;
				& + .group_questions_title {
					margin-left: 3em;
				}
			}
			&_step {
				margin: 0 2em 0 3em;
				h2 {
					font-family: $hregular;
					font-size: 1.8em;

					white-space: nowrap;
				}
			}
			&_title {
				h3 {
					font-family: $hregular;
					font-size: 1.8em;
					line-height: 1.6;
				}
			}
			&_body {
				width: 100%;
				max-width: calc(100% - 5em);
				display: block;
				margin-left: auto;
				overflow: hidden;
				transition:.4s;
				max-height: 0;
				padding: 0;
				opacity: 0;
				p {
					font-family: $hlight;
					font-size: 1.5em;
					display: block;
					line-height: 1.3;
					margin-bottom: 1em;
					position: relative;
					transition:.4s;
					top: -1.5em;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.group_questions_line {
	.t_italic {
		font-style: italic;
		font-size: 1.5em;
		display: inline-block;
	}
	p {
		.t_italic {
			font-style: italic;
			font-size: inherit;
			margin-right: 14px;
		}
	}
}
.group_questions_col {
	
	.general_list {
		li {
			display: block;
			margin-bottom: 2em;
			&:not(.list_none) {
				&:before {
					content: '';
					width: 1em;
					height: 1em;
					border-radius: 50%;
					display: inline-block;
					background: #fff;
					float: left;
					margin-right: 10px;
					top: .4em;
					position: relative;
				}
			}
			
		}
		p{
			font-family: "Heebo Light", serif;
			font-size: 1.5em;
			display: block;
			line-height: 1.3;
			i, a {
				font-size: inherit;
				color: inherit;
			}
			a {
				text-decoration: underline;
				&:hover {
					color: $blue;
				}
			}
		} 
		i,.t_italic {
			font-style: italic;
			font-size: 1.5em;
			display: inline-block;
		}
		.t_italic {
			display: block;
		}
	}
}

@keyframes sphere_waves {
	0% {
		width: 80%;
		height: 80%;
		opacity: 0;
	}
	80% {
		width: 100%;
		height: 100%;
		opacity: 1;
	}
	90% {
		width: 100%;
		height: 100%;
		opacity: 0.5;
	}
	95% {
		width: 100%;
		height: 100%;
		opacity: 0;
	}
	
	100% {
		width: 80%;
		height: 80%;
		opacity: 0;
	}
}



@keyframes fadein {
	from {
		opacity: 0;
		display: none;
	}
	to {
		opacity: 1;
		display: block;
	}
}

.group_questions_body {
	a {
		color: $red;
		&:hover {
			color: $blue;
		}
	}
}

