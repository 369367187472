#root {
	.slider, .present-slider {
		position: relative;
		width: 100%;
		z-index: 8;
		margin-bottom: 0;
		transition:.4s opacity;
		@media(min-width: 1024px){
			overflow: hidden;
		}
		@media(max-width: 1024px){
			font-size: 8px;
			&.active {
				.rose_planet {
					.planet {
						&:before {
							animation:planet_lines 20s  linear 9; 
						}
					}
				}
			}
		}
		
		@media (max-width: 992px) {
			z-index: 3;	
			margin-bottom: 0;
		}
		@media(max-width: 768px){
			margin-bottom: 15em;
			// font-size: 7px;
		}
		@media(max-width: 550px){
			margin-bottom: 0;
		}


		&.regular {
			@media(max-width: 1024px){
				display: none;
			}
		}

		&.slick-slider {
			@media(max-width: 768px){
				max-height: 100vh;
			}
		}
		.wrapDevices {
			@media(min-width: 2461px){
				font-size: 18px;
			}
			@media(min-width: 1400px){
				font-size: 1.6vh;
			}
		}

		.slick-arrow  {
			display: none !important;
		}


		&.hidden {
			z-index: -1;
			opacity: 0;
		}
		&.none {
			@media(min-width: 992px){
				opacity: 0;
				pointer-events: none; 
			}
			.main_slider_dots {
				bottom: -4em;
				opacity: 0;
				transition-delay: .9s,0s;
				transition-property: bottom,opacity;

			}
		}
		.slick-list {
			min-height: 100vh;
		}
		.test {
			font-size: 15em;
			color: $w;
		}
		.main_slider_dots {
			position: fixed;
			display: flex !important;
			align-items: center;
			justify-content: center;
			z-index: 10;
			margin: 0 auto;
			transform: translate(-50%,0);
			left: 50%;
			bottom: 2.5em;
			opacity: 1;
			transition:1.1s bottom, 1.1s opacity;
			
			@media (max-width: 768px) {
				display: none !important;
			}

			li {
				cursor: pointer;
				width: 17em;
				max-width: 17em;
				text-align: center;
				position: relative;
				border-bottom: .1em solid $border;
				color:$border;
				height: 1em;
				border-left: .1em solid $border;
				border-right: .1em solid $border;
				border-bottom:.1em solid $border;
				margin-right: -1px;
				cursor: pointer;
				&:nth-child(even) {
					border-left: 0;
					border-radius: 0;
				}

				&:before {
					content: '';
					position: absolute;
					z-index: 1;
					bottom: -.05em;
					display: block;
					width:0;
					height: .1em;
					left: 0;
					right: 0;
					margin: auto;
					background:$w;
					transition:.7s width;
					transition-delay: .2s;
				}
				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					bottom: -.05em;
					display: block;
					width: 100%;
					transition:.4s;
					height: 0;
					left: 0;
					right: 0;
					border-left: .1em solid $w;
					border-right: .1em solid $w;
				}

				button {
					font-size: 1.1em;
					border: 0;
					bottom: 2em;
					display: block;
					width: 100%;
					position: relative;
					cursor: pointer;
					z-index: 2;
					color: inherit;
					&:before{
						content: '0';
					}
				}

				&.slick-active {
					color: $w;
					&:before {
						width: 101%;
					}
					&:after {
						height: 100%;
						transition-delay: .7s;
					}
				}
			}
		}
		&-track, .slick-track {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			align-items: flex-start;
			position:fixed;
			z-index: 1;
			@media(max-width: 768px){
				display: block;
			}
		}
		.bottom_line {
			display: flex;
			align-items: center;
			padding: 6em 0 5em 9em;
			position: relative;

			@media (max-width: 768px) {
				padding: 4em 0 3em;
			}
			@media(max-width: 600px){
				display: block;
			}
			.download_links {
				@media(min-width: 769px){
					position: relative;
					left: 80px; 
					opacity: 0;
					transition:.9s left, .9s opacity ;

				}
				@media(max-width: 600px){
					padding: 2em 0 0 5.5em;
				}
			}
			&>div {
				margin-right: 7em;

				@media (max-width: 992px) {
					&:not(:last-child) {
						margin-right: 0;
						width: 100%;
						max-width: 45em;
					}
					&:last-child {
						margin-right: 0;
					}
				}
				@media(max-width: 768px){
					&:not(:last-child) {
						margin-right: 0;
						width: 100%;
						max-width: 100%;
						padding-left: 5.5em;
					}
				}
				@media(max-width: 550px){
					&:not(:last-child) {
						padding-left: 4em;
					}
				}
			}
			&_subtitle {
				font-family: $hlight;
				font-size: 1.3em;
				@include letter;
				display: none;

			}
			&_title {
				font-family: $hregular;
				font-size: 1.88em;
				@include letter;
				@media (max-width: 550px) {
					font-size: 1.5em;
				}
			}


			[class^=bottom_line_] {
				color:$w;
				@media(min-width: 768px){
					position: relative;
					left: 80px;
					opacity: 0;
					transform: skewX(25deg);
					transition:.9s left, .9s opacity ,.9s transform;
				}
				@media(min-width: 768px){
					display: block;
				}
			}


		}
		.container {
			position: relative;
			z-index: 10;
		}

		[data-index="0"] {
			.present {
				&_line {
					@media(min-width: 768px){
						left: 5em;
						opacity: 0;
						transform: skewX(20deg);
						transition:.9s left, .9s opacity, .7s transform; 
					}
				}
			}
			.description {

				@media(max-width: 768px){
					transition:0s;
					transition-delay: 0s;
				}
			}
			&.slick-active {
				.description {
					&_text {
						@media(max-width: 768px){
							transition:0s;
							transition-delay: 0s;
						}
					}
				}
				.present {
					&_info {
						left: 0;
						opacity: 1;
						transition-delay: .4s, .4s;
					}
					&_line {
						transform:skewX(0deg);
						left:0;
						opacity: 1;
						transition-delay: .4s, .4s,.4s;
					}
				}
			}
		}
		[data-index="2"], [data-index="1"]  {
			.container {
				@media(max-width: 768px){
					position: static;
				}
			}
			.how_to_use {
				position: relative;
				right: -10em;
				opacity: 0;
				margin: 3em 0 1em;
				transition: .8s;
				@media(min-width: 769px){
					span {
						transition: .8s;
						position: relative;
						transform: skewX(20deg);
					}
				}
				@media(max-width: 768px){
					bottom: 13em;
					right: 2em;
					transition:0s;
					position: absolute;
					top: auto;
					transition-delay: 0s;
				}
			}
			.description {
				position: static;
				&_text {
					right: -10em;
					opacity: 0;
					position: relative;
					opacity: 0;
					transform: skewX(20deg);
					transition: .8s;


					@media(max-width: 768px){
						transition:0s;
						transition-delay: 0s;
					}
				}
			}
			&.slick-active {
				.slide {
					&.two, &.three {
						.how_to_use {
							right: 0;
							opacity: 1;
							transform:skewX(0deg);
							transition-delay: .5s,.5s,.5s;
							transition-property: right,opacity,transform;

							@media(min-width: 769px){
								span {
									transform:skewX(0deg);
									transition-delay: .35s;
									transition-property: right;
								}
							}

							@media(max-width: 768px){
								right: 2em;
								transition:0s;
								transition-delay: 0s;
							}
						}
						.description {
							&_text {
								right: 0;
								opacity: 1;
								transform:skewX(0deg);
								transition-delay: .35s,.35s,.75s;
								transition-property: right,opacity,transform;

								@media(max-width: 768px){
									transition:0s;
									transition-delay: 0s;
								}
							}
						}
					}
				}
			}
		}


		.slick-active {
			.bottom_line {
				[class^=bottom_line_] {
					@media(min-width: 768px){
						left: 0;
						opacity: 1;
						transform: skewX(0deg);
						transition-delay:.35s, .35s, .75s;
						transition-property: left,opacity,transform ;
					}
				}
				.download_links {
					@media(min-width: 768px){
						left: 0;
						opacity: 1;
						transition-delay:.45s, .45s;
						transition-property: left,opacity ;
					}
				}

				&_subtitle , &_title { 
					@include letter_active;
				}
				&_title {
					@include letter_anim;
				}
				&_subtitle {
					@media (max-width: 768px) {
						display: none;
					}
				}
			}

			.slide, .slide_window {
				.wrapDevices {
					@media(min-width: 769px){
						right: 0;
						transition-delay: .2s;
						transition-property: right;
					}
				}
				.present {
					&_title {
						left: 0;
					}
				}
				.present_title {
					&.one {
						margin-bottom: 5px;
					}
				}


				.top_phone {
					right: 42em;
					display: block;
					top: 0;
					bottom: auto;

					@media(max-width: 1024px){
						top: -0;
						bottom: auto;
					}
					@media(max-width: 768px){
						display: block;
						bottom: -50em;
						top: auto;
						z-index: 1;
						left: 0;
						opacity: 1;
						right: auto;
						min-width: 61em;
						width: 100%;
						max-width: 67em;
					}
					@media(max-width: 600px){
						left: -8em;
						bottom: -36em;
						max-width: 56em; 
						min-width: 55em;
					}
					@media(max-width: 470px){
						bottom: -37em;
						left: -15em;
					}
					@media(max-width: 370px){
						left: -19em;
					}
				}
				.bottom_phone {
					right: 0em;
					@media(max-width: 768px){
						transition: 0s;
						transition-delay: 0s;
						right: auto;
						left: 0;
						left: 7em;
						min-width: 21em;
						margin: auto;
						bottom: -36em;
						max-width: 67em;
						max-width: 61em;
						display: none;
					}
					@media(max-width: 550px){
						bottom: -33em;
					}
				}
				.top_tablet {
					top: -10%;
					left: 18em;
					@media (max-width: 1024px) {
						left: auto;
						right: -18%;
						width: 100%;
						max-width: 85em;
					}
					@media(max-width: 768px){
						display: none;
						left: auto;
						right: -4em;
						top: -13em;
						width: 83em;
					}
					@media (max-width: 560px) {
						display: none;
					}
				}
				.bottom_tablet {
					right: 0;
					@media (max-width: 1024px) {
						right: auto;
						left: -31%;
						bottom: -19em;
						width: 100%;
						max-width: 85em
					}

					@media(max-width: 768px){
						transition-delay: 0s;
						min-width: 85em;
						width: 100%;
						max-width: 100%;
						max-width: 100%;
						bottom: -22em; 
						left: -33em;
						right: auto;
					}

					@media(max-width: 550px){
						left: -29em;
						bottom: -10em;
					}
					@media(max-width: 480px){
						min-width: 78em;
						bottom: -14em;
					}
					@media(max-width: 410px){
						min-width: 67em;
						left: -29em;
						bottom:-11em;
					}
					@media(max-width: 350px){
						left: -33em;
					}

				}
				&:not(.one) {
					.present {
						&_title, &_info {
							@include letter_active;
						}
						&_info {
							@include letter_anim;
							&:before {
								width: 2.7em;
							}
						}
						@media(max-width: 768px){
							&_title {
								&.one {
									transition:0s;
									top: 0;
									opacity: 1;
									transition-delay: 0s, 0s;
									transition-property: top, opacity;

								}
								&.two {
									transition:0s;
									top: 0;
									opacity: 1;
									transition-delay: 0s, 0s;
									transition-property: top, opacity;

								}
							}
						}
					}
				}
				&.one {
					.rose_planet {
						bottom: 10em;
						transition: 1s transform,.9s padding;
						padding: 2em;
						transition-delay:0s, .4s ;
						transition-property: transform, padding;
						@media(max-width: 768px){
							transition:0s;
							transition-delay: 0s;
							bottom: 50%;
						}

						.planet {
							&:before {
								transform: rotate(11deg);
								@media(min-width: 1025px){
									animation:planet_lines 20s  linear 9; 
								}
							}
						}
					}
					.name_room {
						&.room1 {
							right: 12.5em;
							bottom: 17em;
							transition: .6s;
							transition-delay:.1s;
							transition-property: right,bottom;
						}
						&.room2 {
							right: 35em;
							top: 22em;	
							transition: .6s;
							transition-delay:.1s;
							transition-property: right,top;
						}
						&.room3 {
							right: 6em;
							top: 11em;
							transition: .6s;
							transition-delay:.1s;
							transition-property: right,top;
						}

						span {
							font-size: 3em;
							line-height: 1;
							font-family: $hregular;
							display: block;
							text-align: center;
							&:after {
								opacity: 1;
								transition-delay: .8s;
							}
						}
					}
					.groupe_device {
						.general_communication {
							path {
								transition:7s;
								stroke-dashoffset: 0 ;
								transition-delay: 1s;
							}
						}

						.slidePhone {
							bottom: 10em;
							right: 22em;
							opacity: 1;
							transition-delay: 0s;
						}
						.slideTabletReserved {
							top: 3em;
							opacity: 1;
							right: 37em;
							transition-delay: 0s;
						}
						.slideTabletFree {

							opacity: 1;
							right: -1em;
							transition-delay: .2s;  
						}
						.slideTabletFreeBottom {

							opacity: 1;
							right: -19em;
							transition-delay:.2s;
						}
					}
					.present {
						&_title, &_info {
							@include letter_active;
						}
						&_info {
							span {
								letter-spacing: 2px;
							}
							&:before {
								transition-delay:.4s;
								transition-property: width;
								width: 2.7em;
							}
						}


						@media(max-width: 768px){
							&_title {
								&.one {
									top: 0;
									opacity: 1;
									transition-delay: 0s;
									transition:0s;
								}
								&.two {
									top: 0;
									opacity: 1;
									transition-delay: 0s;
									transition:0s;
								}
							}
						}
					}
					@media(max-width: 768px){
						.present {
							&_line {
								opacity: 1;
								left: 0;
								transform:skewX(0deg);
								transition-delay: .35s,.35s,.45s;
								transition-property: left,opacity,transform;
							}
						}
						.mobileDevices {
							transition-delay:0s;
							top: 0;
							right: 0;
							transition:0s;
						}
						.rose_planet {
							left: -2em;
							bottom: 55%;
							transform:scale(.7);
							transition-delay: 0s;
							transition:0s;
						}
					}
				}
				&.two{
					@media(max-width: 550px){
						margin-bottom: 5em;
						padding-bottom: 32em;
					}
				}
				&.two, &.three {
					@media(max-width: 768px){
						min-height: 10vh;
						max-width: 100%;
						padding-bottom: 30em;
						margin-bottom: 5em;
						.description {
							&_text {
								display: none;
							}
						} 
					}
					.how_to_use {

						@media(min-width: 1025px){
							span {
								transition: .8s;
								position: relative;
								transform: skewX(20deg);
							}
						}
						@media(max-width: 768px){
							bottom: 0;
							right: 2em;
							transition:0s;
							position: absolute;
							top: auto;
							transition-delay: 0s;
						}
					}
				}

				&.three {
					@media(max-width: 1024px){
						align-items: flex-start;
						margin-bottom: 7em;
					}
					@media(max-width: 550px){
						padding-top: 5em;
						padding-bottom: 42em;
					}
					@media(max-width: 480px){
						padding-bottom: 35em;
					}

					@media(max-width: 410px){
						padding-bottom: 30em;
					}
				}
			}
		}

		@keyframes dash {
			0% {
				stroke-dashoffset: 2921;
			}
			100% {
				stroke-dashoffset: 0;
			}
		}
		@keyframes bubble {
			0% {
				width: 16em;
				height: 16em;
			}
			100% {
				width: 13em;
				height: 13em;
			}
		}
		.pulse_to_second_tablet {
			position: absolute;
			width: 9em;
			height: 27em;
			display: block;
			z-index: 3;
			opacity: .6;
			top: 10.90em;
			right: 31.1em;
			transform: rotate(-34deg) skew(6deg,10deg);
			box-sizing: content-box;
			overflow: hidden;
			.end {
				position: absolute;
				width: 3em;
				height: .1em;
				right: -50%;
				top: 0;
				box-shadow: 0px 0px 7px 1px #fff;
				background:$w;
				transition:0 right, 0s opacity;
				z-index: 2;
			}
			&:before {
				content: '';
				width: 3em;
				height: .1em;
				background:$w;
				position: absolute;
				right: 110%;
				bottom: 0;
				z-index: 2;
				transition:0s right, 0s width;
				box-shadow: 0px 0px 7px 1px #fff;
			}
			&:after {
				content: '';
				width: .1em;
				height: 3em;
				background:$w;
				position: absolute;
				right: 0;
				top: 105%;
				z-index: 2;
				transition:0s height, 0s top;
				box-shadow: 0px 0px 7px 1px #fff;
				display: block;
			}
			&.active {
				&:before {
					right: 0;
					width: 0;
					transition-delay:0s, .35s;
					transition-property:right, width;
				}
				&:after {
					top: 0;
					height: 0;
					transition-delay:.7s, .6s;
					transition-property:height, top;
				}
				.end {
					transition-delay: 1s,1.5s;
					transition-property: right, opacity;
					right: 100%;
					opacity: 0;
				}
			}
		}
		.pulse_to_third_tablet {
			position: absolute;
			width: 29.5em;
			height: 22.2em;
			display: block;
			z-index: 3;
			opacity: .6;
			top: 10.9em;
			right: 9.7em;
			transform: rotate(-34deg) skew(6deg,10deg);
			box-sizing: content-box;
			overflow: hidden;
			.top {
				width: 100%;
				height: 47%;
				display: block;
				position: absolute;
				top: 0;
				overflow: hidden;
				right: 0;

				&:after {
					content: '';
					width: .1em;
					height: 3em;
					display: block;
					overflow: hidden;
					position: absolute;
					right: 0;
					top: 110%;
					background:$w;
					box-shadow: 0px 0px 7px 1px #fff;
				}
			}
			.end {
				position: absolute;
				width: 72%;
				height: 56%;
				right: 0;
				bottom: 0;
				background: transparent;
				transition:0 right, 0s opacity;
				z-index: 2;
				overflow: hidden;
				box-sizing: content-box;

				&:before {
					content: '';
					width: .1em;
					height: 3em;
					position: absolute;
					left: 2.5%;
					top: 110%;
					background:$w;
					display: block;
					box-shadow: 0px 0px 7px 1px #fff;
				}
				&:after {
					content: '';
					width: 3em;
					height: .1em;
					position: absolute;
					right: 110%;
					top: 2.5%;
					background:$w;
					display: block;
					box-shadow: 0px 0px 7px 1px #fff;
				}
			}
			&:before {
				content: '';
				width: 3em;
				height: .1em;
				background:$w;
				position: absolute;
				right: 110%;
				bottom: 0;
				z-index: 2;
				transition:0s right, 0s width;
				box-shadow: 0px 0px 7px 1px #fff;
			}

			&.active {
				&:before {
					right: 70%;
					width: 0;
					transition:.4s right, .2s width;
					transition-delay:0s, .30s;
					transition-property:right, width;
				}
				&:after {
					top: 0;
					height: 0;
					transition-delay:.7s, .6s;
					transition-property:height, top;
				}
				.end {
					&:before {
						transition:.9s top, 0s opacity;
						transition-delay: .35s, 1s;
						transition-property: top,opacity;
						top: -50%;
						opacity: 0;
					}
					&:after {
						right: -30%;
						transition:.9s right, 0s opacity;
						transition-delay:.7s, 1.9s;
						transition-property:right, opacity;
						opacity: 0;
					}

				}
				.top {
					&:after {
						transition:.9s top, 0s opacity;
						transition-delay: 1.15s, 2.5s;
						transition-property: top,opacity;
						top: -50%;
						opacity: 0;
					}
				}
			}
		}
		.pulse_to_first_tablet {
			position: absolute;
			width: 23.5em;
			height: 16.2em;
			display: flex;
			z-index: 13;
			opacity: .6;
			top: 29.8em;
			right: 3.0em;
			transform: rotate(-34deg) skew(6deg,10deg);
			box-sizing: content-box;
			overflow: hidden;
			.end {
				width: 15.5%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				overflow: hidden;
				&:before {
					content: '';
					display: block;
					top: 0;
					left: -110%;
					width: 3em;
					height: .1em;
					position: absolute;
					background: $w;
					box-shadow: 0px 0px 7px 2px #fff;
				}
				&:after {
					content: '';
					display: block;
					top: -110%;
					right: 0;
					width: .1em;
					height: 3em;
					position: absolute;
					background: $w;
					box-shadow: 0px 0px 7px 2px #fff;
				}
			}
			.bottom {
				display: block;
				width: calc(100% - 15.5%);
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				overflow: hidden;
				&:before {
					content: '';
					display: block;
					bottom: 0;
					left: -110%;
					width: 3em;
					height: .1em;
					position: absolute;
					background: $w;
					box-shadow: 0px 0px 7px 2px #fff;
				}
			}
			&.active {
				.end {
					&:before {
						transition:.6s left, 0s opacity;
						transition-delay: .1s, 1s;
						transition-property: left, opacity;
						left: 110%;
						opacity: 0;
					}
					&:after {
						transition:.7s left, 0s opacity;
						transition-delay: .25s, 1s;
						transition-property: top, opacity;
						top: 110%;
						opacity: 0;
					}
				}
				.bottom {
					&:before {
						transition:.6s left, 0s opacity;
						transition-delay: .55s, 1s;
						transition-property: left, opacity;
						left: 110%;
						opacity: 0;
					}
				}
			}
		}
		.slide, .slide_window {
			display: flex !important;
			align-items: center;
			position: relative;
			padding: 8em 3.5em;
			z-index: 3;
			@media(min-width: 1024px){
				overflow: hidden;
			}
			@media(min-width: 768px){
				height: 100vh;
				max-height: 99.99vh;
			}
			@media (max-width: 768px) {
				height: auto;
				max-height: 100%;
				min-height: 100vh;
				max-height: 100vh;
				align-items: flex-start;
				padding: 10em 1.5em 8em;
			}
			.groupe_device {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 63em;
				z-index: 3;
				display: block;
				font-size: 10px;
				@media(min-width: 2560px) {
					top: 12%;
					right: 12%;
				}
				@media(min-width: 2450px) {
					font-size: 15px;

				}
				@media(min-width: 1400px) and (max-width: 1600px) { 
					font-size: 1.1em;
				}
				@media(min-width: 1601px) and (max-width: 1900px) { 
					font-size: 1.25em;
				}
				@media(min-width: 1900px) and (max-width: 2400px) { 
					font-size: 1.30em;
				}
				@media(max-width: 1024px) {
					font-size: 9px;
					height: 100%;
					align-items: flex-end;
					display: flex;
				}

				@media (max-width: 768px) {
					height: 100%;
					top: 3em;
					transform: scale(1);
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					align-items: flex-end;
					* {
						&:not(.mobileDevices){
							display: none;
						}
					}
				}
				.mobileDevices {
					display: none;
					transition:.8s top, .8s right;
					@media (max-width: 1024px) {
						display: block;
						width: 80%;
						display: block;
						right: -15%;
						margin-left: auto;
						bottom: 0;
						position: relative;
						transition:0s;
						transition-delay:0s;
					}
					@media(max-width: 500px){
						width: 160%;
						left: -10%;
					}
				}
				.general_communication {
					position: absolute;
					display: block;
					height: 47em;
					margin-left: auto;
					top: 7.5em;
					right: -2em;
					z-index: 0;

					path {
						stroke-dasharray: 2921;
						stroke-dashoffset: 2921;

					}

					.st0{

						fill:none;
						stroke:#5856A6;
					}
				}
			}
			.wrapDevices {
				@media(min-width: 769px){
					position: absolute;
					top: 0;
					right: -100%;
					width: 100%;
					height: 100%;
					transition:.7s right ease-out;
				}
			}
			&.one {
				@media(max-width: 1024px){
					overflow: hidden;
				}
				@media(max-width: 993px){
					padding-top: 13em;
				} 
				@media(max-width: 768px){
					overflow: visible;
					.description {
						&_text {
							width: 160px;
							margin-left: auto;
						}
					}
				}
			}

			&.two, &.three {
				@media(max-width: 1024px){
					.how_to_use {
						span {
							transform:skewX(0deg);  
						}
					}
				}
				@media(max-width: 993px){
					padding-top: 13em;
				}


				.container {
					align-items: flex-start;
					padding-top: 3em;
					@media (max-width: 1024px) {
						display: block;
						position: static;
						.present {
							display: block;
							width: 100%;
							max-width: 100%;
							justify-content: space-between;
							.download_links {
								margin: 0 auto;
							}

						}
						.description {
							margin:0 18em 0 auto;
						}
					}
				}
			}
			&.first_animation {
				.room2 {
					opacity: 1;
					transition: .6s opacity;
					transition-delay: 1.2s;
					transition-property: opacity;
				}
				.line1  {
					transition-delay:.3s;
					opacity: 1;
				}
				.groupe_device {
					.slidePhone {
						bottom: 12em!important;

					}
				}
				.pulse_to_second_tablet {
					&:before {
						right: 0;
						width: 0;
						transition:.7s right, .3s width;
						transition-delay:0s, .35s;
						transition-property:right, width;
					}
					&:after {
						top: 0;
						height: 0;
						transition:.7s height, .9s top;
						transition-delay:.7s, .6s;
						transition-property:height, top;
					}
					.end {
						transition:.8s right, 0s opacity;
						transition-delay: 1.1s,1.5s;
						transition-property: right, opacity;
						right: 100%;
						opacity: 0;
					}
				}
				.slideTabletReserved {
					transition-delay: .5s;
					top: 1em!important;
					right: 39em!important;


					&>img {
						&:last-child {
							transition-delay: 1.3s;
							opacity: 1;
						}
					}
				}
			}
			&.second_animation {
				.line1 {
					opacity: 0;
					transition-delay: 0s;
				}
				.line2 {
					opacity: 1;
				}
				.room2 {
					opacity: .3;
				}
				.room3 {
					opacity: 1;
					transition-delay: 2s;
					transition-property: opacity;
				}
				.groupe_device {
					.slidePhone {
						bottom: 12em!important;
						&:before {
							top: 3em;
						}
					}
				}
				.slideTabletReserved {
					top: 3em !important;
					right: 37em !important;

					&>img {
						&:last-child {
							transition-delay: 1.3s;
							opacity: 1;
						}
					}
				}
				.slideTabletFree {
					top: -13em !important;
					transition-delay:.9s;
					transition-property: top;

					&>img {
						&:last-child {
							transition-delay: 1.3s;
							opacity: 1;
						}
					}
				}
				.pulse_to_third_tablet {
					&:before {
						right: 70%;
						width: 0;
						transition:.4s right, .2s width;
						transition-delay:0s, .30s;
						transition-property:right, width;
					}
					&:after {
						top: 0;
						height: 0;
						transition-delay:.7s, .6s;
						transition-property:height, top;
					}
					.end {
						&:before {
							transition:.9s top, 0s opacity;
							transition-delay: .35s, 1s;
							transition-property: top,opacity;
							top: -50%;
							opacity: 0;
						}
						&:after {
							right: -30%;
							transition:.9s right, 0s opacity;
							transition-delay:.7s, 1.9s;
							transition-property:right, opacity;
							opacity: 0;
						}

					}
					.top {
						&:after {
							transition:.9s top, 0s opacity;
							transition-delay: 1.15s, 2.5s;
							transition-property: top,opacity;
							top: -50%;
							opacity: 0;
						}
					}
				}
			}
			&.third_animation {
				.line2 {
					opacity: 0;
				}
				.line3 {
					opacity: 1;
				}
				.room1 {
					opacity: 1;
				}
				.groupe_device {
					.slidePhone {
						bottom: 12em!important;

					}
				}
				.slideTabletFree, .slideTabletReserved, .slideTabletFreeBottom {
					&>img {
						&:last-child {
							transition-delay: 1s;
							transition-property: opacity;
							opacity: 1;
						}
					}
				}
				.slideTabletFreeBottom {
					bottom: 23em !important;
					right: -21em !important;
					&:before {
						top: 2em;
					}
				}
				.pulse_to_first_tablet {
					.end {
						&:before {
							transition:.6s left, 0s opacity;
							transition-delay: .1s, 1s;
							transition-property: left, opacity;
							left: 110%;
							opacity: 0;
						}
						&:after {
							transition:.7s left, 0s opacity;
							transition-delay: .25s, 1s;
							transition-property: top, opacity;
							top: 110%;
							opacity: 0;
						}
					}
					.bottom {
						&:before {
							transition:.6s left, 0s opacity;
							transition-delay: .55s, 1s;
							transition-property: left, opacity;
							left: 110%;
							opacity: 0;
						}
					}
				}
			}
			&.end_animation {
				.slideTabletFree, .slideTabletReserved, .slideTabletFreeBottom {
					&>img {
						&:last-child {
							transition-delay: 0s;
							opacity: 1;
						}
					}
				}
			}
			.line1 {
				position: absolute;
				right: 22.7em;
				top: 12.1em;
				width: 23.9em;
				z-index: 2;
				transition:.3s;
				opacity: 0;
				&>.st0{
					fill:none;
					stroke:url(#SVGID_1_);
				}
				&>.st1{
					fill:none;
					stroke:url(#Shape_659_copy_1_);
				}
			}
			.line2 {
				position: absolute;
				right: 11.2em;
				top: 9.35em;
				width: 35.4em;
				z-index: 2;
				opacity: 0;
				transition:.3s;
				transition-delay: 0s;
				transition-property: opacity;
				&>.st0{
					fill:none;
					stroke:url(#SVGID_1_);
				}
				&>.st1{
					fill:none;
					stroke:url(#Shape_659_copy_2_1_);
				}
			}
			.line3 {
				position: absolute;
				right: -1.9em;
				top: 33.7em;
				width: 48.5em;
				z-index: 2;
				transition:.3s;
				transition-delay: 0s;
				transition-property: opacity;
				opacity: 0;
				.st0{
					fill:none;stroke:url(#Shape_659_copy_4_1_);
				}
				.st1{
					fill:none;stroke:url(#SVGID_1_);
				}
			}
			.container {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				width: 100%;
				@media(min-width: 1921px){
					width: 100%;
					max-width: 1920px;
					margin: 0 auto;
				}
				@media(min-width: 1400px){
					width: 84%;
				}
			}
			.slick-list {
				min-height: 100vh;
			}
			.slidePhone {
				display: block;
				position: absolute;
				background-size: 100%;
				width: 22em;
				z-index: 4;
				bottom: -20%;
				right: -20%;
				opacity: 0;
				transition:.7s ease-out;
				img{
					width: 100%;
					height: 100%;
					display: block;
					position: relative;
					z-index: 2;
				}
				&:before {
					content: '';
					position: absolute;
					z-index: 1;
					transition:.6s;
					width: 121%;
					top: 2em;
					left: -2em;
					height: 121%;
					display: block;
					opacity: .77;
					background: url('../img/shadowMobile.png');
					background-size: 100% 100%;
				}
			}
			.slideTabletReserved, .slideTabletFree, .slideTabletFreeBottom {
				z-index: 4;
				transition:.7s ease-out;
				&>img {
					transition:1s;
					&:last-child {
						position: absolute;
						left: 0;
						top: 0;
						z-index: 2;
						opacity: 0;
					}
				}
				&:before {
					content: '';
					position: absolute;
					z-index: 1;
					width: 110%;
					height: 110%;
					top: 4em;
					left: -8em;
					transition:.5s;
					display: block;
					background: url('../img/shadowTablet.png');
					background-size: 100% 100%;
				}
			}
			.slideTabletReserved{
				opacity: 0;
				top: -20%;
				right: -20%;
				display: block;
				position: absolute;
				width: 100%;
				z-index: 5;
				max-width: 45em;
				img{
					width: 100%;
					height: 100%;
					display: block;
					position: relative;
					z-index: 2;
				}
			}
			.slideTabletFree{
				opacity: 0;
				top: -12em;
				right: -10%;
				display: block;
				position: absolute;
				width: 100%;
				max-width: 46em;
				img{
					width: 100%;
					height: 100%;
					display: block;
					position: relative;
					z-index: 2;
				}
			}
			.slideTabletFreeBottom{
				opacity: 0;
				bottom: 21em;
				right: -20%;
				display: block;
				position: absolute;
				width: 100%;
				max-width: 45em;
				z-index: 2;
				@media (max-width: 992px) {
					max-width: 42em;
				}
				img{
					width: 100%;
					height: 100%;
					display: block;
					position: relative;
					z-index: 2;
				}
			}
			.name_room {
				position: absolute;
				color: $w;
				display: flex;
				z-index: 3;
				opacity: .3;
				&:hover {
					color: $w;
				}
				&.room1 {
					transition:.4s right, .4s bottom, .4s opacity;
					right: -30em;
					bottom: -30em;
					transform: rotate(-25deg) skewX(20deg)  scale(.9);
				}
				&.room2 {
					transition:.4s right, .4s top, .4s opacity;
					right: -35em;
					top: -21em;
					transform: rotate(-25deg) skewX(20deg) scale(.85);
				}
				&.room3 {
					transition:.4s right, .4s top, .4s opacity;
					right: -40em;
					top: -20em;
					transform: rotate(-25deg) skewX(20deg) scale(.7);
				}

				span {
					font-size: 3em;
					line-height: 1;
					font-family: $hregular;
					display: block;
					text-align: center;
					&:after {
						content: 'Room';
						display: block;
						font-size: 0.4em;
					}
				}

				h3 {
					line-height: 1;
					font-family: $hregular;
					font-size: 5em;
				}
			}
			.present {
				display: block;
				position: relative;
				width: 100%;
				max-width: 85em;
				@media(max-width: 1024) { 
					width: 100%;	
					max-width: 100%;
					justify-content: space-between;
				}

				&_title {
					display: block;
					color: $w;
					line-height: 1.2;
					font-size: 8.3em;
					font-family: $hregular;
					padding-left: 1em;
					position: relative;
					left: 200px;
					@media(min-width: 1025px){
						transition:left 1.1s;
					}

					@media(max-width: 768px){
						transition:0s;
						transition-delay:0s;
						position: relative;
						opacity: 0;
						top: -3em;
					}
					&:first-child {
						text-align: left;
						padding-left: 0;

					}
					span {
						letter-spacing: .01em;
						position: relative;
						opacity: 0;
						top: -.8em;
						transition:opacity .6s,top .6s;
					}


					@media (max-width: 992px) {
						text-align: left;
						font-size: 7.2em;
					}
					@media (max-width: 768px) {
						font-size: 5.3em;
					}
					@media (max-width: 450px) {
						font-size: 3.7em;
					}
				}
				&_info {
					color: $w;
					align-items: center;
					width: 8.1em;
					display: block;
					position: relative;
					transition:.7s left, .7s opacity;
					font-family: $pbold;
					text-transform: uppercase;
					font-size: 1.4em;
					min-width: 7px;
					letter-spacing: .05em;
					text-align: right;

					@media (max-width: 992px) {
						position: absolute;
						bottom: 0;
						left: 0;
						justify-content: flex-end;
						width: 12.4em;
					}
					@media (max-width: 768px) {
						display: none;	
						position: absolute;
						bottom: 0;
						left: 5em;
					}
					&:before {
						content: '';
						width: 0;
						height: 2px;
						background: #fff;
						transition: .6s;
						position: relative;
						margin: 0 .6em 0 0;
						display: inline-flex;
						vertical-align: middle;
					}
				}
				&_line {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					padding:7em 0 0 8em;
					position: relative;


					@media (max-width: 992px) {
						padding: 7em 3em 0 0em;
						justify-content: flex-end;	
					}
					@media (max-width: 768px) {
						padding: 9em 0em 0 0em;
						justify-content: flex-end;	
					}
				}
			}
			.description {
				width: 100%;
				max-width: 30em;
				display: block;
				color: $w;
				&_title {
					font-size: 2em;
					display: block;
					padding-bottom: .5em;

				}
				&_text {
					position: relative;
					font-size: 1.3em;
					line-height: 1.65;
				}
			}
			.top_phone {
				top: 0;
				right: 42em;
				width: 45em;
				transition:  transform 1s;
				@media (max-width: 1024px) {
					width: 100%;
					max-width: 50em;
					right: 31em;
					top: 0;
				}
				@media (max-width: 768px) {
					display: none;
				}
			}
			.bottom_phone {
				right: 0em;
				width: 100%;
				bottom: -36em;
				max-width: 54em;
				transition:  transform 1s;
				@media (max-width: 1024px) {
					display: none;
				}
				@media (max-width: 768px) {
					left: 170%;
					display: block;
					transition-delay: 0s;
					transition:0s;
					transition-property: left;
				}
			}
			.top_tablet {
				top: -25%;
				left: 18em;
				width: 71em;
				transition: transform 1s;
				@media(max-width: 1024px){
					transition:0s;
					top: 0;
					right: 0;
				}
			}
			.bottom_tablet {
				bottom: -10%;
				right: 0;
				width: 75em;
				transition: 1s transform ;
				@media(max-width: 768px){
					right: auto;
					transition:0s;
					transition-delay:0s;
					transition-property: left;
					left: 200%;
				}
			}
		}
	} 
  
	&.safari {
		@media(min-width: 1024px){
			.slider {

				.slide {
					.present_title {
						left: 200px;
						position: relative;
						opacity: 0;
						// transform:skewX(-25deg);
						transition:  .99s left,.99s opacity,.99s transform;
					}
				}


				.slick-active {
					.slide {
						.present_title {
							left: 0;
							opacity: 1;
							transform:skewX(0deg);
							transition-delay:.25s,.25s,.65s; 
							transition-property: left,opacity,transform;
						}
					}
				}
			}
		}
	}
}

@keyframes ghost {
	0% {
		color: $border;
	}
	10% {
		color: $w;
	}
	20% {
		color: $w;
	}
	30% {
		color: $border;
	}
	40% {
		color: $border;
	}
	50% {
		color: $w;
	}
	60% {
		color: $w;
	}
	70% {
		color: $border;
	}
	80% {
		color: $w;
	}
	90% {
		color: $border;
	}
	100% {
		color: $w;
	}
}

.animate_element {
	position: absolute;
	z-index: 0;
}


.blue_planet {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: -110%;
	width: 65em;
	height: 65em;
	border-radius: 50%;
	z-index: 6;
	@include fb;
	transition:top 1.2s;
	border: 1px solid rgba(255,255,255,.2);
	&:after {
		content: "";
		width: 53%;
		height: 53%;
		border: 1px solid rgba(255,255,255,.2);
		border-radius: 50%;
		display: block;
		@include abs;
	}

	.planet {
		@include fb;
		width: 53%;
		height: 53%;
		border-radius:50%;
		position: relative;
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			border-radius:50%;
			background: rgba(54,124,190,0.22);
			background:radial-gradient(ellipse at center,rgba(54,124,190,.22) 0,rgba(54,124,190,.22) 32%,rgba(54,124,190,.45) 48%,rgba(54,124,190,.64) 60%,rgb(54, 124, 190) 100%);
			@include abs;
		}
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			@include abs;
			background: url('../img/lines_blue_planet.png');
			background-repeat-x:repeat;
			background-size: 56em;
			border-radius: 50%;

		}
	}
}
.rose_planet {
	position: absolute;
	left: 10em;
	bottom: -110%;
	width: 17em;
	height: 17em;
	border-radius: 50%;
	z-index: 0;
	transition:.5s;
	@include fb;
	@media(max-width: 768px){
		left: -30em;
		transition:.9s left, .7s bottom;
		bottom: -30em;
	}
	.planet {
		@include fb;
		width: 100%;
		height: 100%;
		border-radius:50%;
		position: relative;
		&:after {
			content: "";
			width: 100%;
			height: 100%;
			border-radius:50%;
			background: #a2227d;
			background:radial-gradient(ellipse at center,rgba(162,34,125,0) 0,rgba(162,34,125,0) 27%,rgba(162,34,125,.37) 48%,rgba(162,34,125,.64) 60%,#bf1e90 100%);
			@include abs;
		}
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			@include abs;
			background: url(../img/a2.png)0 -1em;
			background-repeat-y: no-repeat;
			background-size: 66em;
			border-radius: 50%;
			position: relative;
			z-index: 2;

		}
	}
}
@keyframes blue_planet_line {
	0% {
		width: 53%;
		height: 53%;
		opacity: 1;
	}
	90% {
		width: 100%;
		height: 100%;
		opacity: 1;
	}
	95% {
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	100% {
		width: 53%;
		height: 53%;
		opacity: 0;
	}
}
@keyframes planet_lines {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: -500px 0;
	} 
}