@import "fonts";
@import "vars";
@import "static";
@import "header";
@import "footer";
@import "background";
@import "slider";
@import "main";
@import "left_nav";
@import "modal_window";
@import "contact";
@import "help_center";
@import "rangeSlider";
@import "privacyPolicy";
@import "not_found";
@import "_user_emails";
@import "_how_it_work"; 
 
 

.preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	@include fb;
	background: rgb(20, 35, 77);
	z-index: 100;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: none;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	&:after {
		content: '';
		@include abs;
		background:url('../img/noise.gif');
		opacity: .3;
	}
	.logo {
		fill:$w;
		position: relative;
		z-index: 2;
		width: 280px;
	}
	img {
		display: none;

	}
	&:before {
		content: '';
		position: relative;
		background: url('../img/preloader.gif') no-repeat center 0;
		z-index: 2;
		background-size: 100%;
		width: 100%;
		max-width: 225px;
		height: 228px;
		margin-bottom: 90px;
	}
}


.slider {
	.slick-arrow {
		pointer-events: none !important;
		&:not(.qwe){
		}
	}
}


body {
	overflow-x: hidden;
	background: rgb(20, 35, 77);
	&.load {
		overflow: hidden;
		#noise {
			display: none;
		}
		.preloader {
			display: flex;
		}
	}
	&.hidden {
		overflow: hidden;
	}
	* {
		font-family: $hregular;
	}
	&.no_fixed {
		header,
		footer {
			position: absolute;
		}
	}
	&.hidden {
		@media(max-width: 768px){
			overflow: hidden;
		}
	}
}



.none {
	pointer-events: none;
}
#root {
	font-size: 10px;

	

	.download_links {
		display: flex;
		align-items: center;
		position: relative;
		z-index: 4;
		.googlePlay {
			.st0{fill:#A6A6A6;}
			.st1{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:0.2;stroke-miterlimit:10;}
			.st2{fill:#FFFFFF;}
			.st3{fill:url(#SVGID_1_);}
			.st4{fill:#ffce00;}
			.st5{fill:#f63449;}
			.st6{fill:#00f076;}
			.st7{opacity:0.2;enable-background:new    ;}
			.st8{opacity:0.12;enable-background:new    ;}
			.st9{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}
		}
		.appStore {

		}
		@media(max-width: 600px){
			padding: 2em 0 0 4em;
		}
		.download_links_wrap {
			&:first-child{
				margin-right: 2em;
			}
		}

		&_wrap {
			text-align: center;
			position: relative;
			cursor: pointer;

			&.no-active {
				cursor: default;
			}
			svg,img {
				position: relative;
				z-index: 2;
				height: 4em;
				width: 14em;
				@media(max-width: 992px){
					max-width: 18em;
					width: 100%;
				}    
				@media(max-width: 550px){
					max-width: 14em;
				}
			}
			p {
				display: block;
				span {
					position: relative;
					top: -20px;
					opacity: 0;
					transition:.3s top, .7s opacity;
				}
			}
			&_bottom_line {
				position: absolute;
				top: 110%;
				left: 0;
				right: 0;
				width: 100%;
				&>p {
					position: relative;
					transition: .4s;
					top:0;
					opacity: 1;
					color: #848db1;

				}
			}
			&_subtitle {
				color:$w;
				font-family: $hlight;
				font-size: 1.1em;
			}
			&_title {
				color:$w;
				font-family: $hregular;
				font-size: 2em;

			}
			&:hover {

				.download_links_wrap_bottom_line {
					&>p {
						top: 0;
						opacity: 1;
						span {
							top: 0;
							opacity: 1;
						}
					}

				}
			}
		} 


		&_social {
			border:.1em solid $w;
			@include fb;
			border-radius:50%;
			width: 7em;
			min-width: 7em;
			height: 7em;
			margin: 0 2.5em 1.5em;
			position: relative;
			box-sizing: content-box;
			@media (max-width: 992px) {
				margin: 0 1.7em 2.5em;
				width: 7em;
				min-width: 7em;
				height: 7em;
			}
			svg {
				fill:$w;
				width: 45%;
				height: 45%;
				position: relative;
				z-index: 1;
			}
			&:before {
				content: '';
				@include abs;
				width: 0;
				height: 0;
				border-radius: 50%;
				background:#4bb4ff;
				transition:.4s;
			}



		}
	}
	

	@media (min-width: 1921px) {
		font-size:12px;
	}
	@media (min-width: 1400px) {
		font-size: 1.1vh;
	}
	@media (max-width: 992px) {
		font-size: 9px;
		overflow-x: hidden;
	}
	@media (max-width: 768px) {
		font-size: 8px;
	}
	@media (max-width: 550px) {
		font-size: 7px;
	}

	.btn {
		cursor: pointer;
		color: $blue;
		border: 1px solid $blue;
		padding: 0.6em 1.5em;
		border-radius: 0.2em;
		position: relative;
		background: rgba(75, 180, 255, 0.102);
		font-size: 1.5em;
		font-family: $hregular;
		box-sizing: content-box;
		&:before {
			right: 0;
			left: auto;
			content: "";
			width: 0;
			height: 101%;
			position: absolute;
			top: 0;
			bottom: 0;
			display: block;
			transition: 0.4s;
			background: #4bb4ff;
			z-index: 0;
			border: 0;
		}
		&_toggle {
			display: flex;
			align-items: center;
			overflow: hidden;
			border-radius: 0.2em;
			border: 1px solid $blue;
			position: relative;
			@media(max-width: 768px){
				width: intrinsic;
				width: -moz-max-content;
				width: -webkit-max-content;
			}
			&:before {
				content: "";
				width: 0.1em;
				height: 109%;
				@include abs;
				display: block;
				background: $blue;
			}

			li {
				@include fb;
				position: relative;
				cursor: pointer;
				width: 20em;
				height: 2.7em;
				box-sizing: content-box;
				@media(max-width: 768px){
					width: 15em;
					height:3.7em;
					border: 0;
				}
				&.active {
					&:before {
						width: 110%;
					}
					span {
						font-family: $psemibold;
					}
				}
				&:first-child {
					&:before {
						right: 0;
						left: auto;
					}
				}
				&:before {
					content: "";
					width: 0;
					height: 109%;
					position: absolute;
					top: 0;
					bottom: 0;
					right: auto;
					left: 0;
					display: block;
					transition: 0.2s;
					background: $blue;
					z-index: 0;
					border: 0;
				}

				span {
					font-size: 1.0em;
					font-family: $pmedium;
					text-transform: uppercase;
					position: relative;
					z-index: 2;
					letter-spacing: 2px;
				}
				@media(max-width: 768px){
					font-size: 1.2em;
				}
			}
		}
		&:hover {
			&.clear {
				&:active {
					&:before {
						box-shadow: 0 0 10px -3px #000 inset,
						0 9px 11px 0 rgba(75, 180, 255, 0.3);
					}
				}
				&:before {
					right: auto;
					left: 0;
					width: 100%;
					box-shadow: 0 6px 8px 0 rgba(75, 180, 255, 0.3);
				}
				span {
					transition-delay: 0.1s;
				}
			}

			span {
				color: $w;
			}
		}
		&:active {
			box-shadow: 0px 0px 10px -3px #000000 inset;
		}
		span {
			position: relative;
			z-index: 1;
			transition-delay: 0.1s;
		}

		&.bright_blue {
			padding: 1.0em 1.8em;
			background: rgb(75, 180, 255);
			box-shadow: 0px 6px 8px 0px rgba(75, 180, 255, 0.3);
			color: $w;
			font-family: $psemibold;
			text-transform: uppercase;
			transition:box-shadow .2s;
			letter-spacing: 2px;
			@media(max-width: 768px){
				padding: 1.3em 1.5em;
			}
			&:hover {
				box-shadow: 0px 9px 11px 0px rgba(75, 180, 255, 0.3);
			}
			&:active {
				box-shadow: 0 0 10px -3px #000 inset,
				0 6px 8px 0 rgba(75, 180, 255, 0.3);
			}
		}
	}

	.socials {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		a {
			display: block;
			&:not(.envelope) {
				margin-right: 2.4em;
				svg {
					stroke: rgb(255, 255, 255);
					fill: transparent;
				}
			}
			&.envelope {
				svg {
					&:last-child {
						display: none;
					}
				}
			}
			&:hover {
				&.facebook {
					svg {
						stroke: #3b5998;
						fill: #3b5998;
					}
				}
				&.twitter {
					svg {
						stroke: #00aced;
						fill: #00aced;
					}
				}
				&.linkedin {
					svg {
						stroke: #0077b5;
						fill: #0077b5;
					}
				}
				&.envelope {
					svg {
						&:first-child {
							display: none;
						}
						&:last-child {
							display: block;
						}
					}
				}
			}
		}

		svg {
			display: block;
		}
	}

	.how_to_use {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		margin: 1em 0;
		cursor: pointer;
		width: intrinsic;
		width: -moz-max-content;
		width: -webkit-max-content;

		span {
			font-size: 1.5em;
			font-family: $hregular;
		}

		.plus {
			margin-left: 1em;
		}
		&:hover {
			.plus {
				border-color: #4bb4ff;
				&:before {
					width: 100%;
					height: 100%;
					box-shadow: 0px 0px 5px 0px #4bb4ff;
				}
			}
		}
	}

	.plus {
		@include fb;
		border: 1px solid $w;
		width: 2em;
		height: 2em;
		border-radius: 50%;
		position: relative;
		color: $w;
		font-size: 2em;
		cursor: pointer;
		&:before {
			content: "";
			@include abs;
			width: 0;
			height: 0;
			border-radius: 50%;
			background: #4bb4ff;
			transition: 0.4s;
			z-index: -1;
		}
	}
}
