
#root {
	
	.not_found {
		display: flex;
		align-items: center;
		justify-content:center;
		position: relative;
		width: 100%;
		height: 100vh;
		font-size: 10px;
		padding: 5em;
		overflow: hidden;
		&+foter {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
		@media(min-width: 1400px){
			font-style: 1.2em;
		}
		@media(max-width: 992px){
			flex-direction: column;
			text-align: center;
		}
		@media(max-width: 768px){
			font-size: 8px;
		}
		@media(max-width: 550px){
			padding: 2em;
		}
		&.active {
			.rose_planet {
				.planet {
					&:before {
						animation:planet_lines 20s  linear infinite; 
					}
				}
			}
			.blue_planet {
				&:after {
					animation:blue_planet_line 10s  linear infinite;
				}
				.planet {
					&:before {
						animation:planet_lines 10s  linear infinite;
					}
				}
			}
			.subtitle {
				top: 0;
				opacity: 1;
				transition-delay: .9s, 1s;
				transition-property: top,opacity;
			}
			.line_choose {
				top: 0;
				opacity: 1;
				transition-delay: 1.2s, 1.3s;
				transition-property: top,opacity;
			}
			.left {
				h1 {
					span {
						opacity: 1;
						letter-spacing: 0;
						top: 0;
					}
				}
			}
		}
		.subtitle {
			color: $w;
			font-size: 1.9em;
			line-height: 1.65;
			display: block;
			width: 100%;
			max-width: 17.6em;
			position: relative;
			// top: 5em;
			// opacity: 0;
			transition:1s top, 2s opacity;
			@media(max-width: 992px){
				max-width: 100%;
			}
		}
		.line_choose {
			display: flex;
			align-items: center;
			margin-top: 3em;
			// top: 5em;
			// opacity: 0;
			position: relative;
			transition:1s top, 2s opacity;
			a {
				display: block;
				&:first-child {
					margin:0 20px 0  0 ;
					@media(max-width: 550px){
						margin:0 auto 20px auto;
					}
				}
			}
			.btn {
				padding: .6em 1.5em;
				font-size: 1.2em;
				box-sizing: border-box;
				text-transform: uppercase;
				font-family: $psemibold; 
				letter-spacing: 2px;
				@media(max-width: 550px){
					font-size: 1.6em;
					width: 100%;
				}
				span {
					font-family: inherit;
				}
				&.bright_blue {
					padding: .6em 1.5em;
					
					
				}
			}
			@media(max-width: 550px){
				display: block;
			}
		}
		.left {
			margin-right: 14em;
			position: relative;
			z-index: 2;
			h1 {
				display: block;
				color: #fff;
				line-height: 1.2; 
				font-size: 7.7em;
				font-family: $hregular;
				position: relative;
				&:last-child {
					margin-left: 1em;
					@media(max-width: 992px){
						margin-left: 0;
					}
				}
				span {
					top: 0;
					opacity: 1;
					transition-delay: .9s, 1s;
					transition-property: top,opacity;
				}
				@media(max-width: 1100px){
					font-size: 6.5em;
				}
				@media(max-width: 400px){
					font-size: 5.0em;
				}
			}
			@media(max-width: 992px){
				margin-right: 0;
			}
		}
		.right {
			width: 100%;
			max-width: 35em;
			position: relative;
			z-index: 2;
		}
		.rose_planet {
			position: absolute;
			left: 10em;
			bottom: 10%;
			width: 12em;
			min-width: 12em;
			height: 12em;
			border-radius: 50%;
			z-index: 0;
			transition:.5s;
			@include fb;
			transform: rotate(25deg);
			@media(max-width: 768px){
				display: none;
			}
			.planet {
				@include fb;
				width: 100%;
				height: 100%;
				border-radius:50%;
				position: relative;
				&:after {
					content: "";
					width: 100%;
					height: 100%;
					border-radius:50%;
					background: #a2227d;
					background:radial-gradient(ellipse at center,rgba(162,34,125,0) 0,rgba(162,34,125,0) 27%,rgba(162,34,125,.37) 48%,rgba(162,34,125,.64) 60%,#bf1e90 100%);
					@include abs;
				}
				&:before {
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					@include abs;
					background: url(../img/a2.png)0 -1em;
					background-repeat-y: no-repeat;
					background-size: 58em;
					border-radius: 50%;
					position: relative;
					z-index: 2;

				}
			}
		}
		.blue_planet {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -31em;
			width: 70em;
			height: 70em;
			border-radius: 50%;
			z-index: 1;
			@include fb;
			transition:top 1.2s;
			border: 1px solid rgba(255,255,255,.2);
			@media(max-width: 550px){
				font-size: 6px;
			}
			@media(max-width: 440px){
				font-size: 5px;
			}
			@media(max-width: 440px){
				font-size: 4px;
			}
			&:after {
				content: "";
				width: 53%;
				height: 53%;
				border: 1px solid rgba(255,255,255,.2);
				border-radius: 50%;
				display: block;
				@include abs;
			}

			.planet {
				@include fb;
				width: 53%;
				height: 53%;
				border-radius:50%;
				position: relative;
				&:after {
					content: "";
					width: 100%;
					height: 100%;
					border-radius:50%;
					background: rgba(54,124,190,0.22);
					background:radial-gradient(ellipse at center,rgba(54,124,190,.22) 0,rgba(54,124,190,.22) 32%,rgba(54,124,190,.45) 48%,rgba(54,124,190,.64) 60%,rgb(54, 124, 190) 100%);
					@include abs;
				}
				&:before {
					content: "";
					width: 100%;
					height: 100%;
					display: block;
					@include abs;
					background: url('../img/blue-planet.png');
					background-repeat-x:repeat;
					background-size: 86em;
					border-radius: 50%;

				}
			}
		}
	} 



	@keyframes blue_planet_line {
		0% {
			width: 53%;
			height: 53%;
			opacity: 1;
		}
		90% {
			width: 100%;
			height: 100%;
			opacity: 1;
		}
		95% {
			width: 100%;
			height: 100%;
			opacity: 0;
		}

		100% {
			width: 53%;
			height: 53%;
			opacity: 0;
		}
	}
	@keyframes planet_lines {
		0% {
			background-position: 0 0;
		}

		100% {
			background-position: -500px 0;
		} 
	}
	@keyframes planet_blue_lines {
		0% {
			background-position: 0 0;
		}

		100% {
			background-position: -500px 0;
		} 
	}
}