
.slick-arrow {
	display: none !important;
}
.mobileMaineSlider {
	display: none;
	@media(max-width: 992px){
		display: flex;
		align-items: flex-start;
	}
	.slide {

	}
}
.main_content {
	@include cont(96em);
	display: block;
	min-height: 20em;
	position: relative;
	z-index: 8;
	transition:filter .4s, transform .6s;
	transform-origin: left bottom;
	&.wrapper_privacy_policy {
		font-size: 10px;
	}
	@media(max-width: 1024px){
		&.first {
			position: static;
		}
	}
	@media(max-width: 992px){
		overflow-x: hidden;
		max-width: 83%;
	}
	@media(max-width: 550px){
		max-width: 100%;
	}
	.block_subtitle {
		@include title_light;
	}

	.cursors_navigate {
		display: flex;
		align-items: center;
		position: absolute;
		z-index: 15;

		&.prev{
			.cursor_prev {
				display: block;
			}
		}
		&.next{
			.cursor_next {
				display: block;
			}
		}
		.cursor {
			opacity: 1;
		}
	}
	.cursor {
		display: none;
		cursor: pointer;
		width: 6em;
		height: 6em;
		z-index: 10;
		
		.cls-2 {
			transition:.4s transform;
		}
		&:active {
			.cls-1, .cls-2 {
				fill:#912277;
			}
			.cls-1 {
				stroke:#912277;
			}
			.cls-2 {
				transform:translate(-1em,0);
			}
		}
		
		&_prev {
			.cls-1, .cls-2 {
				fill: none;
				stroke: #fff;
				stroke-width: 1px;
			}

			.cls-2 {
				fill-rule: evenodd;
			}
		}
		&_next {
			transform:rotate(180deg);
			.cls-1, .cls-2 {
				fill: none;
				stroke: #fff;
				stroke-width: 1px;
			}

			.cls-2 {
				fill-rule: evenodd;
			}
		}
	}


	.block_title {
		font-size: 6em;
		color: $w;
		font-family: $hregular;
		width: 100%;
		max-width: 11em;
		line-height: 1.3;
		position: relative;
		z-index: 3;
		&_subtitle {
			font-family: $hlight;
			font-size: 1.3em;
			color: $w;
		}
		@media(max-width: 768px){
			max-width: 100%;
			font-size: 5em;
		}
		@media(max-width: 500px){
			font-size: 4.55em;
			line-height: 1.55;
		}
	}
	.block_window {
		display: block;
		width: 100%;
		padding-top: 8em;

		&.feature{
			@media(max-width: 1024px){
				&.active {
					.line_benefits {
						&_icon {
							opacity: 1;
							&:after {
								width: 99%;
								height: 99%;
							}
							&:before {
								animation:core 2s  linear infinite;
								animation-delay: .2s;
							}
							.number {
								top: .4em;
								opacity: 1;
							}
						}
					}
				}
			}
			@media(max-width: 768px){
				margin-top: 4em;
				padding-bottom: 0;
			}
			.block_title {
				margin-bottom: .7em;
			}
		}

		&.video{
			padding: 5em 0;
			margin-bottom: 7em;
			.poster {
				position: absolute;
				z-index: 0;
				top: 0;
				left: 0;
				object-fit: cover;
				width: 100%;
				height: 100%;
				opacity: .6;
				&.none {
					display: none;
				}
			}
			@media(max-width: 768px){
				margin-bottom: 2em;
			}
		}
		&.pricing{
			padding: 15em 0 10em;

			@media(min-width: 2200px){ 
				padding: 30em 0 30em;
				height: 100vh;
			}
			@media(min-width: 1400px){
				padding: 23em 0;
			}
			@media(max-width: 768px){
				padding-top: 2em;
			}
		}
		&.scheduling {
			padding: 7em 0 0em;
			height: auto;
			z-index: 2;
			@media(max-width: 768px){
				margin-bottom: 0;
			}
			.block_title {
				max-width: 5em;
				margin-right: -.3em;
				@media (max-width: 992px) {
					max-width: 100%;
					display: block;
					text-align: left;
					font-size: 4.5em;
					margin-bottom: 15px;
					line-height: 1.5;
				}
			}
		}
		&.list_etaps {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
			position: relative;
			padding:2em 0;
			z-index: 3;
			@media(max-width: 992px){
				padding:2em 0 13em;
			}
			@media(max-width: 768px){
				position: relative;
				padding: 0;
				margin-bottom: 4em;
			}
			.etap {
				position: relative;
				width: 100%;
				max-width: 21em;
				min-height: 10em;
				display: block;
				color: #848db1;
				margin: 0 1em 3em;
				cursor: pointer;
				@media (max-width: 992px) {
					max-width: 100%;
					position: absolute;
					transition:.8s top, .8s opacity;
					top: 3em;
					opacity: 0;
					left: 0;
					top: 0;
					right: 0;
					margin: 0 0em 3em;
				}
				@media(max-width: 768px){
					position: relative;
					margin-bottom: 0;
				}
				&.active {
					color: rgba(255,255,255,.99);
					@media (max-width: 992px) {
						top: 0;
						opacity: 1;
						transition:.8s top, .8s opacity;
						position: relative;
					}
					.white_line {
						&:before {
							width: 100%;
							left: 0;
							right: auto;
							opacity: .6;
						}
						&:after {
							height: 1em;
							top:0;
							bottom: auto;
							opacity: .6;
							transition-delay: .5s;
							@media(max-width: 768px){
								transition-delay: .78s;
							}
						}
					}
					.etap_description {
						max-height: 25em;
						p {
							opacity: 1;
						}
					}
				}
				&:after {
					content: '';
					width: 1px;
					height: 1em;
					display: block;
					background: rgba(255,255,255,.2);
					position: absolute;
					top: .1em;
					right: 0;
				}
				
				.white_line {
					content: '';
					width: 100%;
					height: .1em;
					display: block;
					background:rgba(255,255,255, .2);
					position: absolute;
					top: 0;
					left: 0;
					@media(max-width: 768px){
						height: 1px;
					}

					&:before {
						content: '';
						width: 0;
						height: 100%;
						position: absolute;
						left: auto;
						right: 0;
						bottom: 0;
						margin: auto;
						display: block;
						background: $w;
						z-index: 2;
						transition: .5s;
						@media(max-width: 768px){
							transition:.8s;
						}
					}
					&:after {
						content: '';
						width: 1px;
						height: 0;
						display: block;
						background:rgba(255,255,255,.99);
						position: absolute;
						bottom: auto;
						top: 0;
						right: 0;
						transition:.5s;
						transition-delay: .3s;
						margin: auto;
					}
				}
				&_numbers {
					display: flex;
					align-items: center;
					position: relative;
					top: -2em;
					@media(max-width: 550px){
						top: -3em;
						span {
							font-size: 1.6em;
						}
					}
					span {
						font-size: 1.3em;
						font-family: $hlight;
						&.current_etap {
							&:after {
								content: '/';
							}
						}

					}
				}
				&_title {
					font-family: $hregular;
					font-size: 1.9em;
					display: block;
					margin-bottom: .8em;
					@media(max-width: 992px){
						font-size: 3.2em;
						margin: 0.4em 0 0.5em;
					}
					@media(max-width: 550px){
						font-size: 3.6em;
					}
				}
				.etap_description {
					transition:.9s max-height;
					overflow: hidden;
					max-height: 0;
					display: block;
				}
				p {
					font-size: 1.3em;
					transition:1s;
					opacity: 0;
					@media(max-width: 550px){
						font-size: 1.6em;
					}
				}
			}
		}
	}
}

.safari {
	
	#root {
		.main_content {
			.slick-active {
				.slide.one {
					.figure.tablet {
						&:before {
							z-index: 2;
							border-top-color: #65188e;
							border-right-color: transparent;
							border-bottom-color: transparent;
							transition: border-top-color .75s linear,border-right-color .75s linear .6s,border-bottom-color .75s linear .6s;
							transform: rotate(116deg);
						}
						&:after {
							z-index: 0;
						}
						img {
							z-index: 1;
						}
					}
				}
			}
		}
	}
}

#root {
	.pricing{
		position: relative;
		z-index: 2;
		.block_title {
			max-width: 100%;
		}
		&_upper_line {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			color:$w;
			.btn_toggle {
				margin-left: 4em;
			}
			@media(max-width: 768px){
				display: block;
				text-align: center;
				.btn_toggle {
					margin: 0 auto;
					display: flex;
					justify-content: center;
					box-sizing: content-box;
				}
			}
		}
		&_group  {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			width: 100%;
			max-width: 90em;
			@media(max-width: 1024px) {
				flex-wrap: wrap;
			}
			@media(max-width: 768px){
				display: block;
				text-align: center;
			}
			p {
				font-family: $hlight;
				font-size: 1.5em;
				width: 100%;
				text-align: right;
				@media(max-width: 768px){
					text-align: center;
					padding: 0 0 1em;
				}
			}

		}
		&_tariff_plans {
			display: flex;
			align-items: stretch;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
			@media (max-width: 992px) {
				display: block;
			}
			.tariff_plans  {
				&_col {
					&:nth-child(1) {
						order: 1;
						@media(max-width: 768px){
							order:0;
						}
					}
					&:nth-child(2) {
						order: 0;
						@media(max-width: 768px){
							order:1;
						}
					}
					padding:3em 2em;
					width: 50%;
					border:1px solid rgba(255,255,255,.25);
					color:$w;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					flex-wrap: wrap;
					.short_descript {
						width: 100%;
						display: block;
						padding-top: 1em;
						@media(max-width: 992px){
							padding-top: 3em;
						}
						span {
							font-size: 1.2em;
							font-family: $hlight;
							line-height: 1.2;
							color: #848db1;
						}
					}
					@media (max-width: 992px) {
						width: 100%;
						max-width: 70em;
						margin: 0 auto;
					}
					@media(max-width: 768px){
						display: block;
						max-width: 100%;
						text-align: center;
					}
					.btn {
						&.bright_blue {
							font-size: 1.2em;
						}
					}
					.title {
						font-size: 3em;
						font-family: $hregular;
						line-height: 1;
					}
					.price {
						display: flex;
						align-items: flex-end;
						padding-top: 2em;
						&.additional_info {
							.price_length {
								top: -1.5em;
							}
						}
						@media(max-width: 768px){
							justify-content: center;
							padding: 2em 0;
						}

						&_currency {
							margin-bottom: auto;
							font-family: $hlight;
							text-transform: uppercase;
							font-size: 1.5em;
						}
						&_number {
							font-size: 7em;
							font-family: $hregular;
							line-height: 1;
						}
						&_length {
							font-family: $hregular;
							position: relative;
							&_title {
								display: block;
								line-height: 2;
								font-size: 2em;
								&:before {
									content: '/';
								}
							}
							&_info {
								position: absolute;
								top: 70%;
								left: 0;
								font-family: $hlight;
								white-space: nowrap;
								font-size: 1.2em;
							}

						}
					}
					.available_rooms{
						margin-bottom: 2em;
						text-align: right;
						span {
							display: block;
							font-size: 1.5em;
							font-family: $hlight;
						}
						@media(max-width: 768px){
							text-align: center;
						}
					}
					.col {
						&:last-child {
							text-align: right;
							@media(max-width: 768px){
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
	&.safari {
		@media(min-width: 1024px){
			.scheduling {

				.slide {
					.block_title {
						left: 200px;
						opacity: 0;
						transition:  1s left, 2s opacity;
					}
				}
				

				.slick-active {
					.slide {
						.block_title {
							left: 0;
							opacity: 1;
							// transition-delay: 0s,.2s;
							// transition-property: top,opacity;
						}
					}
				}
			}
		}
	}
}

.scheduling {
	width: 100%;
	position: relative;
	.arrows {
		position:  absolute;
		display: block;
		align-items: center;
		width: 100%;
		margin-bottom: 5em;
		z-index: 100;
		background:red;
		width: 6em;
		height: 6em;
		pointer-events: none;
		@media(max-width: 992px){
			display: flex;
		}
		svg {
			fill:$w;
			width: 4em;
			display: block;
			cursor: pointer;
			&:active {
				fill:$blue;
			}
			&:last-child {
				margin-left: 2em;
			}
		}
	}
	.block_title {
		position: absolute;
		left: 0;
		font-size: 5.5em;
		@media (max-width: 992px) {
			position: relative;
			display: block;
			width: 100%;
			max-width: 100%	;
		}
		@media (max-width: 768px) {
			font-size: 4.9em;
		}
	}

	.wrapper_for_navigation {
		position: relative;
		display: block;
		@media(max-width: 992px){
			display: none;
		}
		
		.overlay_left, .overlay_right {
			width: 50%;
			position: absolute;
			z-index: 5;
			top: 0;
			height: 100%;
			display: none;
			@media(min-width: 768px){
				display: block;
			}
		}
		.overlay_left {
			cursor:url('../img/cursor_left.cur'), pointer;
			left: 0;
			&:active {
				cursor:url('../img/cursor_left_active.cur'), pointer;
			}
		}
		.overlay_right {
			cursor:url('../img/cursor_right.cur'), pointer;
			right: 0;
			&:active {
				cursor:url('../img/cursor_right_active.cur'), pointer;
			}
		}
	}
	.scheduling_animate_slider {
		position: relative;
		z-index: 2;
		width: 100%;
		height: 60em;
	}
	.bottomSliders-dots, .slick-arrow {
		display: none !important;
		

	}




	.slick-active {
		.slide {
			.block_title {
				@include letter_active;
			}
			&.one {
				.figure {
					&.phone {
						@include circle;
						img {
							top: 0;
							left: 0;
							opacity: 1;
							transition-delay: .3s, .3s, .3s;
							transition-property: left, top,opacity;
						}
					}
					&.tablet {
						&:before {
							border-top-color: #65188e;
							border-right-color: #65188e;
							border-bottom-color: #65188e;
							transition: border-top-color 0.75s linear, border-right-color 0.75s linear 0.6s, border-bottom-color 0.75s linear 0.6s;
							transform: rotate(130deg);
							transition-delay: .9s;
						}
						&:after {
							border-top: 1px solid #47aaf3;
							border-left-width: 1px;
							border-right-width: 1px;
							-webkit-transform: rotate(270deg);
							transform: rotate(27deg);
							transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
							transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
							transition: transform 0.74s linear 0s, border-left-width 0s linear 0.95s, -webkit-transform 0.7s linear 0s;
						}
						img {
							left: 0;
							top: 0;
							opacity: 1;
							transition-delay: .3s,.3s,.3s;
							transition-property: left,top,opacity;
						}
					}
				}
			}
			&.two {
				@media(max-width: 768px){
					margin-bottom: 14em;
				}
				.right {

					.phone {
						bottom: -39%;
						right: 10%;
						transition-delay: .4s,.4s,.4s;
						transition-property: right, bottom, opacity;
						opacity: 1;
						
						@media (max-width: 992px) {
							bottom: 0;
							max-width: 38em;
							right: 25%;
						}
						@media(max-width: 768px){
							bottom: -9em;
							max-width: 33em;
							right: 27%;
						}
						@media(max-width: 550px){
							max-width: 38em;
							right: 19%;
						}
					}
					.room {
						&_number {
							transition-delay: .4s;
							top: 5em;
							opacity: 1;
						}
						.indicator {
							opacity: 1;
							transition-delay:1s;
							img {
								&+img {
									transition-delay: 2.9s;
									opacity: 1;
								}
							}
						}
						.door {

							.line {
								&_top_side {
									&:before {
										transition-delay: .5s;
										width: 100%;
									}
								}
								&_side {
									&:before, &:after {
										height: 100%;
										transition-delay: .8s;
									}
								}
								&_bottom {
									&:after {
										transition-delay: 1.15s;
										width: 100%;
									}
									&:before {
										transition-delay: 1.5s;
										height: 100%;
									}
								}
							}
							.door_handle {
								opacity: 1;
								transition-delay: .4s;
							}
						}
					}
				}
			}
			&.thre {
				@media(max-width: 768px){

				}
				.line {
					width: 100%;
					transition-delay: .5s;
					transition-property: width;
				}
				.phone {
					right: -40%;
					opacity: 1;
					transition-delay: .7s,.7s;
					transition-property: right,opacity;
					@media (max-width: 992px) {
						right: -32%;
					}
					@media (max-width: 550px) {
						right: 0em;
					}
					@media (max-width: 450px) {
						max-width: 34em;
						right: -5em;
					}
					@media(max-width: 350px){
						max-width: 30em;
					}
				}

				.people {
					opacity:1;
					transition-delay: .5s;
					transition-property: opacity;
				}
			}
			&.four {
				padding-top: 8em;
				.circle {
					transition:.4s opacity;
					transition-delay: .9s;
					transition-property: opacity;
					opacity: 1;
					&:before {
						border-top-color: #0eb7da;
						border-right-color: #0eb7da;
						border-bottom-color: #0eb7da;
						transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.1s, border-bottom-color 0.15s linear 0.2s;
						transition-delay: .7s ;
						
					}
					&:after  {
						border-top: 2px solid #0eb7da;
						border-left-width: 2px;
						border-right-width: 2px;
						-webkit-transform: rotate(270deg);
						transform: rotate(270deg);
						transition: border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
						transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s;
						transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
						transition-delay: .7s;
					}
				}
				.tablet {
					opacity: 1;
				}
				.phone {
					left: 19%;
					opacity: 1;
					transition-delay: .5s,.5s;
					transition-property: left, opacity;
					@media (max-width: 550px) {
						left: 0;
					}
				}
			}
		}
	}


	.slide {
		padding: 4em 0 13em;
		position: relative;
		overflow: hidden;
		@media(max-width: 768px){
			padding:0;
			margin-bottom: 4em;
			overflow: visible;
		}
		.block_title {
			@include letter;
			span {
				top: -25px;
			}
		}
		&.one {
			@media(max-width: 992px){
				padding: 4em 0 3em;
			}
			@media(max-width: 768px){
				padding-bottom: 0;
			}
			.right { 
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				@media (max-width: 992px) {
					justify-content: center;
					padding-bottom: 6em;
				}
				@media (max-width: 810px) {
					font-size: 7px;
				}
				@media (max-width: 600px) {
					font-size: 5px;
				}
				@media (max-width: 390px) {
					font-size: 4px;
				}
			}
			.figure {
				position: relative;
				img {
					width: 100%;

				}
				&.phone {
					width: 34em;
					min-width: 34em;
					top: 6em;
					margin-right: -25em;
					position: relative;					

					img {
						transition:.7s left, .7s top, .7s opacity;
						position: relative;
						z-index: 4;
						top: 5em;
						left: 3em;
						opacity: 0;
					}

					.circle {
						width: 12em;
						height: 12em;
						display: block;
						position: absolute;
						z-index: 0;
						right: 9em;
						top: 12em;
						border-radius: 100%;
						box-shadow: none;
						&:before, &:after {
							box-sizing: inherit;
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							border-radius: 100%;
							top: 0;
							left: 0;
						}
						&:before {
							border: 1px solid transparent;
						}
						
						
					}
				}
				&.tablet {
					width: 65em;
					min-width: 65em;
					padding: 0;
					

					&:before, &:after {
						box-sizing: inherit;
						content: '';
						position: absolute;
						width: 33em;
						height: 33em;
						border-radius: 100%;
						top: 0;
						left: 0;
						z-index: -1;
						right: 6em;
						top: 4em;
						left: auto;
						

					}
					&:before {
						border: 1px solid transparent;
						transform: rotate(0deg);
					}
					&:after {
						z-index: 100;
						transform: rotate(-27deg)
					}
					img {
						z-index: 1;
						left: 7em;
						top:-10em;
						position: relative;
						opacity: 0;
						transition: .7s left, top .7s, .7s opacity;
					}
					.circle {
						width: 33em;
						height: 33em;
						display: block;
						position: absolute;
						z-index: 0;
						right: 6em;
						top: 2em;
						border-radius: 100%;
						box-shadow: none;
						z-index: 1;
						@media(max-width: 490px){
							width: 23em;
							height: 23em;
						}
					}
				}
			}
		}
		&.two {
			@media (max-width: 400px) {
				padding: 9em 0 4em;
			}
			.right {
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				padding: 3em 0 0;
				@media (max-width: 992px) {
					justify-content: flex-end;
				}
				@media (max-width: 650px) {
					font-size: 6.7px;
				}
				@media(max-width: 550px){
					padding-top: 5em;
				}
				@media (max-width: 450px) {
					font-size: 5px;
				}
				@media (max-width: 350px) {
					font-size: 4.5px;
				}

				.phone {
					position: absolute;
					transition:right .4s, bottom .4s, opacity .7s, transform 0s;
					right: 10%;
					bottom: 0;
					width: 100%;
					max-width: 39em;
					bottom: -5em;
					right: -5em;
					opacity: 0;
					@media (max-width: 450px) {
						max-width: 30em;
					}

				}

				.room {
					display: flex;
					margin-right: 5em;
					align-items: flex-start;
					.indicator {
						width: 6.0em;
						display: block;
						margin: 4em 0 0 2em;
						position: relative;
						transition:.8s;
						opacity: 0;
						img {
							width: 100%;
							& + img {
								display: block;
								position: absolute;
								left: 0;
								top: 0;
								z-index: 2;
								opacity: 0;
							}
						}
					}
					&_number {
						position: absolute;
						width: 4em;
						display: block;
						left: 0;
						right: 0;
						margin: auto;
						top: 10em;
						color: $blue;
						transition:.6s;
						opacity: 0;
						.number {
							font-family: $hregular;
							line-height: 1;
							font-size: 3em;
							vertical-align: top;
							transition:.5s;
							position: relative;
							&:before {
								content: "#";
								vertical-align: top;
								font-size: .7em;
								transition:.3s;
							}
						}
						.subtitle {
							font-family: $hbold;
							text-transform: uppercase;
							font-size: 1.2em;
							transition:.4s;
						}
					}
				}

				.door {
					width: 17em;
					height: 34em;
					display: block;
					position: relative;
					.line_bottom {
						display: block;
						position: absolute;
						width: 100%;
						height: 1.3em;
						left: 0;
						bottom: 0;
						&:after {
							width: 0;
							content: '';
							transition: .4s;
							height: 100%;
							border-top: 2px solid #b61ae5;
							border-bottom: 2px solid #b61ae5;
							display: block;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							position: absolute;
							box-sizing: border-box;
						}
						&:before {
							height: 0;
							content: '';
							transition: .4s;
							width: 100%;
							border-left: 2px solid #b61ae5;
							border-right: 2px solid #b61ae5;
							display: block;
							box-sizing: border-box;
							left: 0;
							right: 0;
							top: 0;
							margin: auto;
							position: absolute;
						}
					}
					.line_top_side, .line_side {
						position: absolute;
						height: calc(100% - 1.3em);
						width: 92%;
						margin: auto;
						left: 0;
						right: 0;
						display: block;
						top: 0;
						box-sizing: border-box;
					}
					.line_top_side {
						&:before {
							width: 0;
							content: '';
							box-sizing: border-box;
							height: 1px;
							background:#4bb4ff;
							display: block;
							transition: .4s;
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							margin: auto;
						}
					}
					.line_side {
						&:before, &:after {
							height:0;
							content: '';
							width: 1px;
							box-sizing: border-box;
							display: block;
							transition: .5s;
							position: absolute;
							left: 0;
							top: 0;
							background: -moz-linear-gradient(269deg, #4bb4ff 0%, #cd1dff 72%, #cd1dff 100%); 
							background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4bb4ff), color-stop(72%, #cd1dff), color-stop(100%, #cd1dff)); 
							background: -webkit-linear-gradient(269deg, #4bb4ff 0%, #cd1dff 72%, #cd1dff 100%); 
							background: -o-linear-gradient(269deg, #4bb4ff 0%, #cd1dff 72%, #cd1dff 100%); 
							background: -ms-linear-gradient(269deg, #4bb4ff 0%, #cd1dff 72%, #cd1dff 100%); 
							background: linear-gradient(181deg, #4bb4ff 0%, #cd1dff 72%, #cd1dff 100%); 
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4bb4ff', endColorstr='#cd1dff',GradientType=0 );
						}
						&:after {
							left: auto;
							right: 0;
						}
					}
					.door_handle {
						position: absolute;
						width: 2em;
						height: 2em;
						display: block;
						left: 2em;
						top: 50%;
						transition:.8s;
						transform: translate(0,-50%);
						opacity: 0;
					}
				}
			}
		}
		&.thre {
			padding: 0 0 0 8em;
			@media (max-width: 992px) {
				padding: 0 0 3em 0;
			}
			@media(max-width: 768px){
				padding: 8em 0 3em 0;
			}
			.right {
				max-width:50em;
				margin: 0 auto 0 auto;
				padding: 9em 3em 1em 3em;
				position: relative;
				z-index: 0;
				@media (max-width: 992px) {
					font-size: 7px;
					padding: 9em 0em 1em 0em;
				}
				@media (max-width: 550px) {
					font-size: 6px;
					max-width: 100%;
				}
			}
			.slideThrePhone {
				position: absolute;
				bottom: 0;
				right: 0;
			}
			.phone {
				position: absolute;
				right: -60%;
				transition: .5s right, .5s opacity;
				width: 100%;
				max-width: 41em;
				z-index: 1;
				top: 2em;
				opacity: 0;
				margin: auto;
				
			}

			.people {
				position: relative;
				z-index: 2;
				width: 100%;
				max-width: 32em;
				display: block;
				margin: 0 auto 3em;
				opacity: 0;
				transition:1.5s opacity;
				@media(max-width: 400px){
					max-width: 25em;
				}

			}
			.down {
				width: 90%;
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				z-index: 0;
				bottom: 0;
				margin: auto;
				.st0{
					fill:#292863;
				}
				.st1{
					fill:#E613DF;
				}
				.st2{
					fill:#13A8E9;
				}	
				@media (max-width: 450px) {
					width: 75%;
				}
			}
			.line {
				position: absolute;
				width: 0;
				height: 90%;
				transition: .9s width;
				top: 10%;
				right: 0;
				display: block;
				overflow: hidden;
				z-index: 1;
				img {
					width: 50em;
					float: right;
					height: 100%;
				}
				@media(max-width: 758px){
					left: 0;
					width: 85%;
					img {
						width: 100%;
					}
				}
			}
		}
		&.four {
			@media(max-width: 992px){
				padding: 0em 0 3em;
			}
			.right {
				width: 100%;
				max-width: 100%;
				display: block;
				position: relative;
				margin: auto;
				@media (max-width: 992px) {
					font-size: 7px;
				}
				@media (max-width: 550px) {
					padding: 6em 6em 2em;
				}
			}
			.phone {
				position: absolute;
				left: 15em;
				bottom: -3em;
				width: 100%;
				max-width: 35em;
				transition:.6s left, .6s opacity;
				z-index: 2;
				opacity: 0;
				@media (max-width: 550px) {
					max-width: 40%;
				}
			}
			.tablet {
				display: block;
				width: 100%;
				max-width: 56em;
				margin: 0 0 0 auto;
				z-index: 1;
				opacity: 0;
				transition:.7s opacity ;
				@media (max-width: 992px) {
					margin: 0 auto 0 auto;
				}
				@media (max-width: 550px) {
					max-width: 50em;
				}
			}


			.circle {
				width: 40em;
				min-width: 40em;
				height: 40em;
				display: block;
				position: absolute;
				z-index: 0;
				left: 21em;
				top: 10em;
				border-radius: 100%;
				box-shadow: none;
				z-index: -1;
				opacity: 0;				
				&:before, &:after{
					box-sizing: inherit;
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 100%;
					top: 0;
					left: 0;
				}
				&:before {
					border: 1px solid transparent;
				}
				@media (max-width: 550px) {
					bottom: 0;
					top: auto;
					left: 0;
					font-size: 4.5px;
				}
			}
		}
	}

}
.line_benefits {
	width: 100%;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	@media(max-width: 992px){
		margin-bottom: 7em;
	}
	@media(min-width: 1025px){
		&:hover {
			.line_benefits {
				&_icon {
					transform: scale(1);
					opacity: 1;
					&:after {
						width: 99%;
						height: 99%;
					}
					&:before {
						animation:core 2s  linear infinite;
						animation-delay: .2s;
					}
					.number {
						top: .4em;
						opacity: 1;
					}
					.core {
						animation:shadow 2s linear infinite;
					}
				}
				&_title {
					h2 {
						transform: scale(1.1);
					}
				}
				&_description {
					.white_line {
						&:before {
							width: 100%;
							left: 0;
							right: auto;
						}
						&:after {
							transition-delay: .4s;
							height: 1em;
							top:0;
							bottom: auto;
						}
					}
				}
			}
		}
	}
	@media(max-width: 768px){
		display: block;
	}

	&_icon {
		width: 17em;
		min-width: 17em;
		height: 17em;
		@include fb;
		margin-right: 7em;
		border-radius: 50%;
		position: relative;
		transition:.7s;
		transform: scale(.7);
		opacity: 0;
		transform-origin: bottom center;
		pointer-events: none;
		&:before {
			content: "";
			border:.1em solid rgba(255,255,255,.2);
			border-radius: 50%;
			display: block;
			@include abs;
			width: 50%;
			height: 50%;
		}
		&:after {
			width: 0;
			height: 0;
			content: "";
			border:.1em solid rgba(255,255,255,.2);
			border-radius: 50%;
			display: block;
			@include abs;
			border:.1em solid rgba(255, 255, 255, 0.2);
			transition:.7s;
		}
		.number {
			font-family: $hregular;
			color: $w;
			font-size: 3em;
			position: absolute;
			left: -.7em;
			top: -1.3em;
			opacity: 0;
			transition: .6s;
			transition-delay: 0s;
		}
		.core {
			@include abs;
			width: 60%;
			height: 60%;
			border-radius: 50%;
			background: #a2227d;
			background:radial-gradient(ellipse at center,rgba(162, 34, 125, 0) 0,rgba(162, 34, 125, 0) 27%,rgba(162, 34, 125, 0.37) 48%,rgba(162,34,125,.87) 60%,#a2227d 100%);
			@include fb;
			svg {
				width: 50%;
				min-width: 50%;
				height: 50%;
			}
		}
		&_1 {
			.cls-1 {
				fill: none;
				stroke: #4bb4ff;
				stroke-width: 1px;
			}

			.cls-1, .cls-2 {
				fill-rule: evenodd;
			}

			.cls-2 {
				fill: url(#linear-gradient);
			}
		}
		&_2 {
			.st0{fill:none;stroke:#4BB4FF;}
			.st1{fill:url(#Rectangle_834_copy_7_1_);}
		}
		&_3 {
			.st0{fill:none;stroke:#4BB4FF;}
			.st1{fill:url(#Ellipse_7_copy_5_1_);}
		}
		&_4 {
			.st0{fill:url(#Ellipse_7_copy_5_1_);}
			.st1{fill:url(#Ellipse_7_copy_6_1_);}
			.st2{fill:url(#SVGID_1_);stroke:#4BB4FF;}
			.st3{fill:url(#SVGID_2_);stroke:#4BB4FF;}
			.st4{fill:url(#SVGID_3_);stroke:#4BB4FF;}
			.st5{fill:url(#SVGID_4_);stroke:#4BB4FF;}
			.st6{fill:url(#SVGID_5_);stroke:#4BB4FF;}
		}
		@media(max-width: 992px){
			transform: scale(1);
			opacity: 1;

		}
		@media(max-width: 768px){
			margin: 0 auto ;
		}
	}

	&_title {
		width:32em;
		min-width:32em;
		display: block;
		min-height: 11em;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		margin-right: 4em;
		@media(max-width: 992px){
			width: 22em;
			min-width: 25em;
		}
		@media(max-width: 768px){
			margin: 0 auto 0em;
			width: 100%;
			min-width: 0;
			max-width: 40em;
		}
		h3 {
			color: $w;
			font-size: 3.0em;
			font-family: $hregular;
			line-height: 1.3;
			transition: .5s;
			transform-origin: left center;
		}
		@media(max-width: 992px){
			h3 {
				transform: scale(1.1);
				font-size: 2.6em;
			}
		}
		@media(max-width: 550px){
			h3 {
				transform: scale(1.1);
				font-size: 2.9em;
			}
		}
	}

	&_description {
		width: 100%;
		max-width: 40em;
		padding: 1em 1em 0 0;
		position: relative;
		@media(max-width: 768px){
			margin: 0 auto;
		}
		&:after {
			content: '';
			width: .19em;
			height: 1em;
			display: block;
			background:rgba(255,255,255,.2);
			position: absolute;
			top: .1em;
			right: 0;
		}

		p {
			font-size: 1.5em;
			font-family: $hlight;
			line-height: 1.5;
			color:$w;
			@media(max-width: 768px){
				margin-top: 20px;
			}
		}

		.white_line {
			content: '';
			width: 100%;
			height: .15em;
			display: block;
			background:rgba(255,255,255, .2);
			position: absolute;
			top: 0;
			left: 0;

			&:before {
				content: '';
				width: 0;
				height: 100%;
				position: absolute;
				left: auto;
				right: 0;
				bottom: 0;
				margin: auto;
				display: block;
				background: $w;
				z-index: 2;
				transition: .5s;
			}
			&:after {
				content: '';
				width: .19em;
				height: 0;
				display: block;
				background:rgba(255,255,255,.99);
				position: absolute;
				bottom: auto;
				top: 0;
				right: 0;
				transition:.5s;
				transition-delay: .4s;
				margin: auto;
			}
			@media(max-width: 992px){
				&:before {
					width: 100%;
					left: 0;
					right: auto;
				}
				&:after {
					transition-delay: .4s;
					height: 1em;
					top:0;
					bottom: auto;
				}
			}
		}
	}
}

.wrap_video {
	width: 100%;
	max-width: 55em;
	margin: -4.5em auto 0;
	z-index: 1;

	.video {
		width: 100%;
		height: 35em;
		@include fb;
		position: relative;
		&_info {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			align-items: center;
			justify-content: space-between;
			color:$w;
			text-transform: uppercase;
			padding: 1em;
			span {
				font-family: $hlight;
				font-size: 1.3em;
			}
		}
	}
	button {
		display: none;
	}
	video {
		opacity: .4;
		width: 100%;
		min-width: 100%;
		height: 100%;
		min-height: 100%;
		object-fit: cover;
		z-index: 2;
	}
	&.play {
		.playBtn {
			opacity: 0;
			transition:scale(2);
		}
		video {
			transition:.6s;
			opacity: 1;
		}
		&:hover {
			.playBtn {
				opacity: 1;
				transition:scale(1);
				transition:.7s opacity, transform .7s;
			}
			video {
				opacity: .4;
			}
		}
	}
	.playBtn {
		width: 10em;
		min-width: 10em;
		height: 10em;
		border:1px solid $w;
		border-radius: 50%;
		z-index: 3;
		@include fb;
		padding: 2em;
		transition:.2s opacity, transform .2s;
		cursor: pointer;
		transition-delay: 0s;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		box-sizing: border-box;
		overflow: hidden;


		&:active {
			box-shadow:0 0 9px -2px #4bb4ff, 0 0 10px -3px #000 inset;
		}
		&:hover {
			border-color: #4bb4ff;
			box-shadow: 0 0 9px -2px #4bb4ff;
			transition-delay: .3s;
			&:after {
				width: 150%;
				height: 150%;
			}
		}
		&:after {
			content: '';
			width: 0;
			height: 0;
			@include abs;
			background:#4bb4ff;
			z-index: 1;
			border-radius: 50%;
			transition:.3s;
		}
		

		span {
			font-size: 1.5em;
			color: $w;
			text-transform: capitalize;
			font-family: $hregular;
			position: relative;
			z-index: 2;
		}

		svg {
			position: relative;
			z-index: 2;
			fill:$w;
			width: 1.7em;
			min-width: 1.7em;
			height: 1.7em;
		}

	}
}

@keyframes core {
	0% {
		width: 40%;
		height: 40%;
		opacity: 1;
	}
	80% {
		width: 90%;
		height: 90%;
		opacity: 1;
	}
	90% {
		width: 90%;
		height: 90%;
		opacity: 0;
	}
	95% {
		width: 99%;
		height: 99%;
		opacity: 0;
	}
	
	100% {
		width: 40%;
		height: 40%;
		opacity: 0;
	}
}
@keyframes shadow {
	0% {
		box-shadow: 0 0 0 0 #a2227d;
	}

	50% {
		box-shadow: 0 0 12px 0 #a2227d;
	}
	
	100% {
		box-shadow: 0 0 0 0 #a2227d;
	}
}

