.rangeSlider {
    position: relative;
    z-index: 5;
    width: 101.5%;
    @media(max-width: 768px){
        margin: 6em 0 7em;
    }
    &_title {
        position: absolute;
        display: block;
        text-align: center;
        color: $border;
        font-size: 1.5em;
        font-family: $hlight;
        width: 100%;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%);
        @media(max-width: 768px){
            font-size: 1.3em;
            top: -15px;
        }
    }
    .rangeslider__handle {
        width: 3em;
        height: 3em;
        // transform: translate3d(-50%, -50%, 0) perspective(900px);
        transform: translate(-50%, -50%) perspective(900px);
    }
    .rangeslider__handle-label {
        display: none;
    }


    .rangeslider__labels {
        color: $border;
        left: 0;
        position: relative;
        top: 0;
        &:before {
            content: '';
            width: calc(100% - 1.3em);
            height: 1px;
            position: absolute;
            right: 1.3em;
            top: 0;
            z-index: 2;
            background:$border;
            box-sizing: border-box;
            @media(max-width: 768px){
                content: '';
                width:calc(100% - 1.38em);
                height: 1px;
                position: absolute;
                right: 1.38em;
            }
        }
        &:after {
            content: '';
            width: calc(100% - 1.3em);
            height: 3em;
            position: absolute;
            right: 1.3em;
            top: -3em;
            border-right: 1px solid $border;
            box-sizing: border-box;
            z-index: 0;
            @media(max-width: 768px){
                height: 4em;
                position: absolute;
                right: 1.37em;
                top: -3.9em;
            }
        }
        .rangeslider__label-item {
            width: 2.7em;
            font-size: 1.1em;
            padding-top: .8em;
            line-height: 1;
            top: 0px;
            text-align: right;
            padding-right: 1em;
            margin-left: -1.79em;
            box-sizing: content-box;
            @media(max-width: 768px){
                width: 0;
                font-size:1.13em;
                padding-top: 3.8em;
                margin-left: -0.2em;
                line-height: 1;
                top: 1px;
                text-align: right;
                padding-right: 6px;
                box-sizing: content-box;
            }
            &:first-child {
                margin-left: -1.7em;
                @media(max-width: 768px){
                    margin-left: -.2em;
                }
            }
            &:before {
                content:'';
                right: 0;
                top: 0;
                margin: auto;
                display: block;
                background: $border;
                position: absolute;
                height: 100%;
                width: 1px;
                @media(max-width: 768px){
                    height: 3em;
                }
            }
            &:last-child {
                margin-left: 0;
                padding: .8em 1em 0em 0;
                box-sizing: border-box;
                &:before {
                    left: 0;
                    right: auto;
                }
                @media(max-width:768px){
                    padding: 3.7em 1em 0 0;
                    width: 2.6em;
                }
            }


        }
    }

    .rangeslider {
        background: transparent;
        // border-bottom: 0.1em solid $border;
        // border-right: 0.1em solid $border;
        // border-left: 0.1em solid $border;
        border:0;
        box-shadow: none;
        margin-bottom: 5em;
        .rangeslider__fill {
            z-index: 1;
            position: relative;
            border-radius: 0;
            background: -moz-linear-gradient( 0deg, rgb(75, 180, 255) 0%, rgb(238, 30, 143) 100% );
            background: -webkit-linear-gradient(  0deg,  rgb(75, 180, 255) 0%, rgb(238, 30, 143) 100% );
            background: -ms-linear-gradient( 0deg,  rgb(75, 180, 255) 0%, rgb(238, 30, 143) 100% );
            z-index: 5;
            background:-webkit-linear-gradient( 0deg,  rgb(75, 180, 255) 0%, rgb(238, 30, 143) 100%  );
        }
        &-horizontal {
            height: 4em;
            border-radius: 0;
            box-sizing: content-box;

            
            .rangeslider__handle {
                z-index: 6;
                width: 3em;
                height: 3em;
                border-radius: 100%;
                border: 0;
                box-shadow:none;
                transform: translate(-1.5em,-1.5em) perspective(900px);
                &:after {
                    background: url("../img/opposite.png") no-repeat center;
                    background-size: contain;
                    background-color: transparent;
                    box-shadow: none;
                    width: 50%;
                    height: 50%;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:before {
                    content: "";
                    width: 1px;
                    height: 6em;
                    display: block;
                    position: absolute;
                    top: -0.55em;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background: $w;
                    @media(max-width: 768px){
                        height: 7.8em;
                    }

                }
            }
        }
    }
}
