#root {
	footer {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2.5em 4em;
		position: relative;
		left: 0;
		right: 0;
		z-index: 10;
		font-family: $hlight;
		@media(max-width: 768px){
			padding: 0 4em 4.0em;
		}
		&.focus {
			.wrap_socials, .copyright:not(.center) {
				bottom: 2em;
				opacity: 1;
				transition-delay: .9s,.9s;
				transition-property: bottom, opacity ;
			}

		}
		@media(min-width: 1921px){
			width: 100%;
			max-width: 1920px;
			margin: 0 auto;
		}
		@media(min-width: 992px){
			.wrap_socials, .copyright:not(.center){
				bottom: -10em;
				opacity: 0;
				transition:bottom .9s, opacity .9s;
			}
		}
		@media (max-width: 992px) {
			z-index: 8;
			position: relative;

		}

		&.last {
			.copyright {
				&.center {
					top: 6px;
					p {
						line-height: 1.1;
					}
				}
			}
		}

		&:not(.media_none) {
			display: block;
			text-align: center;
		}
		&.contact_page {
			display: none;
		}

		.wrap_socials {
			position: fixed;
			transition:.5s left;
			left: 4em;
			bottom:-10em;
			display: block;
			z-index: 2;
			@media(min-width: 1931px){
				left: calc((100% - 1920px) / 2);
			}
			@media(max-width: 992px){
				position: fixed;
				left: 0;
				bottom: 3em;
				.soc_btn {
					padding:  2em 2em;
				}
			}

			&.active {
				overflow: visible;
				transition:.4s, left 0s;
				.socials {
					a {
						margin: 3.0em auto ;
						opacity: 1;
						left: 0;
					}
				}
				@media(max-width: 768px){
					.soc_btn {
						span {
							display: none;
						}
					}
				}
			}
			.soc_btn {
				display: none;
				@media(max-width: 992px){
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					align-items: center;
					cursor: pointer;
				}

				span {
					color: $w;
					font-family: $hregular;
					font-size: 1.3em;
					@media(max-width: 768px){
						position: relative;
						transform: rotate(-90deg);
						transform-origin: -60% 50%;
					}
				}
				svg {
					display: block;
					margin-right: 1em;
				}
			}
			.socials{
				@media(max-width: 992px){
					display: block;
					position: absolute;
					bottom: 4em;
					left: 1.5em;

					a {
						margin: -2em auto ;
						margin-right: 0;
						display: block;
						text-align: center;
						transition:.4s, left 0s;
						opacity: 0;
						position: relative;
						left: -10em;
						&.facebook {
							svg {
								display: block;
								margin: 0 auto;
							}
						}
					}
				}
				a {
					display: block;
					&:not(.envelope) {
						margin-right: 1.4em;
						svg {
							stroke:rgb(255, 255, 255);
							fill:transparent;
						}
					}
					&.envelope {
						svg {
							&:last-child {
								display: none;
							}
						}
					}
					&:hover {
						&.facebook {
							svg {
								stroke:#3b5998;
								fill:#3b5998;
							}
						}
						&.twitter {
							svg {
								stroke:#00aced;
								fill:#00aced;
							}
						}
						&.linkedin {
							svg {
								stroke:#0077b5;
								fill:#0077b5;
							}
						}
						&.envelope {
							svg {
								&:first-child {
									display: none;
								}
								&:last-child {
									display: block;
								}
							}
						}
					}
				}

				svg {
					display: block;
				}
			}
		}

		.copyright {
			position: relative;
			transition: .4s;

			&:not(.center) {
				position: fixed;
				right: 4em;
				bottom: -10em;
				@media(max-width: 992px){
					bottom: 3em;
				}
				@media(min-width: 1931px){
					right: calc((100% - 1920px) / 2);
				}
			}
			&.center {
				transition:1s;
				position: relative;
				margin: auto;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				@media(max-width: 768px){
					display: block;
				}
				p,.privacyPolicy {
					@media(max-width: 768px){
						font-size: 1.5em;
					}
				}
				.privacyPolicy {
					&:not(.terms) {
						margin-left: 1em;
						padding-left: 1em;
						border-left: 1px solid rgba(255,255,255,.2);
					}
				}
				p {
					margin-left: 15px;
					padding-left: 15px;
					border-left: 1px solid $border;
					@media(max-width: 768px){
						border:0;
						margin-left: auto;
						padding-left: 0;
						line-height: 2;
					}
				}
			}
			&:not(.center) {
				@media(max-width: 992px){
					transform:rotate(90deg);
					white-space: nowrap;
					transform-origin: bottom right;

				}
				@media(max-width: 768px){
					display: none;
				}
			}

			p,a, .privacyPolicy {
				font-family: $hlight;
				color: $w;
				font-size: 1.3em;
			}
			a {
				&:hover {
					color: #4bb4ff;
				}
			}
		}
	}
}