#root {
	.modal {
		&_box {
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			@include fb;
			z-index: -100;
			transition-delay: .9s;
			@media(max-width: 549px){
				font-size: 8px;
				padding: .8em;
			}

			.overlay {
				width: 100%;
				height: 100%;
				@include abs;
				background: rgba(0, 0, 14, 0.6);
				cursor: pointer;
				z-index: 0;
				transition:.4s;
				opacity: 0;
				transition-delay: .7s;
			}
			&.active {
				transition-delay: 0s;
				z-index: 40;
				.overlay {
					transition-delay: .1s;
					opacity: 1;
				}
				

				.modal {
					&_close {
						span {
							width: 100%;
							transition-delay: .3s;
							&:first-child {
								transform:rotate(45deg);
							}
							&:last-child {
								transform:rotate(-45deg);
							}
						}
					}
					&_window {
						top: 0;
						transition-delay: .2s;
						
						.line {
							&_bottom {
								transition-delay:2s;
								box-shadow: 0px -2px 8px -2px rgba(0, 0, 0, 0.37);
							}
						}
					}
				}
			}

			&.email , &.pass{
				.modal {
					&_window {
						
						width: 100%;
						opacity: 1;
						max-width: 50em	;
						@media(max-width: 550px){
							max-width: 60em;
						} 
						.line {
							&_email {
								.title {
									top: 0;
									opacity: 1;
									margin-bottom: 6px;
									transition-delay: .5s, .5s;
									transition-property: top,opacity;
								}
								.subtitle {
									top: 0;
									opacity: 1;
									transition-delay: .6s, .5s;
									transition-property: top,opacity;
								}
								.btn {
									top: -5px;
									opacity: 1;
									transition-delay: .9s ;
									transition-property:  opacity;
									@media(max-width: 768px){
										top: 120%;
									}
								}	
								form {
									
									input {
										width: 100%;
										padding: .7em 7em .5em 0;
										transition-delay: .7s,.7s;
										transition-property: width,padding;
										border-radius:0;
									}
									label {
										@include letter_active;
									}
								}
							}
							&_pass {
								.title {
									top: 0;
									opacity: 1;
									margin-bottom: 6px;
									transition-delay: .5s, .5s;
									transition-property: top,opacity;
								}
								.subtitle {
									top: 0;
									opacity: 1;
									transition-delay: .6s, .5s;
									transition-property: top,opacity;
								}
								.btn {
									top: -5px;
									opacity: 1;
									transition-delay: .9s ;
									transition-property:  opacity;
									@media(max-width: 768px){
										top: 120%;
									}
								}	
								form {
									
									input {
										width: 100%;
										padding: .7em 7em .5em 0;
										transition-delay: .7s,.7s;
										transition-property: width,padding;
										border-radius:0;
									}
									label {
										@include letter_active;
									}
								}
							}
						}
					}
				}
				&.show {
					.thanks {
						.figure { 
							&:before {
								width: 100%;
								height: 100%;
								transition-delay: .3s;
							}
							.icon {
								box-shadow: 0px 5px 13px -1px rgba(0, 0, 0, 0.49);
								transition-delay: .7s;
								z-index: 10;
								&:before {
									width: 0;
								}
							} 
						}
					}
				}
				&.thanks {

					.line_email,.line_email  {
						display: none;
					}
					.thanks {
						display: flex;
						padding: 3.2em; 
						top: 0;
						opacity: 1; 
						transform:scale(1);
						transition-delay: .4s,.4s;
						transition-property: top,transform;
						z-index: 1;
						pointer-events: all;
						
						@media(max-width: 768px){
							display: block;
						}
					}
				}
			}
			&.present {

				@media(max-width: 1024px){
					font-size: 9px;
					padding: 7em;
					overflow-y: auto;
				}
				@media(max-width: 768px){
					font-size: 8px;
					padding: 7em 1.5em;
				}
				&.jumpToEmail {
					.modal_window {
						transition:.6s;
					}
					.modal {
						&_window {
							padding: 3em;
							width: 100%;
							opacity: 1;
							max-width: 50em	;
							@media(max-width: 550px){
								max-width: 60em;
							}
							.line {
								&_email, &_pass { 
									.title {
										top: 0;
										opacity: 1;
										margin-bottom: 6px;
										transition-delay: .5s, .5s;
										transition-property: top,opacity;
									}
									.subtitle {
										top: 0;
										opacity: 1;
										transition-delay: .6s, .5s;
										transition-property: top,opacity;
									}
									.btn {
										top: -5px;
										opacity: 1;
										transition-delay: .9s ;
										transition-property:  opacity;
										@media(max-width: 768px){
											top: 120%;
										}
									}	
									form {

										input {
											width: 100%;
											padding: .7em 7em .5em 0;
											transition-delay: .7s,.7s;
											transition-property: width,padding;
											border-radius:0;
										}
										label {
											@include letter_active;
										}
									}
								}
							}
						}
					}
					&.show {
						.thanks {
							.figure { 
								&:before {
									width: 100%;
									height: 100%;
									transition-delay: .3s;
								}
								.icon {
									box-shadow: 0px 5px 13px -1px rgba(0, 0, 0, 0.49);
									transition-delay: .7s;
									z-index: 10;
									&:before {
										width: 0;
									}
								} 
							}
						}
					}
					&.thanks {

						.line_email {
							display: none;
						}
						.thanks {
							display: flex;
							padding: 3.2em; 
							top: 0;
							opacity: 1; 
							transform:scale(1);
							transition-delay: .4s,.4s;
							transition-property: top,transform;
							z-index: 1;
							pointer-events: all;

							@media(max-width: 768px){
								display: block;
							}
						}
					}
				}
				&.jumpToEmail {
					.line_email {
						display: block;
						opacity: 1;
						z-index: 1;
						position: relative;
						transition: .6s,.6s z-index,.6s position;
						transition-delay: .6s opacity,.6s,.6s;
						transition-property: opacity,z-index,position; 
						
					}
					.line_present {
						opacity: 0;
						z-index: 0;
						position: absolute;
						left: 0;
						top: 0;

					}
				}
				.modal {
					&_window {
						width: 100%;
						max-width: 90em;
						opacity: 1;
						padding: 0;
					}
				}
				.line_email {
					opacity: 0;
					z-index: 0;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
		&_close {
			width: 5em;
			min-width: 5em;
			height: 5em;
			position: absolute;
			top: 0;
			right: -5.5em;
			display: block;
			z-index: 2;
			cursor: pointer;
			&:hover {
				span {
					&:before, &:after {
						width: 100%;
					}
				}
			}
			@media(max-width: 768px){
				top:  -5.5em;
				right:0;
			}
			span {
				@include abs;
				transform-origin: center center;
				width: 0;
				height: 1px;
				display: block;
				background:$w;
				transition: .5s width, transform .7s;
				&:before {
					content: '';
					width: 0;
					height: 100%;
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					background: $blue;
					transition:.8s;
				}
				&:after {
					content: '';
					width: 0;
					height: 100%;
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					background: $blue;
					transition:.8s;
				}

				&:first-child {
					transform:rotate(0deg);
				}
				&:last-child {
					transform:rotate(0deg);
				}
			}
		}

		&_window {
			display: block;
			position: relative;
			z-index: 1;
			background:#172b56;
			margin: auto;
			top: -10%;
			opacity: 0;
			color: $w;
			transition:.6s all, max-width 0s, padding 0s;
			padding: 3em;
			width: 100%;
			max-width: 50em	;
			.line {
				&_upper {
					width: 100%;
					display: flex;
					@media(max-width: 925px){
						display: block;
					}
				}
				&_left {
					width: 50%;
					height: 100%;
					display: block;
					position: relative;
					display: flex;
					align-items: stretch;
					justify-content: space-between;
					flex-direction: column;
					@media(max-width: 925px){
						width: 100%;
					}
					&:after {
						content: '';
						position: absolute;
						right: 0;
						bottom: 0;
						display: block;
						background: rgba(255, 255, 255, 0.25);
						width: 1px;
						height: 100%;
						margin: auto;
						transition: .5s;
						transition-delay: .4s;
						z-index: 3;
						@media(max-width: 925px){
							display: none;
						}
					}
					&:before {
						content: '';
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						display: block;
						background: rgba(255, 255, 255, 0.25);
						width: 100%;
						height: 1px;
						margin: auto;
						transition: .5s;
						z-index: 3;
						@media(max-width: 925px){
							margin: 0;
							background:none;
							border-top: 1px solid $border;
							border-bottom: 1px solid $border;
							height: 50%;
							width: 100%;
							top:auto;
						}
					}
				}
				&_right {
					width: 50%;
					@media(max-width: 925px){
						width: 100%;
					}
					.line {
						&_col {
							min-height: 100%;
							overflow: hidden;
							display: block;
							@media(max-width: 925px){
								display: flex;
								align-items: flex-start;
								height: 29em;
							}
							@media(max-width: 768px){
								height: auto;
							}
						}
					}
					img {
						display: block;
						width: 28em;
						margin: 3em 0 0;
						transition:.6s;
						@media(max-width: 925px){
							margin: 0;
						}
						@media(max-width: 768px){
							display: none;
						}
					}
				}
				&_col {
					position: relative;
					width: 100%;
					height: 21em;
					z-index: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 3.1em 3.5em;
					@media(max-width: 925px){
						flex-direction: row; 
						height: auto;
						z-index: 5;
					}
					@media(max-width: 768px){
						display: block;
					}

					.upper,.bottom {
						width: 80%;
						@media(max-width: 925px){
							width: auto;
						}
						.subtitle {
							font-size: 1.3em;
							font-family: $hlight;
						}
						.title {
							display: flex;
							position: relative;
							align-items: flex-start;
							.optional {
								color: $blue;
								font-size: .6em;
								margin: -10px 0 0 5px;
								font-family: $hlight;
								position: relative;
								&.coming {
									white-space: nowrap;
								}
							}
						}
						img {
							min-width: 10em;
							margin-right: 2em;
						}
					}
					.upper {
						transition:1s;
						@media(max-width: 768px){
							display: block;
							margin-bottom: 2em;
						}
						.title {
							font-size: 2.0em;
							font-family: $hregular;
							line-height: 1.1;
							margin-bottom: 4px;
						}

					}
					.bottom {
						display: flex;
						align-items: center;
						transition:.8s;
						@media(max-width: 768px){
							justify-content: space-between;
						}
						.title {
							font-size: 1.8em;
							font-family: $hregular;
							line-height: 1.1;
						}
					}
				}
				&_bottom {
					height: 6em;
					background:#14234d;
					@include fb;
					cursor: pointer;
					transition:1s; 
					&:active {
						box-shadow: 0 0 14px -3px rgba(0,0,0,.47) inset;
					}
					span {
						text-transform: uppercase;
						color: $blue;
						font-size: 1.0em;
						letter-spacing: 2px;
						font-family: $psemibold;
					}
				}
				&_email, &_pass {
					.title {
						font-size: 2.2em;
						font-family: $hregular;
						@include letter;
						position: relative;
						@media(max-width: 768px){
							top: 0;
							opacity: 1;
							transition:0s;
						}
					}
					.subtitle {
						font-size: 1.05em;
						line-height: 1.5;
						@include letter;
						position: relative;
						@media(max-width: 768px){
							top: 0;
							opacity: 1;
							transition:0s;
						}
					}
					form {
						position: relative;
						display: -webkit-flex;
						display: -moz-flex;
						display: -ms-flex;
						display: -o-flex;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						flex-wrap: wrap;
						margin-top: 3em;
						@media(max-width: 768px){
							margin-bottom: 7em;
						}
					}
					input {
						font-family: $psemibold;
						font-size: 1.4em;
						border:0;
						border-bottom: 2px solid $border;
						box-sizing: border-box;
						color: $w;
						display: block;
						position: relative;
						width: 100%;
						padding: .7em 7em .5em 0;
						transition-delay: .7s,.7s;
						transition-property: width,padding;
						border-radius:0;

						&.error {
							&+label {
								color: red;
								top: -15px;
							}
							&+label+span {
								width: 100%;
								&:after {
									width: 100%;
								}
							}
						}
						
						&:focus, &.focus {
							&+label {
								top: -15px;
							}
							&+label+span {
								width: 100%;
								left: 0;
								right: auto;
							}
						}
						&+label+span {
							display: block;
							position: absolute;
							right: 0;
							left: auto;
							bottom: 0;
							width: 0;
							height: 2px;
							transition:.4s;
							background: #4bb4ff;
							box-shadow: 0 -1px 15px 2px rgba(75,180,255,.3);
							&:after {
								content: '';
								width: 0;
								height: 100%;
								display: block;
								transition:.4s;
								right: 0;
								float: right;
								top: 0;
								bottom: 0;
								margin: auto;
								background:red;
								box-shadow: 0 -1px 15px 2px rgba(255, 0, 0, 0.77);
							}
						}
					}
					label {
						display: block;
						text-transform: uppercase;
						font-family: $psemibold;
						font-size: 1.15em;
						transition:.4s;
						letter-spacing: 1.2px;
						position: absolute;
						left: 0;
						top: 3px;
						@include letter;
					}
					.btn {
						position: absolute;
						right: 0;
						top: -5px;
						font-size: 1.1em;
						letter-spacing: 2.5px;
						padding: .8em 1.8em;
						font-family: $psemibold;
						@media(max-width: 768px){
							width: 100%;
							max-width: calc(100% - 3em);
							top: 120%;
							right: 0;
							display: block;
							margin: auto;
							left: 0;
							height: 2em;
							transition:.8s opacity;
						}
					}
				}
				&_pass {
					.title {
						text-align: center;
						font-size: 3em;
						text-transform: capitalize;
					}
				}
			}
			.thanks {
				display: none;
				align-items: center;
				position: relative;
				transform:scale(.9);
				transition:.7s;
				z-index: 0;
				pointer-events: none;
				.figure {
					width: 12em; 
					min-width: 12em; 
					height: 12em; 
					border-radius: 50%;
					position: relative;
					
					padding: 2em;
					margin: auto;
					&:before {
						content: '';
						width: 0;
						height: 0;
						@include abs;
						border-radius: 50%;
						border:1px solid #1f7371;
						z-index: 0;
						transition:1s;
					}

					.icon {
						@include fb;
						width: 100%;
						height: 100%;
						border-radius: 50%;
						position: relative;
						border:1px solid #1f7371;
						transition: .9s box-shadow;
						z-index: 1;
						svg {
							width: 50%;
							height: 45%;
						}
						&:before {
							content: '';
							width: 70%;
							height: 62%;
							position: absolute;
							right: 15%;
							top: 0;
							bottom: 0;
							display: block;
							background: #172b56;
							margin: auto;
							z-index: 2;
							transition:1s;
						}
					}
				} 
				.title {
					font-size: 2.0em;
					font-family: $hregular;
					line-height: 1.5;
					margin-bottom: 1em;
				}
				.subtitle {
					font-size: 1.3em;
					font-family: $hlight;
				}
				&_text {
					padding-left: 4em;
					@media(max-width: 768px){
						padding-left: 0;
						padding-top: 3em;
					}
				}
			}
			.number {
				position: absolute;
				top: 0;
				right: .2em;
				font-family:$hregular;
				color: $w;
				font-size: 11em;
				line-height: 1.4;
				transition:.4s;
				opacity: 1;
				@media(max-width: 925px){
					display: none;
				}
			}
		}
	}
}