#root {
	header {
		width: 100%;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1.5em 4em;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 20;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			z-index: 0;
			transition:1s opacity;
			opacity: 0;
			background: -moz-linear-gradient(88deg, rgba(20,35,77,0.15) 0%, rgba(20,35,77,1) 100%); 
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(20,35,77,1)), color-stop(100%, rgba(20,35,77,0.15))); 
			background: -webkit-linear-gradient(88deg, rgba(20,35,77,0.15) 0%, rgba(20,35,77,1) 100%); 
			background: -o-linear-gradient(88deg, rgba(20,35,77,0.15) 0%, rgba(20,35,77,1) 100%); 
			background: -ms-linear-gradient(88deg, rgba(20,35,77,0.15) 0%, rgba(20,35,77,1) 100%); 
			background: linear-gradient(2deg, rgba(20,35,77,0.15) 0%, rgba(20,35,77,1) 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#14234d', endColorstr='#14234d',GradientType=0 );
		} 

		&.shadow {
			&:before {
				opacity: 1;
			}
		}

		.wrapLogo {
			a {
				cursor: pointer;
				&:first-child {
					display: block;
				}
				&:last-child {
					display: none;
				}
			}
		}
		&.other_page {
			.wrapLogo {
				a {
					&:first-child {
						display: none;
					}
					&:last-child {
						display: block;
					}
				}
			}
		}

		&.focus {
			top: 0;
			opacity: 1;
			transition-delay: .5s,.5s;
			transition-property: top, opacity ;
			&.ghost {
				div:not(.wrapLogo) {
					opacity: 0;
					pointer-events: none;
				}
				#present {
					opacity: 0;
					pointer-events: none;
				}
			}
		}
		@media(min-width: 1921px){
			width: 100%;
			max-width: 1920px;
			margin: 0 auto;
			padding-left: 0;
			padding-right: 0;
		}
		@media(min-width: 993px){
			top: -10em;
			opacity: 0;
			transition:top .9s, opacity .9s;
		}
		@media(max-width: 992px){
			transition:background .4s, box-shadow .6s;
			padding: 2.7em 2em;
		}

		&.active {
			.mobile_button {
				.title {
					transform:translate(-3em,0) scale(.8);
					opacity: 1;
				}
				span {
					&:nth-child(1) {
						transform: rotate(-31deg);
					}
					&:nth-child(2) {
						width: 0;
						margin: 8px auto;
					}
					&:nth-child(3) {
						transform: rotate(35deg);
					}	
				}
			}
			.wrapper {
				left: 0;
			}

			nav {
				transition-delay: .3s;
				left: 0;
				top: 0;
				opacity: 1;
			}
			.wrap_get_started {
				transition-delay: .4s;
				left: 0;
				opacity: 1;
			}
		}


		.logo {
			width: 21em;
			display: block;
			.st0{
				fill:#FFFFFF;
			}
			position: relative;
			z-index: 12;
			cursor: pointer;
			@media(max-width: 768px){
				width: 24em;
			}
		}
		.wrapper {
			@include fb;
			margin: 0 0 0 auto;
			background: #14234d;
			@media(min-width: 992px) {
				margin: 0 2em 0 auto;
				background:transparent;
			}
			@media(max-width: 992px){
				position: fixed;	
				transition:.6s;
				z-index: 10;	
				left: -105%;
				top: 0;
				height: 100%;
				width: 100%;
				display: flex;
				flex-direction: column;
				overflow-y: auto;
				&:after {
					content: '';
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					opacity: .5;
					z-index: -1;
					left: 0;
					top: 0;
					opacity: .25;
					background:url('../img/noise.gif');
				}
			}
			@media(max-width: 768px){
				overflow-y: auto;
			}
			@media(max-width: 570px){
				font-size: 8px;
			}
			&:not(.socials a) {
				a {
					display: inline-block;
				}
			}
			a {
				&:not(.wrap_get_started){

					color: $w;
					font-family: $hregular;
					font-size: 1.5em;
					position: relative;
					transition:.2s;
					margin: 0 1.5em	;
					display: flex;
					align-items: center;
					@media(max-width: 768px){
						margin: 0 2.8em	;
					}
					&:hover {
						.before {
							opacity: 1;
							&:after {
								// right: -1em;
								// animation-name: leftRight;
								// animation-duration:.4s;
								//animation-iteration-count:1;
								// animation-timing-function:linear;
							}
						} 
						.after {
							opacity: 1;
							&:after {
								// left: -1em;
								// animation-name: rightLeft;
								// animation-duration:.4s;
								//animation-iteration-count:1;
								// animation-timing-function:linear;
							}
							
						}
					}
					&:before, &:after {
						content: '';
						height: .6em;
						width: .6em;
						min-width: .6em;
						transition:.4s;
					}
					&:before {
						opacity: 0;
						position: absolute;
						top: 0;
						bottom: 0;
						left: -2em;
						margin: auto;
						display: block;
						background:url('../img/triangle_right.svg')no-repeat center;
						background-size: 100%;
					}
					&:after {
						opacity: 0;
						position: absolute;
						top: 0;
						bottom: 0;
						right: -2em;
						margin: auto;
						display: block;
						background:url('../img/triangle_left.svg')no-repeat center;
						background-size: 100%;
					}
				}
				&.active {
					color:$blue;
					&:hover {
						&:before, &:after {
							display: none;
						}
					}
				}
				&:hover {

					&:before {
						opacity: 1;
						left: -1em;
						// animation-name: leftRight;
						// animation-duration:1.4s;
						//animation-iteration-count:1;
						// animation-timing-function:linear;
					}
					&:after {
						opacity: 1;
						right: -1em; 
						// animation-name: rightLeft;
						// animation-duration:1.4s;
						//animation-iteration-count:1;
						// animation-timing-function:linear;
					}
				}
				&:last-child {
					margin-right: 0;
				}
			} 
			.envelope {
				a {
					margin-left: 0;
					&:before, &:after {
						display: none;
					}
				}
				@media(max-width: 570px){
					a {
						margin-left: 0;
					}
				}
			}
			.mobile_section {
				@media(min-width: 992px) {
					display: none;
				}
				&.socials {
					a {
						font-family: $hlight;
					}
					@media(max-width: 992px){
						a{
							margin-left: 0;
						}
					}
					
				}
				&.wrapper {
					display: -webkit-flex;
					display: -moz-flex;
					display: -ms-flex;
					display: -o-flex;
					display: flex;
					justify-content: center;
					align-items: flex-start;
					width: 100%;
				}
			}
		}

		@keyframes leftRight {
			0% {opacity: 1; transform: translateX(0px) scale(1);}
			25%{opacity: 0; transform:translateX(10px) scale(0.9);}
			26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
			55% {opacity: 1; transform: translateX(0px) scale(1);}
		}
		@keyframes rightLeft {
			0% {opacity: 1; transform: translateX(0px) scale(1);}
			25%{opacity: 0; transform:translateX(-10px) scale(0.9);}
			26%{opacity: 0; transform:translateX(10px) scale(0.9);}
			55% {opacity: 1; transform: translateX(0px) scale(1);}
		}

		.bounceAlpha {
			animation-name: bounceAlpha;
			animation-duration:1.4s;
			//animation-iteration-count:1;
			animation-timing-function:linear;
		}



		nav {
			@media(max-width: 992px){
				width: 100%;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: flex-start;
				justify-content:space-around;
				padding: 8em 4em 2em;
				position: relative;
				transition:1s;
				left: -10em;
				opacity: 0;

				.menu_links {
					display: block;
					white-space: pre-line;
					text-align-last: auto;
					order:1;
					& > a:not(.wrap_get_started) {
						font-size: 4em;
						margin: 0;
						position: relative;
						display: block;
						width: intrinsic;
						width: -moz-max-content;
						width: -webkit-max-content;
						&:nth-child(even) {
							margin: .3em 0;
						}
						@media(max-width: 570px){
							margin-left: 1.2em !important;
							&:nth-child(even) {
								margin-left: 1.2em;
							}
						}
						@media(max-width: 570px){
							font-size: 3em;
							
							&:nth-child(even) {
								margin: .3em 0 0 1.2em;
							}
						}
					}

				}
			}
			@media(max-width: 768px){
				padding: 8em 1em 2em;
			}
			@media(max-width: 570px){
				display: block;
				padding: 8em 0 2em;
			}
		}

		.menu_links {
			display: flex;
			align-items: center;
			@media(min-width: 768px){
				order:1;
			}
		}
		.envelope {
			display: block;
			
			@media(min-width: 768px){
				order:0;
			}
			.help {
				color:$w;
				font-family: $hregular;
				font-size: 1.8em;
				margin-bottom: .4em;
			}
			a {
				margin: 0;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: center;
				font-family: $hlight;
				font-size: 1.8em;
			}
			svg {
				margin-right: 1em;
			}
			@media(max-width: 570px){
				padding: 2.5em 2.5em  2.5em 5.3em;
			}
		}


		.socials {

			@media(min-width: 570px){
				display: block;
				white-space: pre-line;
				width: intrinsic;
				width: -moz-max-content;
				width: -webkit-max-content;
				a {
					font-family: $hlight;
					font-size: 1.6em;
					margin: 0;
					display: block;
					line-height: 2;
				}
			}
			
			@media(max-width: 570px){
				border-bottom: 1px solid #434f71;
				padding-bottom: .8em;
				margin: 4em 0 2em;
				justify-content: flex-start;
				padding-left: 5em;
				a {
					font-size: 1.6em;
				}
			}
		}

		.wrap_get_started {
			display: block;
			width: 100%;
			padding: 4em 3em 3em;
			margin: auto 0 0;
			background: -moz-linear-gradient(331deg, #4d2eb9 0%, #4d2eb9 47%, #1d4dd4 100%); 
			background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #4d2eb9), color-stop(47%, #4d2eb9), color-stop(100%, #1d4dd4));
			background: -webkit-linear-gradient(331deg, #4d2eb9 0%, #4d2eb9 47%, #1d4dd4 100%); 
			background: -o-linear-gradient(331deg, #4d2eb9 0%, #4d2eb9 47%, #1d4dd4 100%); 
			background: -ms-linear-gradient(331deg, #4d2eb9 0%, #4d2eb9 47%, #1d4dd4 100%); 
			background: linear-gradient(119deg, #4d2eb9 0%, #4d2eb9 47%, #1d4dd4 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d2eb9', endColorstr='#1d4dd4',GradientType=1 );
			position: relative;
			transition:left .9s, opacity .7s;
			left: -7em;
			opacity: 0;
			overflow: hidden;
			#noise {
				position: absolute;
				right: 0;
				bottom: 0;
				height: 130%;
				width: 130%;
			}

			span {
				color: $w;
				font-family: $hregular;
				font-size: 1.2em;
				display: block;
				@media(max-width: 570px){
					font-size: 1.55em;
				}
			}
			.get_started_button {
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-family: $hregular;
				font-size: 4em;
				color:$w;
			}
		}

		.btn {
			@media (max-width: 992px) {
				margin-left: auto;
			}
			@media(max-width: 570px){
				display: none;
			}
		}

		.mobile_button {
			display: block;
			width: 35px;
			margin: 0 0 0 2em;
			cursor: pointer;
			position: relative;
			z-index: 10;
			@media(min-width: 992px) {
				display: none;
			}
			@media(max-width: 550px){
				width: 5em;
			}
			span {
				display: block;
				margin: 0 auto;
				height: 2px;
				background:$w;
				width: 100%;
				transition: transform .4s,  width .4s , margin .4s;
				&:nth-child(1) {
					transform-origin: top right;
				}
				&:nth-child(2) {
					margin: .7em auto;
					width: 75%;
					@media(max-width: 768px){
						margin: 8px auto;
					}
				}
				&:nth-child(3) {
					transform-origin: top right;
				}
			}

			.title {
				position: absolute;
				top: 0;
				bottom: 0;
				color: $w;
				font-family: $hregular;
				transform:translate(0,0) scale(.8);
				font-size: 1.5em;
				transition:.4s;
				opacity: 0;
				@media(max-width: 768px){
					font-size: 2.0em;
				}
			}
		}
	}
}

