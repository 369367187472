@font-face {
    font-family: "Prompt Bold";
    src: url("../fonts/Prompt-Bold.woff2") format("woff2"),
        url("../fonts/Prompt-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Prompt SemiBold";
    src: url("../fonts/Prompt-SemiBold.woff2") format("woff2"),
        url("../fonts/Prompt-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Prompt Regular";
    src: url("../fonts/Prompt-Regular.woff2") format("woff2"),
        url("../fonts/Prompt-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Prompt Medium";
    src: url("../fonts/Prompt-Medium.woff2") format("woff2"),
        url("../fonts/Prompt-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Heebo Light";
    src: url("../fonts/Heebo-Light.woff2") format("woff2"),
        url("../fonts/Heebo-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Heebo Regular";
    src: url("../fonts/Heebo-Regular.woff2") format("woff2"),
        url("../fonts/Heebo-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Heebo-Medium";
    src: url("../fonts/Heebo-Medium.woff2") format("woff2"),
        url("../fonts/Heebo-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Heebo-Bold";
    src: url("../fonts/Heebo-Bold.woff2") format("woff2"),
        url("../fonts/Heebo-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
