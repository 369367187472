.cookies-alert{
  position: fixed;
  bottom: 0;
  width: 100vw;
  color: white;
  background-color: rgba(255, 255, 255, .8);
  z-index: 10;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  :after {
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../img/noise.gif");;
  }
  a {
    color: #4bb4ff;
  }
}