.arrowPrev {
	position: relative;
	transform: rotate(180deg);
	transform-origin: center;
}
.page_how_it_work {
	position: relative;
	display: block;
	font-size: 10px;
	@media(min-width: 1400px){
		font-size: 12.5px;
	}
	// @media (min-width: 1921px) {
	// 	font-size:13px;
	// }
	
	@media (max-width: 1024px) {
		font-size: 9px;
		overflow-x: hidden;
	}
	@media (max-width: 992px) {
		font-size: 8.5px;
		overflow-x: hidden;
	}
	@media (max-width: 768px) {
		font-size: 7.5px;
	}
	@media (max-width: 650px) {
		font-size: 7px;
	}

	.banner {
		z-index: 3;
		display: flex;
		min-height: 100vh;
		position: relative;
		align-items: center;
		padding: 8em 2em 6em 2.4em;
		@media(max-width: 1280px){
			padding: 9em 2em 6em 5.1em;
		}

		@media(max-width: 768px){
			padding: 21em 0 8em;
			display: block;
		}
		@media(max-width: 650px){
			padding-bottom: 10em;
		}
		@media(max-width: 550px){
			height: auto;
			min-height: 77em;
		}
		@media(max-width: 340px){
			padding:10.5em 0 8em .75em;
		}
		&.active {
			.present_title {
				opacity: 1;
				top: 0;
				span {
					opacity: 1 !important;
					top: 0 !important;
				}
			}
			.wrapDevices {
				&_phone {
					img {
						right: 0;
						top: 0;
						opacity: 1;
					}
					.device_title {
						transform: rotate(25deg) translate(0, 0);
						opacity: 1;
						@media(min-width: 992px){
							transition-delay: .4s;
						}
					}
				}
				&_tablet {
					img {
						left: 0;
						top: 0;
						opacity: 1;
					}
					.device_title {
						transform: rotate(-21deg) translate(0, 0);
						opacity: 1;
						@media(min-width: 992px){
							transition-delay: .3s;
						}
					}
				}
			}
		}
		.wrapDevices {
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			display: flex;
			padding:6em 0 0 0;
			position: absolute;
			align-items: flex-start;
			justify-content: flex-end;
			transition:.7s right ease-out;
			@media(max-width: 768px){
				position: relative;
			}
			
			img {
				width: 100%;
				position: relative;
			}
			.device_title{
				color: #fff;
				position: relative;
				transform-origin: center center;
				display: block;
				width: 100%;
				transition:.8s;
				top: -13.5em;
				margin: 0 2em 0 auto;
				max-width: 39em;
				opacity: 0;
				@media(max-width: 768px){
					display: none;
				}
				p {
					font-size: 1.1em;
					b {
						text-transform: uppercase;
						margin-right: 10px;
						font-family: $pregular;
						letter-spacing: 1.1px;
						text-shadow:0px 3px 6px #ee1e8f, 0px 11px 10px #ee1e8f;
					}
				}
			}
			&_tablet {
				position: absolute;
				width: 100%;
				max-width: 85em;
				right: 17em;
				.device_title {
					transform: rotate(-20.5deg) translate(-10em, 0);
				}
				img {
					transition:.7s;
					top: -20em;
					left: -20em;
					opacity: 0;
				}
				@media(max-width: 992px){
					img {
						left: 0;
						top: 0;
						opacity: 1;
					}
					.device_title {
						transform: rotate(-21deg) translate(0, 0);
						opacity: 1;
						@media(min-width: 992px){
							transition-delay: .3s;
						}
					}
				}
				@media(max-width: 768px){
					max-width: 94em;
					right: 19em;
				}
				@media(max-width: 550px){
					max-width: 80em;
					right: auto;
					left: -20%;
				}
				@media(max-width: 340px){
					transform: scale(1.3);
					bottom: 5em;
				}
			}
			&_phone {
				width: 100%;
				max-width: 43.5em;
				margin: -1em 0em 0 0;
				@media(max-width: 768px){
					max-width: 46.5em;
				}
				@media(max-width: 550px){
					max-width: 35em;
				}
				@media(max-width: 440px){
					max-width: 30em;
				}
				@media(max-width: 340px){
					margin: 2em -4em 0 0;
					max-width: 27em;
				}
				img {

					right: 0;
					top: 0;
					opacity: 1;

					
					@media(min-width: 992px){
						transition:.7s;
						top: -20em;
						right: -20em;
						opacity: 0;
					}
				}

				.device_title {
					margin: -9em auto 0 auto;
					top: 0;
					left: -7em;
					transform: rotate(25deg) translate(10em, 0);
					max-width: 20em;
					text-align: center;
				}
			}
		}
		.container {
			display: block;
			width: 100%;
			position: relative;
			z-index: 3;
			margin-top: auto;

			@media(min-width: 1921px){
				width: 100%;
				max-width: 1920px;
				margin: 0 auto;
			}
			@media(min-width: 1400px){
				width: 84%;
			}
			@media(max-width: 1280px){
				margin-top: 0;
			}
			@media(max-width: 650px){
				position: static;
			}
		}
		.present {
			display: block;
			position: relative;
			width: 100%;	
			max-width: 100em;	
			@media(max-width: 768px){
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				align-items: center;
				padding: 0 1em;
			}		
			@media(max-width: 650px){
				position: static;
			}
			&_title {
				display: block;
				color: $w;
				line-height: 1.2;
				font-size: 4.9em;
				margin: 0 0 0.3em;
				padding-left: .7em;
				position: relative;
				font-family: $hregular;
				@media(max-width: 768px){
					font-size: 6.7em;
				}
				@media(max-width: 600px){
					font-size: 4.4em;
				}
				@media(max-width: 420px){
					font-size: 3.4em;
				}
				@media(max-width: 340px){
					font-size: 4.4em;
					margin: 0 0 0.1em;
				}
				&:nth-child(2) {
					padding-left: 2.1em;
					@media(max-width: 768px){
						padding-left: 1.7em;
					}
					@media(max-width: 340px){
						padding-left: 0;
					}
				}
				
				&:first-child {
					text-align: left;
					padding-left: 0;

				}

				span {
					letter-spacing: .01em;
					position: relative;
					opacity: 0;
					top: -.8em;
					transition:opacity .6s,top .6s;
				}
			}
			&_info {
				color: $w;
				align-items: center;
				width: 8.1em;
				display: block;
				position: relative;
				transition:.7s left, .7s opacity;
				font-family: $pbold;
				text-transform: uppercase;
				font-size: 1.4em;
				min-width: 7px;
				letter-spacing: .05em;
				text-align: right;

				@media (max-width: 992px) {
					position: absolute;
					bottom: 0;
					left: 0;
					justify-content: flex-end;
					width: 12.4em;
				}
				@media (max-width: 768px) {
					display: none;	
					position: absolute;
					bottom: 0;
					left: 5em;
				}
				&:before {
					content: '';
					width: 0;
					height: 2px;
					background: #fff;
					transition: .6s;
					position: relative;
					margin: 0 .6em 0 0;
					display: inline-flex;
					vertical-align: middle;
				}
			}
			&_line {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;      
				padding:7em 0 0 8em;
				position: relative;


				@media (max-width: 992px) {
					padding: 7em 3em 0 0em;
					justify-content: flex-end;	
				}
				@media (max-width: 768px) {
					padding: 9em 0em 0 0em;
					justify-content: flex-end;	
				}
			}
			.pointer {
				top: 2.5em;
				@include fb();
				position: relative;
				margin: 0 0 0 20em;
				border-radius: 50%;
				@include w2(9.5,9.5);
				flex-direction: column;
				@media(max-width: 1280px){
					top: 3.5em;
				}
				@media(max-width: 768px){
					margin: 0 auto 0 auto;
					top: -1.8em;
					@include w2(14.5,14.5);

				}
				@media(max-width: 767px){
					position: absolute;
					bottom: 3em;
					right: 2em;
					top: auto;
				}
				@media(max-width: 340px){
					bottom: -2em;
					right: 0.8em;
				}

				&:before {
					content: '';
					width: 2px;
					transition:.4s;
					height: 40%;
					background: rgb(180, 179, 255);
				}
				&:after {
					content: '';
					@include w2(1.8,1.8);
					border-right:2px solid rgb(180, 179, 255);
					border-bottom:2px solid rgb(180, 179, 255);
					transform-origin: center center;
					transform: rotate(45deg);
					position: relative;
					margin: -2.2em 0 0 0;
					transition:.4s;
					top: 0em;
				}

				img {
					width: 100%;
					@include abs;
					width: 100%;
					height: 100%;
					animation:circle 60s  linear infinite; 
				}
			}
		}
	}
	.common_proplems {
		width: 100%;
		padding: 4.6em 0em 4.5em 7em;
		max-width: 89.4%;
		margin: 12em 0 0;
		min-height: 20em;
		overflow: hidden;
		position: relative;
		background:rgba(255, 255, 255, 0.15);
		@media(max-width: 1280px){
			max-width: 88.8%;
			margin: 8.4em 0 0;
			padding: 7.0em 0em 3.1em 7em;
		}
		@media(max-width: 768px){
			max-width: 89.3%;
			margin: 28.3em 0 0;
			padding: 9em 0em 3.1em 7em;
		}
		@media(max-width: 767px){
			max-width: 100%;
			margin: 15.3em 0 0;
			padding: 9em 1.5em 9em 1.5em;
		}
		
		@media(max-width: 550px){
			max-width: 100%;
		}
		@media(max-width: 340px){
			margin: 16.3em 0 0;
			padding: 11em 1.5em 9em 1.5em;
		}
		.container {
			width: 100%;
			display: flex;
			max-width: 86em;
			margin: 0 0 0 auto;
			align-items: flex-start;
			@media(max-width: 1280px){
				display: block;
				max-width: 97em;
			}
		}
		.col {
			color: $w;
			width: 100%;
			display: flex;
			overflow: hidden;
			max-width: 26.4em;
			min-height: 16.5em;
			padding: 2.3em 2em;
			position: relative;
			margin-bottom: 1.7em;
			flex-direction: column;			
			border-radius: 0 0 5em 0;
			@include br(rgb(135, 89, 212));
			border-left: 2px solid rgb(135, 89, 212);
			background-image: -ms-linear-gradient( -45deg, rgb(23,43,86) 0%, rgb(35,64,127) 100%);
			background-image: -moz-linear-gradient( -45deg, rgb(23,43,86) 0%, rgb(35,64,127) 100%);
			background-image: -webkit-linear-gradient( -45deg, rgb(23,43,86) 0%, rgb(35,64,127) 100%);
			box-shadow: -17.046px 23.461px 43px 0px rgba(234, 31, 181, 0.17),23.461px 17.046px 43px 0px rgba(9, 17, 54, 0.47);
			&:hover {
				.rose_planet {
					&:before  {
						animation:planet_circls 5s  linear infinite; 
					}
					&:after  {
						animation:planet_circls 5s  linear infinite; 
						animation-delay: 2.5s;
					}
					.planet {
						&:before {
							animation:planet_lines 20s  linear 19; 
						}
					}
				}
			}
			@media(max-width: 1280px){
				margin-right: 0;
				margin-right: 4em;
				max-width: 41.8em;
				min-height: 25.5em;
				padding: 3.4em 3.3em;
				border-radius: 0 0 8em 0;
				margin-left: 0;
				&:first-child {
					margin-right: 8.5em;
					margin-left: 0;
				}
			}
			@media(max-width: 992px){
				.rose_planet {
					&:before  {
						animation:planet_circls 5s  linear infinite; 
					}
					&:after  {
						animation:planet_circls 5s  linear infinite; 
						animation-delay: 2.5s;
					}
					.planet {
						&:before {
							animation:planet_lines 20s  linear 19; 
						}
					}
				}
			}
			// @media(min-width: 769px){
			// 	margin: 0 auto 1em;
			// 	max-width: 41.8em;
			// 	min-height: 26.0em;
			// 	border-radius: 0 0 9em 0;
			// }
			@media(min-width: 768px) and (max-width: 1279px){
				margin: 0 auto 4em;
				max-width: 34.8em;
				min-height: 20.5em;
				padding: 3.4em 1.5em 3.4em 2em;
				border-radius: 0 0 12em 0;
				&:first-child {
					margin-right: 3.5em;
					margin-left: 0;
				}
			}
			@media(max-width: 767px){
				margin: 0 auto 4em;
				&:first-child {
					margin-right: auto;
				}
			}
			@media(max-width: 340px){
				max-width: 39.8em;
				min-height: 24.5em;
				margin: 0 auto 3em;
				border-radius: 0 0 13em 0;
			}
			&:last-child {
				@media(min-width: 1280px){
					margin-bottom: 0;
				}
			}
			&_title {
				z-index: 2;
				display: block;
				line-height: 1.3;
				font-size: 1.1em;
				margin-bottom: 1em;
				position: relative;
				@media(max-width: 1280px){
					line-height: 1.45;
					font-size: 1.5em;
					margin-bottom: 1.85em;
					position: relative;
					max-width: 17em;
				}
				@media(max-width: 768px){
					max-width: 100%;
				}
				@media(max-width: 340px){
					font-size: 1.7em;
				}
			}
			.bottom {
				z-index: 2;
				display: flex;
				text-align: center;
				position: relative;
				margin: auto 0 0 0;
				align-items: flex-end;
				justify-content: center;
				@media(max-width: 1280px){
					margin: 0;
				}
				p {
					text-transform: uppercase;
					font-family: $pregular;
					font-size: 1.06em;
					letter-spacing: 2px;
					width: 100%;
					max-width: 7em;
					text-align: left;
					margin: 0 0 0 8px;
					line-height: 1.2;
					@media(max-width: 1280px){
						font-size: 1.5em;
						max-width: 8em;
						line-height: 1.5;
						letter-spacing: 4px;
						margin: 0em -3.4em -.7em 1em;
					}
					@media(max-width: 768px){
						margin: 0em -0.4em -0.7em 0em;
					}
					@media(max-width: 340px){
						font-size: 1.4em;
						max-width: 9em;
					}
				}
			}
			h2 {
				line-height: 1;
				font-size: 5.9em;
				font-family: $hregular;
				@media(max-width: 1280px){
					font-size: 8.9em;
				}
				@media(max-width: 768px){
					font-size: 7.9em;
				}
			}
		}
		.first_circle {
			position: absolute;
			right: 5%;
			bottom: -5%;
			display: block;
			@include w2(140,140);
			opacity: .2;
			&:before {
				content: '';
				@include inner_lines;
				@media(min-width: 992px){
					animation:lines 15s  linear infinite; 
				}
			}
			@media(max-width: 1280px){
				right: -44%;
			}
		}
		.second_circle {
			position: absolute;
			right: -44%;
			bottom: -62%;
			display: block;
			@include w2(190,190);
			opacity: .2;
			@media(max-width: 1280px){
				@include w2(200,200);
			}
			@media(max-width: 992px){
				right: -100%;
				bottom: -42%;
			}
			&:before {
				@include inner_lines();
				width: 0;
				height: 0;
				
				@media(min-width: 992px){
					animation:lines 24s  linear infinite; 
					animation-delay: 3s;
				}
			}
		}
		.left {
			@media(max-width: 768px){
				padding: 0 0 0 3em;

			}
			@media(min-width: 751px) and (max-width: 1280px){
				display: flex;	
				align-items: flex-start;
			}
			@media(max-width: 750px){
				display: block;
			}
		}
		.right {
			color: $w;
			width: 100%;
			padding-left: 0;
			max-width: 46.4em;
			margin-left: auto;
			padding: 2.8em 0 0 0;
			@media(max-width: 1280px){
				max-width: 100%;
				padding: 5.4em 0 0 0em;
			}
			@media(max-width: 768px){
				max-width: 100%;
				margin: 0 auto;
			}
			@media(max-width: 340px){
				padding: 7.0em 0 0 0em;
			}

			.title {
				display: block;
				font-size: 1.6em;
				line-height: 1.35;
				position: relative;
				margin-bottom: 5px;
				letter-spacing: .3px;
				font-family: $hregular;
				@media(max-width: 1280px){
					font-size: 2.17em;
					margin: 0 0 0.5em 0;
					line-height: 1.45;
				}
				@media(max-width: 768px){
					font-size: 3em;
				}
				@media(max-width: 340px){
					font-size: 3.3em;
				}
			}
			p {
				font-size: 1.20em;
				font-family: $hlight;
				line-height: 1.2;
				@media(max-width: 1280px){
					font-size: 1.53em;
					line-height: 1.44;
				}
				@media(max-width: 768px){
					font-size: 1.99em;
				}
				@media(max-width: 340px){
					font-size: 2.3em;
				}
			}
			.wrap {
				width: 100%;
				display: flex;
				align-items: flex-start;
				@media(max-width: 1280px){
					padding: .4em 0 0 0;
				}
				@media(max-width: 767px){
					display: block;

				}
				&_title {
					@include title_light(1.0);
					margin-bottom: 1.35em;
					@media(max-width: 1280px){
						font-size: 1.35em;
						letter-spacing: 3.1px;
						padding: 0 0 0 .6em;
					}
					@media(max-width: 768px){
						font-size: 1.85em;
						margin-bottom: 1em;
						padding: 0 0 0 1.5em;
					}
					@media(max-width: 340px){
						font-size: 2em;
						margin-bottom: 1.5em;
						padding: 0 0 0 0.2em;
					}
				}
			}
			ul {
				width: 100%;
				display: block;
				max-width: 19.0em;
				padding: 0 2em 0 1.6em;
				@media(max-width: 1280px){
					max-width: 31em;
					padding: 0 4em 0 3.2em;
					&:last-child {
						padding-left: 2em;
						max-width: 30em;
					}
				}
				@media(max-width: 768px){
					max-width: 42em;
					padding: 0 4em 0 0em;
					margin: 0 0 0 0 ;
					&:last-child {
						max-width: 37em;
						padding-left: 0;
						li {
							padding-left: 5em;
						}
					}
				}
				@media(max-width: 767px){
					max-width: 100%;
					&:first-child {
						margin-bottom: 3em;
					}
					&:last-child {
						li {
							padding-left: 3em;
						}
					}
				}
				@media(max-width: 340px){
					padding: 0 0 0 0em;
					max-width: 40em;
					&:first-child {
						margin-bottom: 5em;
					}
					&:last-child {
						li {
							padding-left: 4.5em;
						}
					}
				}
				li {
					margin-bottom: 2.4em;
					position: relative;
					@media(max-width: 1280px){
						margin-bottom: 3.3em;
					}
					@media(max-width: 768px){
						margin-bottom: 4.3em;
						padding-left: 6em;

					}
					@media(max-width: 767px){
						padding-left: 3.6em;
					}
					@media(max-width: 340px){
						margin-bottom: 5.3em;
					}

					&:hover {
						&:before {
							background:rgb(238, 30, 182);
						}
					}

					&:before {
						content: '';
						@include w(6,6);
						border-radius: 50%;
						background: rgb(63, 214, 165);
						display: block;
						position: absolute;
						left: -1em;
						top: 1.5em;
						@media(max-width: 767px){
							left: 0;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.sheduling {
		display: block;
		position: relative;
		padding: 10.6em 0 4em 0;
		@media(max-width: 1280px){
			padding: 11.6em 0 7em 0;
		}
		@media(max-width: 340px){
			padding: 7.6em 0 7em 0;
		}
		.block_title {
			@include block_title;
			font-size: 2.6em;
			text-align: center;
			width: 100%;
			max-width: 11em;
			margin: auto;

			@media(max-width: 768px){
				max-width: 17em;
				font-size: 3.6em;
				padding: 0 0.5em;
			}
		}
		.block_subtitle {
			@include title_light(1.0);
			margin-bottom: 1.9em;
			text-align: center;
		}
		.wrap {
			width: 100%;
			max-width: 81em;
			display: block;
			margin:1.5em auto 0;
			@media(max-width: 768px){
				padding: 7em 0 0 0;
				max-width: 100%;
			}
			@media(max-width: 340px){
				padding: 3.6em 0 0 0;
			}
		}
		.line {
			display: flex;
			align-items: center;
			padding: 2em 0 2em 12em;
			@media(max-width: 1280px){
				padding: 1em 0 2em 12em;
			}
			@media(max-width: 992px){
				padding:  2em 2em 2em 12em;
			}
			@media(max-width: 768px){
				padding: 2em 2em 2em 9em;
			}
			@media(max-width: 750px){
				display: block;
			}
			@media(max-width: 550px){
				padding: 2em 0;
			}
			@media(max-width: 340px){
				padding: 2em 0 4em;
			}
			&:nth-child(even) {
				.col {
					&_title {
						h2 {
							&:nth-child(odd) {
								padding-left: 0.6em;
							}
							&:nth-child(3) {
								padding-left: 1.1em;
							}
						}
					}
				}
			}
			&:nth-child(odd) {
				.col {
					&_title {
						h2 {
							&:nth-child(even) {
								padding-left: 1.1em;
							}
						}
					}
				}
			}
			&.couple {
				.col {
					&_face {
						padding: 0;

					}
					img {
						&:first-child {
							position: relative;
							left: -3em;
						}
						&:last-child {
							position: absolute;
							top: 35%;
							right: 5em;
							z-index: 2;
						}
					}
				}
			}
			&.data {
				.col {
					&_face {
						background: url('../img/col3_ovelay.png') center center;
						background-size: 100%;
					}
				}
			}

			&_description {
				width: 100%;
				max-width: 26.7em;
				position: relative;
				padding: 1em 1em 0 0em;
				margin: -4.2em 0 0 0;
				
				@media(max-width: 768px){
					margin: -4em auto 0;
					max-width: 38em;

				}
				@media(max-width: 340px){
					max-width: 44em;
					padding: 2em 1em 0 0em;
				}
				&:after {
					content: '';
					width: .19em;
					height: 1em;
					display: block;
					background:rgba(255,255,255,.2);
					position: absolute;
					top: .1em;
					right: 0;
				}
				p {
					font-size: .9em;
					font-family: $hlight;
					line-height: 1.5;
					color:$w;
					display: block;
					margin-top: 0.5em;
					@media(max-width: 1280px){
						margin: 1em 0 0;
						font-size: 1.5em;
					}
					@media(max-width: 768px){
						margin-top: 7px;
						font-size: 2.1em;
					}
					@media(max-width: 340px){
						font-size: 2.4em;
					}
				}
				.white_line {
					content: '';
					width: 100%;
					height: .15em;
					display: block;
					background:rgba(255,255,255, .2);
					position: absolute;
					top: 0;
					left: 0;

					&:before {
						content: '';
						width: 0;
						height: 100%;
						position: absolute;
						left: auto;
						right: 0;
						bottom: 0;
						margin: auto;
						display: block;
						background: $w;
						z-index: 2;
						transition: .5s;
					}
					&:after {
						content: '';
						width: .19em;
						height: 0;
						display: block;
						background:rgba(255,255,255,.99);
						position: absolute;
						bottom: auto;
						top: 0;
						right: 0;
						transition:.5s;
						transition-delay: .4s;
						margin: auto;
					}
					
				}
			}
			.col {
				width: 100%;
				display: flex;
				height: 20.5em;
				max-width: 36.4em;
				position: relative;
				margin-bottom: 4em;
				margin-right: 3.2em;
				align-items: center;
				
				&:last-child  {
					margin-bottom: 0;
				}
				@media(max-width: 1280px){
					margin-right: 4.6em;
				}
				@media(max-width: 768px){
					margin: 0 auto 4em;
					height: 28.0em;
					max-width: 45.4em;
				}
				@media(max-width: 550px){
					display: block;
					height: auto;
					max-width: 100%;
				}
				

				&:hover {
					.col {
						&_face {
							&:before {
								opacity: 0;
								transform: scale(2)
							}
						}
						&_title {
							z-index: 0;
						}
					}
					& ~ .line_description {
						.white_line {
							&:before {
								width: 100%;
								left: 0;
								right: auto;
							}
							&:after {
								transition-delay: .4s;
								height: 1em;
								top:0;
								bottom: auto;
							}
						}
					}
				}
				&_face {
					background: url('../img/col_fon.png')repeat-y;
					overflow: hidden;
					display: block;
					position: relative;
					width: 100%;
					height: 100%;
					padding: 1em;
					z-index: 1;
					@media(max-width: 550px){
						height: 28em;
					}
					@media(max-width: 340px){
						height: 30em;
					}
					&:before {
						content: '';
						@include abs;
						width: 100%;
						height: 100%;
						display: block;
						background: #000;
						transition:.7s;
						transform-origin: center;
						z-index: 3;
						background-image: -moz-linear-gradient( 130deg, rgb(0,31,166) 0%, rgb(167,0,254) 100%);
						background-image: -webkit-linear-gradient( 130deg, rgb(0,31,166) 0%, rgb(167,0,254) 100%);
						background-image: -ms-linear-gradient( 130deg, rgb(0,31,166) 0%, rgb(167,0,254) 100%);
						opacity: 0.8;
						@media(max-width: 550px){
							display: none;
						}
					}
				}
				&_title {
					z-index: 2;
					left: -10em;
					position: absolute;
					@media(max-width: 550px){
						position: relative;
						left: 0;
						display: block;
						padding: 0 2em;
						margin-top: 4em;
					}
					@media(max-width: 340px){
						padding: 0em 1em;
						min-height: 9.6em;
					}
					h2 {
						color: $w;
						font-size: 2.5em;
						letter-spacing: .4px;
						font-family: $hregular;

					}
				}
				img {
					display: block;
					margin: auto;
					width: 100%;
					transition:.4s;
					max-width: 21em;
					transform-origin: center top;
					transition:.7s;
					@media(max-width: 340px){
						max-width: 33em;
					}
				}
			}
		}
	}
	.wrap_instruction {
		display: flex;
		position: relative;
		align-items: stretch;
		border-top: 1px solid rgba(255, 255, 255, 0.27);
		border-bottom: 1px solid rgba(255, 255, 255, 0.27);
		@media(max-width: 768px){
			margin: 5em 0 0 0;
		}
		@media(max-width: 340px){
			margin: 10em 0 0 0;
		}
		.arrow {
			width: 100%;
			@include fb;
			background:transparent;
			transition: .7s background;
			padding: 3em;
			color: $w;
			@media(max-width: 768px){
				padding: 0em 0 5em;
			}
			@media(max-width: 767px){
				padding: 0;
			}
			span {
				display: block;
				font-size: 1.0em;
				font-family: $hlight;
				width: 9em;
				color: rgb(180, 179, 255);
				position: relative;
				top: -2.0em;
				line-height: 1.533;
				@media(max-width: 1280px){
					font-size: 1.3em;
				}
				@media(max-width: 768px){
					font-size: 1.6em;
				}
				@media(max-width: 767px){
					display: none;
				}
			}
			&:first-child {
				justify-content: flex-start;
				text-align: right;
				span {
					margin-left: auto;
					padding-right: 2em;
					@media(max-width: 1280px){
						padding-right: 1.2em;
					}
				}
				@media(max-width: 767px){
					position: absolute;
					left: 0;
					bottom: 5%;
				}
				@media(max-width: 340px){
					bottom: 12%;
				}
			}
			&:last-child {
				justify-content: flex-end;
				span {
					padding-left: 2em;
					@media(max-width: 1280px){
						padding-left: 1.2em;
					}
				}
				@media(max-width: 767px){
					position: absolute;
					right: 0;
					bottom: 5%;
				}
				@media(max-width: 340px){
					bottom: 12%;
				}
			}
			&:hover {
				@media(min-width: 768px){
					background-image: -moz-linear-gradient( 130deg, rgb(11,32,141) 0%, rgb(111,41,155) 100%);
					background-image: -webkit-linear-gradient( 130deg, rgb(11,32,141) 0%, rgb(111,41,155) 100%);
					background-image: -ms-linear-gradient( 130deg, rgb(11,32,141) 0%, rgb(111,41,155) 100%);
				}
				svg {
					opacity: 1;
				}
			}
			svg {
				z-index: 10;
				width: 100%;
				opacity: .4;
				margin: 7em 0 0;
				cursor: pointer;
				max-width: 13em;
				position: relative;
				transform: scale(.8);
				transform-origin: center;
				@media(max-width: 1280px){
					max-width: 17em;
				}
				@media(max-width: 768px){
					max-width: 32em;
					margin: 0;
				}
				@media(max-width: 767px){
					max-width: 20em;
				}
				@media(max-width: 550px){
					width: 16.5em;
				}
			}
		}
		.how_it_work_slider {
			width: 100%;
			max-width: 85em;
			margin: 0  -21.1em;
			@media(max-width: 888px){
				max-width: 50em;
				margin: 0 -12.5em;
			}
			@media(max-width: 768px){
				margin: 0 auto;
			}
			@media(max-width: 767px){
				max-width: 100%;
			}
			.slick-active {
				.slide {
					&:before{
						height: 55%;
						transition-delay: .5s;
					}
					&:after{
						height: 55%;
						transition-delay: .5s;
					}
				}
				h3 {
					top: 0;
					opacity: 1;
				}
			}
			
			.slide {
				margin: 0;
				height: auto;
				max-width: 33em;
				overflow: hidden;
				padding: 4.6em 0;
				position: relative;
				margin-bottom: -1.3em;
				@media(max-width: 1400px){
					max-width: 42.5em;
				}
				@media(max-width: 1280px){
					padding: 8.1em 0 9.2em;
				}
				@media(max-width: 888px){
					max-width: 25em;
				}
				@media(max-width: 768px){
					max-width: 100%;
					padding: 13em 0 11em 0;
					// border-bottom: 1px solid rgba(255, 255, 255, 0.27);
				}
				@media(max-width: 767px){
					padding: 13em 0 20em 0;
				}
				@media(max-width: 340px){
					padding: 9.8em 0 19em 0;
				}
				&:before, &:after {
					content: '';
					width: 1px;
					height: 0;
					position: absolute;
					transition:.9s;
					left: 0;
					right: 0;
					margin: 0 auto;
					display: block;
					background: rgba(255, 255, 255, 0.27);
				}
				&:before{
					top: 0;
				}
				&:after{
					bottom: 0;
				}
				img {
					margin: auto;
					width: 100%;
					position: relative;
					z-index: 2;
					max-width: 17em;
					@media(max-width: 1280px){
						max-width: 26em;
					}

					@media(max-width: 888px){
						max-width: 20em;
					}
					@media(max-width: 768px){
						width: 100%;
						max-width: 36em;
					}
					@media(max-width: 340px){
						max-width: 32em;
					}
				}
			}
			
			h3 {
				color: $w;
				top: -3em;
				z-index: 2;
				opacity: 0;
				width: 100%;
				display: flex;
				transition:.8s;
				min-height: 5em;
				line-height: 1.2;
				font-size: 1.5em;
				max-width: 12.2em;
				position: relative;
				margin: 0  auto 0.6em;
				font-family: $hregular;
				align-items: flex-start;
				&:before {
					content: '';
					@include w(7,7);
					border-radius: 50%;
					display: inline;
					background: #ee1eb6;
					position: relative;
					margin-right: 1em;
					top: .4em;
				}
				@media(max-width: 1280px){
					font-size: 2.5em;
					min-height: 3em;
					max-width: 14.2em;
					margin: 0 0 .6em auto;
				}
				@media(max-width: 1024px){
					font-size: 1.9em;
					margin: 0 0 .6em 18%;
				}
				@media(max-width: 888px){
					font-size: 2.6em;
				}
				@media(max-width: 767px){
					margin: auto;
					max-width: 15.2em;
					padding: 0 1em 0 0;
				}
				@media(max-width: 340px){
					font-size: 2.8em;
					line-height: 1.7;
				}
			}
		}
	}
	.office_space {
		position: relative;
		padding: 1em 0 4em;
		position: relative;
		overflow: hidden;
		color: $w;
		@media(max-width: 1280px){
			padding: 6.4em 0 4em;
		}
		@media(max-width: 340px){
			padding: 4.6em 0 4em;
		}
		.container {
			display: flex;
			align-items: flex-start;
			@media(max-width: 767px){
				display: block;
			}
		}
		svg {
			position: absolute;
			z-index: 0;
			left: -3%;
			top: 2em;
			width: 100%;
			height: 100%;
			path {
				opacity: .3;
			}
			@media(max-width: 1280px){
				left: 0%;
				top: 3em;
			}
			@media(max-width: 768px){
				top: 9em;
			}
			@media(max-width: 767px){
				left: -26%;
			}
		}
		.st1, .st2 {
			fill:transparent;
		}
		.block {
			&_subtitle {
				@include title_light;
				font-size: 1em;
				text-align: left;
				@media(max-width: 1280px){
					margin-bottom: 2.2em;
				}
				@media(max-width: 768px){
					font-size: 1.5em;
				}
				@media(max-width: 340px){
					font-size: 1.8em;
					margin-bottom: 2.0em;
				}
			}
			&_title {
				margin-bottom: 2em;
				span {
					display: block;
					text-align: right;
					@include block_title(2.6em);
					letter-spacing: 1.3px;
					line-height: 1.4;
					@media(max-width: 768px){
						font-size: 3.8em;
					}
				}
				@media(max-width: 1280px){
					margin-bottom: 1em;
				}
			}
		}
		.text {
			display: block;
			font-family: $hlight;
			font-size: 1.0em;
			width: 100%;
			max-width: 20em;
			color: rgb(136, 146, 209);
			margin-left: auto;
			@media(max-width: 1280px){
				font-size: 1.5em;
				width: 100%;
				max-width: 13em;
			}
			@media(max-width: 768px){
				max-width: 14em;
				font-size: 1.9em;
			}
			@media(max-width: 340px){
				font-size: 2.3em;
				position: relative;
				left: 2em;
			}
		}
		.left {
			width: 39%;
			height: 59em;
			display: flex;
			padding: 0 2em 0 0;
			position: relative;
			align-items: flex-end;
			flex-direction: column;
			justify-content: center;
			@media(max-width: 992px){
				height: 43em;
			}
			@media(max-width: 768px){
				width: 100%;
			}
			@media(max-width: 767px){
				padding: 0 1.5em 0 1.5em;
			}
			@media(max-width: 550px){
				margin-bottom: 2em;
			}
			div {
				z-index: 3;
				position: relative;
				margin: 4.9em 0 0;
				padding: 0 2.4em 0 0;
				@media(max-width: 1280px){
					margin: 5.0em 0 0;
					padding: 1.4em 0.8em 0 0;
				}
				@media(max-width: 768px){
					margin: 20.0em 0 0;
				}
				@media(max-width: 767px){
					margin: 0 auto;
				}
				@media(max-width: 340px){
					margin: 0 auto 0 0;
				}
			}
		}
		.right {
			display: flex;
			max-width: 57%;
			margin-left: auto;
			align-items: center;
			margin: 10em 0 0 auto;
			padding: 7em 0 9em 8em;
			justify-content: flex-end;
			background-color: rgb(100, 57, 172);
			@media(max-width: 1280px){
				padding: 7em 0 10.6em 8em;
			}
			@media(max-width: 992px){
				max-width: 100%;
				padding: 6em 0 6em 4em;
			}
			@media(max-width: 768px){
				max-width:fit-content;
				margin: 12em 0 0 auto;
				padding: 7em 0 6em 4.8em;
			}
			@media(max-width: 340px){
				margin: 8em 0 0 auto;
				padding: 6em 0 4em 4em;
			}
			img {
				display: block;
				box-shadow: 0px 7px 20px -10px #000;
				max-height: 27.5em;
				@media(max-width: 1280px){
					max-height: 29.0em;
				}
				@media(max-width: 992px){
					max-height: 21em;
				}
				@media(max-width: 768px){
					max-height: 24em;
				}
				@media(max-width: 767px){
					max-height: 35em;
				}
				@media(max-width: 550px){
					max-height: 27em;
				}
				@media(max-width: 440px){
					max-height: 21em;
				}
				
				&:first-child {
					margin-right: 6em;
					@media(max-width: 768px){
						margin-right: 5.5em;
					}
					@media(max-width: 340px){
						margin-right: 2em;
					}
				}
			}
			.col {
				position: relative;
				&:not(:last-child) {
					margin-right: 5em;
					img {
						border-radius: 4em;
					}
				}
				&:last-child {
					img {
						border-radius: 5em 0 0 5em;
					}
				}

			}
		}
	}
	.wrap_preview {
		display: flex;
		margin: auto;
		max-width: 200em;
		padding:4em 0 4em;
		position: relative;
		align-items: center;
		@media(max-width: 1280px){
			padding:7em 0 4em;
		}
		@media(max-width: 880px){
			display: block;
			padding: 21em 0 4em;
		}
		@media(max-width: 768px){
			padding: 16em 0 4em;
		}
		@media(max-width: 340px){
			padding: 6em 0 3.3em;
		}
		.btn {
			@media(max-width: 768px){
				padding: 0.9em 3.5em;
			}
			span {
				text-transform: uppercase;
				font-weight: bold;
				@media(max-width: 768px){
					font-size: 1.6em;
				}
				@media(max-width: 340px){
					font-size: 100%;
					font-weight: bold;
				}
			}
		}

		.other_panel {
			display: none;
			justify-content: space-between;
			@media(max-width: 880px){
				width: 100%;
				display: flex;
				padding-top: 4.5em;
				align-items: center;
			}
			@media(max-width: 768px){
				padding: 4.5em 3em 0 0;
			}
			@media(max-width: 340px){
				padding: 6.3em 1.9em;
			}
			svg {
				cursor: pointer;
				position: relative;
				transform-origin: center;
				transform: scale(.9);
				z-index: 10;
				width: 100%;
				max-width: 29em;
				opacity: .4;
				&:hover {
					opacity: 1;
				}
				@media(max-width: 768px){
					max-width: 25em;
				}
				@media(max-width: 550px){
					max-width: 15em;
				}
				@media(max-width: 340px){
					max-width: 13.5em;
				}
			}
			span {
				display: block;
				font-size: 1.4em;
				font-family: $hlight;
				width: 8em;
				color: rgb(180, 179, 255);
				position: relative;
				top: -3em;
				line-height: 1.533;
				@media(max-width: 768px){
					min-height: 9em;
					font-size: 1.7em;
					top: -1em;
				}
				@media(max-width: 767px){
					display: none;
				}
			}
			div {
				&:first-child {
					justify-content: flex-start;
					text-align: right;
					span {
						margin-left: auto;
						padding-right: 1.5em;
						@media(max-width: 768px){
							padding-right: .5em;
						}
					}

				}
				&:last-child {
					justify-content: flex-end;
					span {
						padding-left: 1.5em;
						@media(max-width: 768px){
							padding-left: .5em;
						}
					}
				}
			}
		}
		.preview_slider {
			width: 100%;
			max-width: 100%;
			margin: 0 auto;
			& ~ .arrow {
				right: 0;
				left: auto;
				justify-content: flex-end;
				span {
					padding-left: 1.5em;
				}
			}
			@media(max-width: 768px){
				order:0;
			}
			.slide {
				.head, img {
					width: 100%;
					max-width: 57em;
					margin: auto;
					@media(max-width: 1180px){
						max-width: 60em;
						border-radius: 3em;
					}
					@media(max-width: 992px){
						max-width: 50em;
						border-radius: 2em;
					}
					@media(max-width: 880px){
						max-width: 74em;
					}
					@media(max-width: 768px){
						max-width: 75em;
					}
				}
				.btn {
					@media(min-width: 341px){
						font-size:1.1em !important;
					}
				}
			}
			img {
				width: 100%;
				border-radius: 3em;
				position: relative;
				z-index: 3;
				box-shadow: 0px 7px 20px -10px #000;

			}
			.slick-active{
				.head {
					top: 0;
					opacity: 1;
					transition-delay: .4s;

				}
			}
		}
		.arrow {
			width: 100%;
			max-width: 32em;
			@include fb;
			background:transparent;
			transition: .7s background;
			padding: 5em 5em 0em;
			color: $w;
			position: absolute;
			left: 0;
			@media(max-width: 1280px){
				padding: 11em 5em 0em;
			}
			@media(max-width: 880px){
				display: none;
			}

			span {
				display: block;
				font-size: 1.0em;
				font-family: $hlight;
				width: 8em;
				color: rgb(180, 179, 255);
				position: relative;
				top: -1.5em;
				line-height: 1.533;
				min-height: 9em;
				margin: 0 0 -1em;
			}
			&:first-child {
				justify-content: flex-start;
				text-align: right;
				span {
					margin-left: auto;
					padding-right: 1.5em;
				}
			}
			svg {
				cursor: pointer;
				position: relative;
				transform-origin: center;
				transform: scale(.8);
				z-index: 10;
				width: 100%;
				max-width: 13em;
				opacity: .4;
				&:hover {
					opacity: 1;
				}
				@media(max-width: 1280px){
					transform: scale(0.85);
					max-width: 19em;
				}
				@media(max-width: 550px){
					width: 15em;
				}

			}
		}
		.head {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 1em 2em 0;
			position: relative;
			top: 3em;
			opacity: 0;
			transition:.9s;
			@media(max-width: 768px){
				div {
					min-height: 10em;
					margin-bottom: 0;
					padding: 0 0 1em;

				}
			}
			@media(max-width: 340px){
				display: block;
				
				.btn {
					display: block;
					margin: 0 auto;
					font-size: 1.9em !important;
					text-transform: uppercase;
					padding: 0.7em 2.2em !important;
					letter-spacing: 1px;
					margin: 1.3em auto 1.6em;

				}
			}
			
		}
		.slide {
			@media(max-width: 768px){
				padding: 0 2em;
			}
		}
		h3 {
			color: $w;
			z-index: 2;
			width: 100%;
			display: flex;
			transition:.8s;
			max-width: 15em;
			line-height: 1.2;
			font-size: 1.8em;
			position: relative;
			font-family: $hregular;
			align-items: flex-start;
			&:before {
				content: '';
				@include w(7,7);
				border-radius: 50%;
				display: inline;
				background: #ee1eb6;
				position: relative;
				margin-right: 1em;
				top: .4em;
				@media(max-width: 768px){
					margin-right: 0;
					left: -1em;
				}
			}
			@media(max-width: 1280px){
				line-height: 1.6;
				font-size: 1.99em;
			}
			@media(max-width: 768px){
				font-size: 2.99em;
			}
			@media(max-width: 340px){
				font-size: 3.1em;
				letter-spacing: 0.5px;
			}
		}
	}
	.how_to_start {
		position: relative;
		position: relative;
		overflow: hidden;
		padding: 10em 0 10em 0;
		@media(max-width: 1280px){
			padding: 8.2em 0 10em 0;
		}
		@media(max-width: 768px){
			padding: 1.2em 0 10em 0;
		}
		@media(max-width: 340px){
			padding: 0 0 10em 0;
		}
		.block_subtitle {
			@include title_light(1.0);
			display: block;
			margin: 0 0 2em 3.0em;
			@media(max-width: 768px){
				position: relative;
				font-size: 1.7em;
				margin: 0 0 2em 6em;
			}
			@media(max-width: 550px){
				margin: 0 0 1.6em 5em;
			}
			@media(max-width: 340px){
				margin: 0 0 1.6em 1.5em;
				font-size: 1.5em;
				letter-spacing: 4.7px;
			}
		}
		.container {
			width: 100%;
			right: -18em;
			max-width: 120em;
			margin-left: auto;
			position: relative;
			@media(max-width: 1280px){
				right: -17em;
			}
			@media(max-width: 768px){
				right: 0;
			}
			@media(max-width: 550px){
				width: 100%;
				max-width: 50em;
				margin: 0 auto;
			}
		}
		&_slider {
			width: 100%;
			max-width: 125em;
			margin: 0 0 0 auto;
			@media(max-width: 768px){
				position: relative;
				right: -10em;
			}
			@media(max-width: 550px){
				right: 0;
			}
			.slick-active {
				.icon {
					&:before {
						transform: scale(1);
						transition-delay: .5s;
					}
				}
				.upper {
					svg {
						top: 0;
						opacity: .4;
						transition-delay: .2s;
					}
				}
				h3 {
					opacity: 1;
					bottom: 0;
					transition-delay: .3s;
				}
			}
		}
		.line_arrows {
			width: 100%;
			display: flex;
			margin-left: 3em;
			padding: 2em 0 0 0;
			max-width: 76em;
			align-items: center;
			justify-content: space-between;
			@media(max-width: 1280px){
				padding: 4em 0 0 0;
			}
			@media(max-width: 992px){
				max-width: 69em;
			}
			@media(max-width: 768px){
				max-width: 100%;
				margin: 0 0 0 auto;
				padding: 5em 5em 0 2em;
			}
			@media(max-width: 340px){
				padding: 5.5em 2em 0;
			}
			svg {
				opacity: .4;
				width: 10.6em;
				cursor: pointer;
				position: relative;
				@media(max-width: 1280px){
					width: 16.6em;
				}
				@media(max-width: 768px){
					width: 22.6em;
				}
				@media(max-width: 550px){
					width: 15em;
				}
				@media(max-width: 340px){
					width: 13em;
				}
				&:hover {
					opacity: 1;
				}
			}
		}
		.slide {
			width: 100%;
			max-width: 39em;
			height: auto;
			margin: 0;
			padding: 0 .99em 0 2.4em;
			@media(max-width: 768px){
				max-width: 50em;
				padding: 0 .99em 0 0;
			}
			@media(max-width: 550px){
				max-width: 100%;
				padding: 0 1em;
			}
			@media(max-width: 340px){
				padding: 2em 1em 0 1em;
			}
		}
		.icon {
			@include fb;
			z-index: 4;
			width: 100%;
			height: 14.2em;
			overflow: hidden;
			margin: 1em 0 0 0;
			position: relative;
			background-color: rgb(100, 57, 172);
			@media(max-width: 1280px){
				height: 20.2em;
			}
			@media(max-width: 768px){
				height: 26.4em;
				margin-top: 3.2em;
			}
			@media(max-width: 340px){
				height: 23em;
				margin-top: 2.2em;
			}
			&:before {
				content:'';
				width: 79%;
				z-index: 0;
				height: 100%;
				@include abs;
				transition:1s;
				display: block;
				background: url('../img/back_overlay.png') center;
				background-size: 100%;
				transform-origin: center;
			}
			.third_icon {
				position: relative;
				z-index: 2;
				text-align: center;
				img {
					@include w2(4,4);
					display: block;
					margin: 0 auto -3em;
					z-index: 3;
					position: relative;
				}
			}
			svg {
				z-index: 2;
				width: 9em;
				height: 100%;
				transition: .6s;
				position: relative;
				transform: scale(0.8);
				transform-origin: center;
				@media(max-width: 340px){
					transform: scale(1);
				}
			}
		}
		.upper {
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			position: relative;

			svg {
				top: -5em;
				z-index: 0;
				opacity: 0;
				width: 28em;
				height: 11.1em;
				transition: .8s;
				position: relative;
				margin: 0 -14em -5em 0;
				transform: scale(0.73);
				transform-origin: left top;
				@media(max-width: 1280px){
					transform: scale(0.93);
				}
				@media(max-width: 992px){
					transform: scale(0.83);
				}
				@media(max-width: 768px){
					transform: scale(1.05);
					height: 11.5em;
					margin: 0 -7.5em 0 0;
					path {
						transform: scale(0.9);
					}
				}
				@media(max-width: 340px){
					transform: scale(1.11);
					margin: 0 -22em 0 0;
				}

			}
		}
		h3 {
			z-index: 2;
			opacity: 0;
			width: 100%;
			color: #fff;
			bottom: -3em;
			display: block;
			min-height: 5.5em;
			max-width: 15em;
			font-size: 1.45em;
			line-height: 1.35;
			position: relative;
			letter-spacing: 1px;
			padding:0.8em 0 0 0;
			font-family: $hregular;
			transition: .8s bottom, 1.4s opacity;
			@media(max-width: 1280px){
				font-size: 2.35em;
				padding: 1.4em 0 0 0;
			}
			@media(max-width: 768px){
				font-size: 2.75em;
				line-height: 1.55;
				padding: 1.3em 0 0 0;
			}
			@media(max-width: 340px){
				font-size: 3.05em;
				line-height: 1.55;
				padding: 1.2em 0 0 0;
			}
		}
	}
	.rose_planet {
		bottom: auto;
		top: -96%;
		right: -53%;
		z-index: 0;
		left: auto;
		width: 29em;
		height: 29em;
		padding: 7em;
		@media(max-width: 768px){
			top: -53%;
			right: -30%;
		}
		&:before  {
			// animation:planet_circls 5s  linear 19; 
			content: '';
			@include abs;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			@include br(rgba(255, 255, 255, 0.31));
			transform-origin: center center;
		}
		&:after  {
			content: '';
			@include abs;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			@include br(rgba(255, 255, 255, 0.31));
			transform-origin: center center;
		}

		.planet {
			transform:rotate(28deg);
			z-index: 5;
			&:before {
				background: url('../img/a2_white.png') 0 -1em;
				background-repeat-y: no-repeat;
				background-size: 92em;
				// animation:planet_lines 20s  linear 19; 
			}
			&:after {
				display: none;
			}
		}
	}
}


@keyframes circle {
	0% {
		transform:rotate(0deg);
	}

	100% {
		transform:rotate(1000deg);
	} 
}
@keyframes planet_lines {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 500px 0;
	} 
}
@keyframes planet_circls {
	0% {
		width: 50%;
		height: 50%;
		opacity: 1;
	}
	80% {
		width: 90%;
		height: 90%;
		opacity: 1;
	}
	85% {
		width: 90%;
		height: 90%;
		opacity: 0;
	}
	95% {
		width: 99%;
		height: 99%;
		opacity: 0;
	}

	100% {
		width: 40%;
		height: 40%;
		opacity: 0;
	}
}
@keyframes lines {
	0% {
		width: 1%;
		height: 1%;
		opacity: 1;
	}
	80% {
		width: 90%;
		height: 90%;
		// opacity: .5;
	}
	85% {
		width: 90%;
		height: 90%;
		// opacity: .3;
	}
	95% {
		width: 99%;
		height: 99%;
		opacity: 0;
	}
	
	100% {
		width: 40%;
		height: 40%;
		opacity: 0;
	}
}