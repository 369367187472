section {
	&.main_content {
		.privacy_policy {
			@media(max-width: 550px){
				font-size: 8px ;
			}
		}
	}
}
.block_window {
	&.privacy_policy {
		padding: 8em 0;

		.block_title {
			max-width: 100%;
		}

		.wrapper_privacy_policy {
			display: block;
			background: rgb(23, 43, 86);
			width: 100%;
			padding:4em 9em 4em 3em;
			color:$w; 
			box-shadow: 0 0 16px -5px rgba(10, 21, 50, 0.52);
			margin:2.5em auto 4em;
			@media(max-width: 768px){
				padding:4em 3em 4em 3em;
			}
			.title {
				&_line {
					font-family: $hregular;
					font-size: 3em;
					margin-bottom: .5em;
				}
			}
			.request_to {
				font-size: 1.5em;
				display: block;
				margin-bottom: 2em;
			}

			.line {
				margin-bottom: 1em;
				.italic {
					font-style: italic;
				}
				&:last-child {
					margin-bottom: 0;
				}
				span {
					font-size: 1.5em;
					display: block;
					margin-bottom: 2em; 
					font-family: $hlight;
					line-height: 25px;
					
					a {
						color: #f2326d;
						display: inline-block;
						margin: 0 9px;
						&:hover {
							text-decoration: underline; 
						}
					}
				}
				ul {
					margin: 0 0 2em 2em;
					li {
						font-size: 1.5em;
						margin: 8px 0;
						display: flex;
						align-items: center;
						&:hover{
							&:before {
								background:#f2326d;
							}
							a {
								color: #f2326d;
							}
						}
						a {
							cursor: pointer;
							&.active {
								color: #f2326d;
							}
						}
						&:before {
							content: "";
							margin-right: 11px;
							display: block;
							width: 10px;
							min-width: 10px;
							height: 1px;
							background: #fff;
						}
					}
				}
			}
			.pp_title {
				font-family: $hregular;
				line-height: 1.0;
				display: block;
				font-size: 1.8em;
				margin: 1.3em 0 .7em;
				&.large {
					font-size: 2.9em;
				}
			}

			
		}
	}
}

.cookiesTable {
	width: 100%;
	background: #1F3A76;
	margin-bottom: 20px;
	th, td {
		padding: 5px;
		margin: 0;
		background: #172b56;
	}
}

