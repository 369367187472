.left_navigation {
	// font-size: 10px;
	position: fixed;
	top: 15%;
	padding-left: 4em;
	z-index: 3;
	transition-delay: 0s;
	transition:0s;
	// opacity: 0;
	// transition:.4s opacity;
	&.active {
		a { 
			opacity: 1;
			left: 0;
			transition-delay: 0s;
			pointer-events: auto;
			cursor: pointer;
			@media(min-width: 1921px){
				opacity: 1;
			}
		}
	}
	@media(min-width: 1921px){
		left: 0;
		right: 0;
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
		padding: 0;
		display: block;
	}
	@media (max-width: 1100px) {
		display: none;
	}
	a {
		transition:.4s left, .4s opacity;
		display: block;
		color:#848db1;
		font-family: $hregular;
		position: relative;
		font-size: 1.5em;
		line-height: 2;
		left: -20px;
		opacity: 0;
		pointer-events: none;
		width: intrinsic;
		width: -moz-max-content;
		width: -webkit-max-content;
		&.active {
			color: $w;
			 
		}
		&:hover {
			color: $w;

			&:before {
				opacity: 1;
				left: -1em;
				
			}
			&:after {
				opacity: 1;
				right: -1em;
			}
			@media(min-width: 1921px){
				opacity: 1;
			}
		}
		&:before {
			content: '';
			opacity: 0;
			transition:.6s;
			height: 1.0em;
			width: 1.0em;
			min-width: 1.0em;
			position: absolute;
			top: 0;
			bottom: 0;
			left: -2.0em;
			margin: auto;
			display: block;
			background:url('../img/triangle_right.svg')no-repeat center;
			background-size: 60%;
		}
		&:after {
			content: '';
			opacity: 0;
			transition:.6s;
			height: 1.0em;
			width: 1.0em;
			min-width: 1.0em;
			position: absolute;
			top: 0;
			bottom: 0;
			right: -2.0em;
			margin: auto;
			display: block;
			background:url('../img/triangle_left.svg')no-repeat center;
			background-size: 60%;
		}
		&:nth-child(1){
			transition-delay: .1s, .1s;
			transition-property: left, opacity;
		}
		&:nth-child(2){
			transition-delay: .2s,.2s;
			transition-property: left, opacity;
		}
		&:nth-child(3){
			transition-delay: .3s, .3s;
			transition-property: left, opacity;
		}
		&:nth-child(4){
			transition-delay: .4s, .4s;
			transition-property: left, opacity;
		}
		&:nth-child(5){
			transition-delay: .5s, .5s;
			transition-property: left, opacity;
		}
		&:nth-child(6){
			transition-delay: .6s, .6s;
			transition-property: left, opacity;
		}
		&:nth-child(7){
			transition-delay: .7s, .7s;
			transition-property: left, opacity;
		}
		
	}  
}