#root {
	.main_content {
		&.contact {
			overflow: hidden;
			&.active {
				.block_title, .contact_col .title, .contact_col .subtitle  {
					transform:translate(0, 0px);
					opacity: 1;
					transition-delay: .1s;
				}
				.mobile_section.socials, .envelope {
					transform:translate(0, 0px);
					opacity: 1;
					transition-delay: .3s;
				}
				.socials {
					bottom: 0;
					opacity: 1;
					transition:.7s;
				}
				a {
					&.envelope {
						transition:.1s color, .7s bottom, .7s opacity;
						bottom: 0;
						opacity: 1;
						transition-delay: 0s,.2s,.2s;
						transition-property:color, bottom, opacity;
					}
				}
				.left_signature {
					left: 4em;
					transition-delay: .45s;
				}
			}

			.block_title, .contact_col .title, .contact_col .subtitle, .envelope {
				transition:.5s;
				position: relative;
				transform:translate(0, 150px);
				opacity: 0;
			}




			@media(max-width: 768px){
				font-size: 8px;
			}
			@media(max-width: 550px){
				font-size: 8px;
			}
			& ~ footer {
				display: none;
				width: intrinsic;          
				width: -moz-max-content;   
				width: -webkit-max-content;
				padding: 0;
				height: 4em;
				width: 5em;
				bottom: 48%;
				transform:translate(0, 50%) rotate(-90deg);
				text-align: center;

				.wrap_socials {
					display: none;
				}
				.copyright {
					p {
						white-space: nowrap;
					}
				}
			}

			.left_signature {
				position: fixed;
				left: -5em;
				top: 43%;
				display: block;
				color: rgba(255, 255, 255, 0.82);
				white-space: nowrap;
				transition:.5s left;
				transform:rotate3d(3, -1, -30, 90deg);
				transform-origin: left;
				z-index: 5;
				span {
					white-space: nowrap;
					font-size: 1.1em;
					display: block;
				}
				@media(max-width: 768px){
					display: none;
				}
			}

			.block_window {
				width: 100%;
				min-height: 100vh;
				padding: 6em 0;
				@include fb;
				justify-content: space-between;
				@media (max-width:768px) {
					display: block;
					padding: 8em 0 6em;
				}

			}

			.envelope {
				bottom: -8em;
				opacity: 0;

			}
			.socials {
				align-items: center;
				justify-content: flex-start;
				margin-top: auto;
				width: 100%;
				opacity: 0;
				bottom: -90px;
				position: relative;

				@media (max-width: 992px) {
					display: block;
				}
				@media(max-width: 768px){
					position: fixed;
					left: 0;
					right: 0;
					bottom: -9em;
					display: flex;
					align-items: center;
					justify-content: space-around;
					width: 100%;
					transition:.9s bottom;
					a {
						font-size: 1.7em;
					}
				} 
				a {
					margin: 0 1em;
					@media(min-width: 993px){
						&:first-child {
							margin-left: 0;
						}
					}
				}
			}
			a {
				color: $w;
				font-family: $hlight;
				font-size: 1.6em;
				transition:.1s color;
				transition-delay: 0s;


				&.facebook {
					&:hover {
						color:#3b5998;
					}
				}
				&.twitter {
					&:hover {
						color:#00aced;
					}
				}
				&.likedin {
					&:hover {
						color:#0077b5;
					}
				}
				&.envelope {
					display: flex;
					align-items: center;
					margin-top: auto;
					font-family: $hlight;
					position: relative;
					
					transition-delay: 0s;
					transition-property: color;
					
					svg {
						display: block;
						position: absolute;
						transition:.3s opacity;
						opacity: 0;
						&:first-child {
							opacity: 1;
						}
					}
					&:hover {
						svg {
							opacity: 1;
							&:first-child {
								opacity: 0;
							}
						}
						color: #f2326d;
					}
					span {
						font-family: $hlight;
						padding-left: 3em;
					}
				}
			}
		}

		.contact {
			&_col {
				width: 100%;
				max-width: 47em;
				height: 40em;
				color:$w;
				display: flex;
				flex-direction: column;
				position: relative;
				@media(max-width: 768px){
					height: auto;
					display: block;
					max-width: 100%;
					&:last-child {
						padding:3em 0 2em 2em;
					}
				}

				&:first-child {
					max-width: 35em;
				}

				.title {
					font-size: 3.0em;
					font-family: $hregular;
					line-height: 1.4;
				}

				.subtitle {
					font-size: 1.6em;
					color:#848db1;
					margin-top: 1em;
					@media(max-width: 768px){
						display: block;
						margin-bottom: 1.1em;
					}
				}
			}
		}
		.bubble {
			position: fixed;
			left: 48%;
			top: 10%;
			width: 10em;
			height: 10em;
			border-radius: 50%;
			z-index: 0;
			transition:bottom 1.2s;
			@include fb;
			z-index: 0;
			animation:bubble 30s  linear infinite;
			@media(min-width: 1400px){
				top: 23vh;
			}
			@media(max-width: 500px){
				display: none;
			}

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				border-radius:50%;
				background: #a2227d;
				background:radial-gradient(ellipse at center,rgba(162, 34, 125, 0) 0,rgba(162, 34, 125, 0) 27%,rgba(162, 34, 125, 0.37) 48%,rgba(162,34,125,.64) 60%,#a2227d 100%);
				@include abs;
			}
		}
	}
	@keyframes bubble {
		0% {
			transform:scale(1);
		}
		25% {
			transform:scale(.7);
		}
		50% {
			transform:scale(1);
		}
		75% {
			transform:scale(.5);
		}
		100% {
			transform:scale(1);
		}
	}
}